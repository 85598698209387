<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>


      <div class="row no_side_margin">
        <div class="total col-lg-12 ">
        
          <div class="row">
              <div class="col-12">
                <ul class="nav nav-tabs d_summary">
                  <li class="nav-item active"><a data-toggle="tab" href="#applications" class="nav-link active" @click="setSectionAndUrl('applications')" id="applications">
                  <font-awesome-icon icon="list" size="lg" />
                  {{ $t('status-apps' ) }}</a>
                  </li>

                  <li>
                    <a data-toggle="tab" href="#services" class="nav-link" @click="setSectionAndUrl('services')" id="services">
                    <font-awesome-icon icon="list" size="lg"/>
                    {{ $t('status-services' ) }}</a>
                  </li>

                  <li>
                    <a data-toggle="tab" href="#queues" class="nav-link" @click="setSectionAndUrl('queues')" id="queues">
                    <font-awesome-icon icon="list" size="lg"/>
                    {{ $t('status-queues' ) }}</a>
                  </li>

                  <li>
                    <a data-toggle="tab" href="#caches" class="nav-link" @click="setSectionAndUrl('caches')" id="caches">
                    <font-awesome-icon icon="list" size="lg"/>
                    {{ $t('status-caches' ) }}</a>
                  </li>
                
                </ul>
              </div>
            </div>

          <div class="tab-content">
            <div id="applications" class="tab-pane container active" v-if="section === 'applications'">
              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head col-4">{{ $t('name') }}</th>
                      <th class="t-head col-4 text-center">{{ $t('status') }}</th>
                      <th class="t-head text-right">{{ $t('status-last-updated') }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="!status.apps || !status.apps.length">
                    <tr>
                      <td colspan="3" class="table-col text-center">{{ $t('no-results') }}</td>
                    </tr>
                  </tbody>
                  <tbody v-if="status.apps && status.apps.length">
                    <tr v-for="app in status.apps" :key="app.name">
                      <td class="table-col">
                        <a :href="'https://captain.cr.vps2.clients.killia.com/#/apps/details/uproc-' + app.name" target="_blank">{{ app.name }}</a>
                      </td>
                      <td class="table-col text-center">
                        <font-awesome-icon v-if="app.status === 'ok'" icon="check-circle" class="text-success"></font-awesome-icon>
                        <font-awesome-icon v-else-if="app.status === 'ko'" icon="times-circle" class="text-danger"></font-awesome-icon>
                      </td>
                      <td class="table-col text-right">{{ app.lastUpdate | formatDate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              
            <div id="services" class="tab-pane container active" v-if="section === 'services'">
              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head col-4">{{ $t('name') }}</th>
                      <th class="t-head col-4 text-center">{{ $t('status') }}</th>
                      <th class="t-head text-right">{{ $t('status-last-updated') }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="!status.services || !status.services.length">
                    <tr>
                      <td colspan="3" class="table-col text-center">{{ $t('no-results') }}</td>
                    </tr>
                  </tbody>
                  <tbody v-if="status.services && status.services.length">
                    <tr v-for="service in status.services" :key="service.name">
                      <td class="table-col">{{ service.name }}</td>
                      <td class="table-col text-center">
                        <font-awesome-icon v-if="service.status === 'ok'" icon="check-circle" class="text-success"></font-awesome-icon>
                        <font-awesome-icon v-else-if="service.status === 'ko'" icon="times-circle" class="text-danger"></font-awesome-icon>
                      </td>
                      <td class="table-col text-right">{{ service.lastUpdate | formatDate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div id="queues" class="tab-pane container active" v-if="section === 'queues'">
              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head col-4">{{ $t('name') }}</th>
                      <th class="t-head col-4 text-center">{{ $t('status') }}</th>
                      <th class="t-head text-right" v-for="status in ['inactive', 'complete', 'active', 'delayed', 'failed']" :key="status">{{ $t("status-" + status) }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="!status.queues || !status.queues.length">
                    <tr>
                      <td colspan="3" class="table-col text-center">{{ $t('no-results') }}</td>
                    </tr>
                  </tbody>
                  <tbody v-if="status.queues && status.queues.length">
                    <tr v-for="item in status.queues" :key="item.name">
                      <td class="table-col">{{ item.name }}</td>
                      <td class="table-col text-center">
                        <font-awesome-icon v-if="item.status === 'ok'" icon="check-circle" class="text-success"></font-awesome-icon>
                        <font-awesome-icon v-else-if="item.status === 'warning'" icon="check-circle" class="text-warning"></font-awesome-icon>
                        <font-awesome-icon v-else icon="times-circle" class="text-danger"></font-awesome-icon>
                      </td>
                      <td class="table-col text-center" v-for="status in ['inactive', 'complete', 'active', 'delayed', 'failed']" :key="status">
                        <span v-if="item.details[status] > 0" :class="{'text-danger': status === 'failed', 'text-warning': status === 'delayed' || status === 'inactive'}">{{ item.details[status] }}</span>
                        <span v-else>{{ item.details[status] }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div id="caches" class="tab-pane container active" v-if="section === 'caches'">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center pt-2 mb-2">
                  <div class="mr-2">
                    <p class="text">{{ $t('refresh-caches') }}:</p>
                  </div>
                  <div class="ml-2 d-flex align-items-center">
                    <button @click="refreshAllCaches" class="btn btn-success">{{ $t('refresh') }}</button>
                  </div>
                </div>
              </div>
            </div>
              

          </div>

        </div>
      </div>

              
              
      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Dashboard',
  components: {
    Header,
    Sidebar,
    Footer,
  },
  data() {
    return {
      isActive: false,
      isAdmin: false,
      isActiveIcon: '<',
      
      section: 'applications',

      selectedUser: 'me',
      selectedUserEmail: '',
      selectedUserObj: {},
      customSearch: false,
      customSearchTool: false,

      currentLocale: this.$i18n.locale,
      filterStatus: 'paid',

      balance: 1,

      kpisAll: {
        total_day: -1,
        cost: -1,
        forecast: "-"
      },
      kpisList: [],
      kpisTotal: {
        all: {
          count: "-",
        }
      },
      isCostByHour: true,
      startDate: '',
      endDate: '',
      graphOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      datacollection: {
        datasets: []
      },
      toggleClass: "ani1",
      state: [],
      config: {},
      graph: {
        data: [],
        options: {}
      },

      loading: false,

      totalPagesSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      currentPageSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      totalRecordsSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      totalRowsSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0,
      },

      rowsPerPageSection: {
        jobs: 10,
        requests: 10,
        tools: 10,
        sales: 10,
        users: 10
      },

      prevStartDate: null,
      prevEndDate: null,
      requests: [],
      requestShown: -1,
      shownAllRequests: false,
      jobs: [],
      jobsRunning: false,
      countdownToRefresh: 30,
      secondsToRefresh: 30,

      totalTools: [],
      totalUsers: [],
      filterText: '',
      filterTool: '',
      
      tools: {
        results: [],
      },
      edited_tool_key: '',

      sales: {
        invoices: [],
        payments: [],
        filteredPayments: [],
        subscriptions: [],
        total: 0
      },
      users: {
        list: [],
        total: []
      },

      result: null,
      currency: '€',
      
      status: {
        apps: [],
        services: []
      },
    };
  },
  mounted() {
    this.enableInterceptor();
    this.detectSectionInUrl();
    this.getStatuses();
  },
  methods: {
    detectSectionInUrl() {
      let url = this.$route.fullPath;
      console.log(url);
      console.log(this.section);
      if (url.indexOf('/admin/system/services') !== -1) {
        this.setSection('services')
        this.setSectionAndUrl('services');
      } else if (url.indexOf('/admin/system/queues') !== -1) {
        this.setSection('queues')
        this.setSectionAndUrl('queues');
      } else if (url.indexOf('/admin/system/caches') !== -1) {
        this.setSection('caches')
        this.setSectionAndUrl('caches');
      } else if (url.indexOf('/admin/system') !== -1) {
        this.setSection('applications')
        this.setSectionAndUrl('applications');
      }
      console.log(this.section);
    },
    getConfigAuth() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    setSection(section) {
      $('.nav-tabs a[href="#' + section + '"]').tab('show');
    },

    setSectionAndUrl(section) {
      this.section = section;
      //update url
      if (section.length) {
        section = '/' + section;
      }
      window.history.pushState({}, '', '#/admin/system' + section);
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areDatesDifferents: function(setDates) {
      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    isFirstPageBySection: function(section) {
      return this.currentPageSection[section] === 0;
    },

    
    isLastPageBySection: function(section) {
      return this.currentPageSection[section] === this.totalPagesSection[section] - 1;
    },

    moveNextBySection: function(section) {
      this.clearCountdown();
      if (this.isLastPageBySection(section)) {
        this.loading = true;
      }
      if (section == 'jobs') {
        this.moveNextJobs();
      } else if (section == 'requests') {
        this.moveNextRequests();
      } else if (section == 'tools') {
        this.moveNextTools();
      } else if (section == 'sales') {
        this.moveNextSales();
      } else if (section == 'users') {
        this.moveNextUsers();
      }
    },

    moveNextJobs: function() {
      if (this.currentPageSection.jobs + 1 < this.totalPagesSection.jobs) {
        this.currentPageSection.jobs++;
        this.getJobs(true);
      }
    },
    
    adaptOutputByResult: function(sampleResult) {
      try {
        const output = JSON.parse(sampleResult);
        
        // var values = Object.values(output);
        // for (var i = 0; i < values.length; i++) {
        //   if (this.isImage(values[i])) {
        //     //replace "values[i]" with the image tag in output
        //     values[i] = '<a href="' + values[i] + '" target="_blank">' + values[i] + '</a>';
        //     output[Object.keys(output)[i]] = values[i];
        //   } else if (this.isUrl(values[i])) {
        //     //replace "values[i]" with the link tag in output
        //     values[i] = '<a href="' + values[i] + '" target="_blank">' + values[i] + '</a>';
        //     output[Object.keys(output)[i]] = values[i];
        //   }
        // }

        //console.log(output);
        return output;
        
      } catch (e) {
        return sampleResult;
      }
    },

    isImage: function(value) {
      return value && value.indexOf('http') !== -1 && (value.indexOf('.png') !== -1 || value.indexOf('.jpg') !== -1 || value.indexOf('.jpeg') !== -1 || value.indexOf('.gif') !== -1);
    },

    isUrl: function(value) {
      return value && value.indexOf('http') !== -1;
    },

    moveNextRequests: function() {
      if (this.currentPageSection.requests + 1 < this.totalPagesSection.requests) {
        this.currentPageSection.requests++;
        this.getRequests(true);
      }
    },

    moveNextTools: function() {
      if (this.currentPageSection.tools + 1 < this.totalPagesSection.tools) {
        this.currentPageSection.tools++;
        this.getTools(true);
      }
    },

    moveNextSales: function() {
      if (this.currentPageSection.sales + 1 < this.totalPagesSection.sales) {
        this.currentPageSection.sales++;
        this.getSales();
      }
    },

    moveNextUsers: function() {
      if (this.currentPageSection.users + 1 < this.totalPagesSection.users) {
        this.currentPageSection.users++;
        this.getUsers();
      }
    },
    
    movePreviousBySection: function(section) {
      this.clearCountdown();
      if (this.isFirstPageBySection(section)) {
        this.loading = true;
      }
      if (section == 'jobs') {
        this.movePreviousJobs();
      } else if (section == 'requests') {
        this.movePreviousRequests();
      } else if (section == 'tools') {
        this.movePreviousTools();
      } else if (section == 'sales') {
        this.movePreviousSales();
      } else if (section == 'users') {
        this.movePreviousUsers();
      }
    },

    movePreviousJobs: function() {
      if (this.currentPageSection.jobs - 1 >= 0) {
        this.currentPageSection.jobs--;
        this.getJobs(true);
      }
    },

    movePreviousRequests: function() {
      if (this.currentPageSection.requests - 1 >= 0) {
        this.currentPageSection.requests--;
        this.getRequests(true);
      }
    },

    movePreviousTools: function() {
      if (this.currentPageSection.tools - 1 >= 0) {
        this.currentPageSection.tools--;
        this.getTools(true);
      }
    },

    movePreviousSales: function() {
      if (this.currentPageSection.sales - 1 >= 0) {
        this.currentPageSection.sales--;
        this.getSales();
      }
    },

    movePreviousUsers: function() {
      if (this.currentPageSection.users - 1 >= 0) {
        this.currentPageSection.users--;
        this.getUsers();
      }
    },

    getRequestsTrigger: function() {
      this.period = localStorage.period || 'thisweek';
      this.currentPage = 0;
      this.totalPages = 0;
      this.getRequests(true);
    },

    getSales: function() {
      var url = '/api/v2/sales_period/' + this.period + "/" + this.startDate.getTime() + "/" + this.endDate.getTime() + "/" + this.currentPageSection.sales;
      if (this.selectedUser !== '') {
        if ('custom' === this.selectedUser) {
          url += "?user=" + this.selectedUserEmail;
        } else {
          url += "?user=" + this.selectedUser;
        }
      }

      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.sales = results.data;

        //add paymentsFiltered to sales
        this.filterStatus = 'paid';
        this.filterPaymentsByStatus();

        this.totalRecordsSection.sales = results.data.count;
        this.totalPagesSection.sales = results.data.totalPages;
        this.rowsPerPageSection.sales = results.data.rowsPerPage;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    filterPaymentsByStatus: function() {
      var that = this;
      if (this.sales.payments && this.sales.payments.length) {
        this.sales.filteredPayments = this.filterStatus !== '' ? this.sales.payments.filter(function(item) {
          return item.status === that.filterStatus || ['paid', 'accepted'].includes(item.status) && that.filterStatus === 'paid';
        }) : this.sales.payments;
      }
    },

    getTools: function() {
      var url = `/api/v2/panel/tools/${this.currentPageSection.tools}`;
      if (this.filterTool && this.filterTool.length) {
        url += `?filter=${this.filterTool}`;
      }
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.tools = results.data.results;
        this.totalRecordsSection.tools = results.data.count;
        this.totalPagesSection.tools = results.data.totalPages;
        this.rowsPerPageSection.tools = results.data.rowsPerPage;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    getUsers: function() {
      var url = '/api/v2/users_period/' + this.period + "/" + this.startDate.getTime() + "/" + this.endDate.getTime() + "/" + this.currentPageSection.users;

      if (this.selectedUser !== '') {
        url += "?user=" + this.selectedUser;
      }

      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.users = results.data;
        this.totalRecordsSection.users = results.data.count;
        this.totalPagesSection.users = results.data.totalPages;
        this.rowsPerPageSection.users = results.data.rowsPerPage;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    searchUsers: function() {
      if (!this.filterText || !this.filterText.length) {
        this.getUsers();
      } else {
        this.customSearch = true;
        var url = `/api/v2/users_search/${this.filterText}`;

        this.$http.get(url, this.getConfigAuth()).then((results) => {
          this.users = results.data;
        }).catch(() => {
          this.$root.$emit('isLoading', false);
        });
      }
    },

    searchToolsInput: function(e) {
      if (e.keyCode === 13) {
        this.currentPageSection.tools = 0;
        this.getTools(true);
      }
    },

    searchTools: function() {
      this.getTools(true);
    },

    searchUsersInput: function(e) {
      if (e.keyCode === 13) {
        this.searchUsers();
      }
    },

    getRequests: function(forced) {
      forced = this.areDatesDifferents() || forced || !this.requests[this.currentPageSection.requests] || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true; 

        var url = '/api/v2/requests_period/' + this.period + "/" + this.startDate.getTime() + "/" + this.endDate.getTime() + "/" + this.currentPageSection.requests;

        if (this.selectedUser !== '') {
          if ('custom' === this.selectedUser) {
            url += "?user=" + this.selectedUserEmail;
          } else {
            url += "?user=" + this.selectedUser;
          }
        }

        this.$http.get(url, this.getConfigAuth()).then((results) => {
          if (this.requests) {
            this.requests[this.currentPageSection.requests] = results.data.results;
            this.requests = this.requests[this.currentPageSection.requests];
            this.totalRecordsSection.requests = results.data.count;
            this.totalPagesSection.requests = results.data.totalPages;
            this.rowsPerPageSection.requests = results.data.rowsPerPage;
            this.pendingRequest = false;
            this.areDatesDifferents(true);
          }
        }).catch(() => {
          this.$root.$emit('isLoading', false);
          
        });
      }
    },

    refreshAllCaches: function() {
      this.$http.get('/api/v2/refresh_all_caches', this.getConfigAuth()).then(() => {
        this.$root.$emit('isLoading', false);
        this.$toast.success(this.$t('cache-refreshed'));
      });
    },

    initCountdown: function() {
      this.countdownToRefresh = this.secondsToRefresh;
      this.countdown = setInterval(() => {
        this.countdownToRefresh--;
        if (this.countdownToRefresh === 0) {
          this.clearCountdown();
          this.getJobs(true);
        }
      }, 1000);
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    setPeriodAmounts: function() {
      //calculate with startDate and isCostByHour
      const currentDate = this.endDate.getTime() > new Date().getTime() ? new Date() : this.endDate;
      //add 1 day to startDate
      const startDate = new Date(this.startDate);
      var diff = currentDate.getTime() - startDate.getTime();
      //console.log(diff, startDate, currentDate);
      if (diff > 0) {
        var days = diff / 1000 / 60 / 60 / 24;
        this.periodAmounts = days;
        //console.log(days);

        if (this.isCostByHour) {
          this.periodAmounts = days * 24;
        }
      }
    },

    clearCountdown: function() {
      clearInterval(this.countdown);
      //clear all intervals
      for (var i = 1; i < 100; i++) {
        window.clearInterval(i);
      }
    },

    resetCurrentPage: function() {
      this.currentPageSection = {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      };
    },

    changePeriod: function(period) {
      if (localStorage) {
        localStorage.period = period;
      }
      this.clearCountdown();
      this.filterText = '';

      this.datacollection = {
        datasets: []
      };

      this.period = period;
      this.endDate = new Date();
      this.startDate = new Date();

      var firstDay;
      var lastDay;
      //console.log(this.period, this.startDate, this.endDate);
      if (this.period === 'day') { //current day
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '1day') { //last 24
        this.startDate.setDate(this.startDate.getDate() - 1)
      } else if (this.period === 'month') { //current month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
      } else if (this.period === '7days') { //last 7 days
        this.startDate.setDate(this.startDate.getDate() - 7)
      } else if (this.period === '15days') { //last 15 days
        this.startDate.setDate(this.startDate.getDate() - 15)
      } else if (this.period === '30days') { //last 30 days
        this.startDate.setDate(this.startDate.getDate() - 30)
      } else if (this.period === '90days') { //last 90 days
        this.startDate.setDate(this.startDate.getDate() - 90)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '180days') { //last 180 days
        this.startDate.setDate(this.startDate.getDate() - 180)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '365days') { //last 365 days
        this.startDate.setDate(this.startDate.getDate() - 365)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      //add yesterday, today, this_week, previous_week, this_month, previous_month, this_quarter, previous_quarter, this_year, previous_year
      } else if (this.period === 'yesterday') { //yesterday
        this.startDate.setDate(this.startDate.getDate() - 1)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setDate(this.endDate.getDate() - 1)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === 'today') { //today
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === 'thisweek') { //this week
        firstDay = this.startDate.getDate() - this.startDate.getDay();
        this.startDate.setDate(firstDay);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = firstDay + 6;
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), lastDay, 23, 59, 59);
      } else if (this.period === 'previousweek') { //previous week
        firstDay = this.startDate.getDate() - this.startDate.getDay() - 7;
        this.startDate.setDate(firstDay);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = firstDay + 6;
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), lastDay, 23, 59, 59);
      } else if (this.period === 'thismonth') { //this month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'previousmonth') { //previous month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        this.startDate.setMonth(this.startDate.getMonth() - 1);
        lastDay = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'thisquarter') { //this quarter
        this.startDate = new Date(this.startDate.getFullYear(), Math.floor(this.startDate.getMonth() / 3) * 3, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), Math.floor(this.startDate.getMonth() / 3) * 3 + 3, 0);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'previousquarter') { //previous quarter
        let previousQuarter = Math.floor(this.startDate.getMonth() / 3) * 3 - 3;
        this.startDate = new Date(this.startDate.getFullYear(), previousQuarter, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        this.endDate = new Date(this.startDate.getFullYear(), previousQuarter + 3, 0);
        this.endDate.setHours(23);
        this.endDate.setMinutes(59);
        this.endDate.setSeconds(59);
      } else if (this.period === 'thisyear') { //this year
        this.startDate = new Date(this.startDate.getFullYear(), 0, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), 11, 31);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'previousyear') { //previous year
        this.startDate = new Date(this.startDate.getFullYear() - 1, 0, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), 11, 31);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      }



      //console.log(this.startDate, this.endDate);

      this.isCostByHour = (this.period === 'day' || this.period === '1day' || this.period === 'lday' || this.period === 'yesterday' || this.period === 'today');


      var aux = this.period;
      var url = '/api/v2/stats_period/' + aux + '/' + this.startDate.getTime() + '/' + this.endDate.getTime();

      if (this.selectedUser !== '') {
        if ('custom' === this.selectedUser) {
          url += "?user=" + this.selectedUserEmail;
        } else {
          url += "?user=" + this.selectedUser;
        }
      }
      this.$root.$emit('isLoading', true);
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.$root.$emit('isLoading', false);
        this.state = results.data.all.series.cost;
        this.kpisAll = results.data.all.kpi;
        this.kpisList = results.data.all.values;
        this.totalTools = results.data.all.total.sort(function(a, b) {
          return b.cost - a.cost;
        });
        if (results.data.users) {
          this.totalUsers = results.data.users.sort(function(a, b) {
            return b.price - a.price;
          }).map(function(entry) {
            entry.email = entry.user;
            return entry;
          });
        }

        let that = this;
        $.each(results.data, function(key) {
          that.kpisTotal[key] = {
            maxCost: 0,
            cost: 0,
            count: 0,
            savings: 0
          };
          $.each(results.data[key].total, function(keys, value) {
            that.kpisTotal[key].maxCost += value.maxCost;
            that.kpisTotal[key].cost += value.cost;
            that.kpisTotal[key].savings += value.savings;
            that.kpisTotal[key].count += value.count;
          });
        });
        that.fillData();

        that.resetCurrentPage();

        this.setPeriodAmounts();

        that.getRequests(true);
        that.getJobs(true);
        

        if (that.selectedUser !== 'me') {
          that.getSales(true);
        }

        if (that.selectedUser == 'all') {
          that.getUsers(true);
        }

        if (that.isAdmin) {
          that.getTools(true);
        }
      }).catch(() => {
        //return an error property
        this.$root.$emit('isLoading', false);
        // console.log(error)
        this.$toast.error(this.$t('request-error'));
      });
    },

    getStatuses: function() {
      var url = '/api/v2/status/all';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.status = results.data;
        // console.log(results)
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    restartJob: function(job) {
      if (!job) {
        return;
      }

      //confirm if status is running
      if (job.status == 2) {
        if (!confirm(this.$t('job-restart-confirm'))) {
          return;
        }
      }
      var url = '/api/v2/restart_job/' + job.id;
      this.$http.get(url, this.getConfigAuth()).then(() => {
        this.getJobs(true);
      });
    },

    stopJob: function(job_id) {
      if (!job_id) {
        return;
      }

      if (!confirm(this.$t('job-stop-confirm'))) {
        return;
      }

      var url = '/api/v2/stop_job/' + job_id;
      this.$http.get(url, this.getConfigAuth()).then(() => {
        this.getJobs(true);
      });
    },

    downloadJobResultsFile: function(request_id) {
      var url = this.$apiBase + "/api/v2/request_download/" + request_id;
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        if (results && results.data && results.data.url && results.data.url.length) {
          window.location.href = results.data.url;
        }
      });
    },

    saveSelectedUser: function() {
      if (localStorage) {
        localStorage.panelSelectedUser = localStorage.role === 'admin' ? this.selectedUser : 'me';
      }
    },

    updateDashboardData: function() {
      this.clearCountdown();
      this.customSearch = false;
      this.selectedUserEmail = '';
      this.changePeriod(this.period);
    },

    cancelSearch: function() {
      this.customSearch = false;
      this.selectedUser = 'all'
      this.selectedUserEmail = '';
      this.changePeriod(this.period);
    },

    selectUserAndUpdateDashboardData: function(user) {
      this.clearCountdown();
      this.selectedUser = 'custom';
      this.selectedUserEmail = user.email
      this.selectedUserObj = user;
      this.period = 'thismonth';
      this.changePeriod(this.period);
    },

    addBalance: function() {
      this.result = {};
      var url = '/api/v2/add_balance/' + this.selectedUserEmail + '/' + this.balance;
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.result = results.data;
      });
    },

    saveUser: function() {
      this.result = {};
      var url = '/api/v2/save_user/' + this.selectedUserEmail;
      this.$http.post(url, this.selectedUserObj, this.getConfigAuth()).then((results) => {
        this.result = results.data;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },
  },
  watch: {

    state: function() {
    },

    // $route() {
    //   //reload data
    //   nextTick(() => {
    //     this.changePeriod(this.period);
    //   });
    // }

  },
  computed: {

    orderedList: function() {
      return _.orderBy(this.kpisList, ['key'], ['asc'])
    },

    dayData() {
      if (this.state != 0) {
        return this.state;
      } else {
        return [{
          "key": "cost",
          "values": [
            [Date.now(), 0]
          ]
        }, {
          "key": "saving",
          "values": [
            [Date.now(), 0]
          ]
        }];
      }
    }
  }
}
</script>

<style scoped>
#secure {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 20px;
  margin-top: 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../../assets/css/global.css';
</style>
