<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pb-4">
        <a v-on:click="back">
          {{ $t("back") }}
        </a>
      </div>
      <div class="text-left" style="background-color: #fff; padding: 20px">
        <h4>{{ $t("settings") }}</h4>
        <div v-if="stepNumber === 1">
          <h5 class="pt-4">1. {{ $t("table-input") }}</h5>
          <div class="pt-2 pb-2">
            {{ $t("table-input-description") }}.
          </div>
            <div class="d-flex flex-wrap align-items-center pt-2">
            {{ $t("table-input-chosen", {name: $t("source-" + settings.input.type)}) }}
            <div class="d-flex flex-wrap align-items-center">
              <a v-for="source in sourceNames.filter(source => source.key !== settings.input.type)" :key="source.key" href="#" @click.prevent="settings.input.type = source.key" class="gap-x-2">
              {{ $t("source-" + source.key) }}
              </a>
            </div>
            </div>
          <div>
            <div>
              <InputFile v-if="settings.input.type === 'upload'" :settings="settings" />
              <InputUrl v-if="settings.input.type === 'url'" :settings="settings" />
              <InputS3 v-if="settings.input.type === 's3'" :settings="settings" />
              <InputSftp v-if="settings.input.type === 'sftp'" :settings="settings" />
              <InputMongo v-if="settings.input.type === 'mongo'" :settings="settings" />
              <InputMysql v-if="settings.input.type === 'mysql'" :settings="settings" />
              <InputPostgres v-if="settings.input.type === 'postgres'" :settings="settings" />
              <InputApi v-if="settings.input.type === 'api'" :settings="settings" />

              <div v-if="isAllowedRepeatByInputType()">
                <div class="form-group row mt-2">
                    <div :for="'columns'" class="col-sm-2 text-left">{{ $t("table-synchronization") }}</div>
                    <div class="col-sm-10">
                      <!-- <select v-model="settings.input.properties.sync_every">
                        <option v-for="item in every_data_select" :key="item.key" :value="item.key">{{ $t("table-sync-every-" + item.key) }}</option>
                      </select> -->
                      {{ $t("table-every-" + settings.input.properties.sync_every) }}
                    </div>
                    <small class="col-sm-10 offset-sm-2">{{ $t("table-sync-every-upgrade") }}</small>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-4">
            <button type="button" class="btn btn-primary" v-on:click="stepNumber = 2">
              {{ $t("next") }}
            </button>
          </div>
        </div>
        <div v-else-if="stepNumber === 2">
            <h5 class="pt-4">2. {{ $t("table-actions") }}</h5>
            <p class="pt-2 pb-4">{{ $t("table-actions-description") }}</p>
            <div v-for="(action, index) in actions" :key="index">
            <h6 class="font-italic">{{ $t("table-action-" + action.name) }}</h6>
            <p class="pt-2 pb-4">{{ $t("table-action-" + action.name + "-description") }}</p>
            <div v-if="action.showFields">
              <div class="table-responsive">
              <table class="table">
                <thead>
                <tr> 
                  <th class="col-lg-2">{{ $t("table-field") }}</th>
                  <th class="col-lg-6">{{ $t("table-tool") }}</th>
                  <th class="col-lg-4">{{ $t("table-when") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="field in fields" :key="field.name">
                  <td class="col-lg-2">{{ field.name }}</td>
                  <td class="col-lg-8">
                    <select v-model="settings.actions[action.name].fields[field.name].tool" :disabled="getToolsByField(action.name, field.name).length === 0" v-on:change="$forceUpdate();updateToolOutputProperties(action.name, field.name)" class="form-control">
                      <option value="">{{ $t("select-processor") }}</option>
                      <option v-for="tool in getToolsByField(action.name, field.name)" :key="tool.key" :value="tool.key">{{ tool.description }}</option>
                    </select>
                    <div v-if="settings.actions[action.name].fields[field.name].tool.length" class="pt-4">
                      <div class="pb-2"><strong>{{ $t("price") }}:</strong> {{ getToolsByField(action.name, field.name).find(tool => tool.key === settings.actions[action.name].fields[field.name].tool).cost / 10  }}€ {{$t('cpt')}}</div>
                      <h7><strong>{{ $t("output-params") }}</strong></h7>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>{{ $t("table-tool-output-property") }}</th>
                            <th>{{ $t("table-tool-assigned-column") }}</th>
                            <th>{{ $t("table-tool-property-save") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in settings.actions[action.name].fields[field.name].toolOutputProperties" :key="index">
                            <td>{{ item.name }}</td>
                            <td>
                              {{ settings.actions[action.name].fields[field.name].toolOutputProperties[index].table_field }}
                            </td>
                            <td class="text-center">
                              <input type="checkbox" v-model="settings.actions[action.name].fields[field.name].toolOutputProperties[index].save" checked/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td class="col-lg-2">
                    <select v-model="settings.actions[action.name].fields[field.name].when" :disabled="settings.actions[action.name].fields[field.name].tool.length === 0" class="form-control">
                      <option value="">{{ $t("select-when") }}</option>
                      <option v-for="item in every_tool_select" :key="item.key" :value="item.key">{{ $t('table-every-' + item.key) }}</option>
                    </select>
                  </td>
                </tr>
                </tbody>
              </table>
              </div>
            </div>
            <div v-else>
              <div class="table-responsive">
              <table class="table">
                <thead>
                <tr> 
                  <th class="col-lg-2">{{ $t("table-field") }}</th>
                  <th class="col-lg-6">{{ $t("table-key") }}</th>
                  <th class="col-lg-4">{{ $t("table-order") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="field in fields" :key="field.name">
                  <td>{{ field.name }}</td>
                  <td>
                  <input type="checkbox" v-model="settings.actions[action.name].fields[field.name].key"/>
                  </td>
                  <td>
                  <input type="number" v-model="settings.actions[action.name].fields[field.name].order"  class="form-control"/>
                  </td>
                </tr>
                </tbody>
              </table>
              </div>
              <div>{{ $t("table-deduplicate-criteria") }}</div>
              <select v-model="settings.actions['deduplicate'].criteria" class="form-control col-lg-2">
                <option value="" selected>{{ $t("table-select-criteria") }}</option>
                <option value="best_sort">{{ $t("table-deduplicate-criteria-best-sort") }}</option>
                <option value="most_recent">{{ $t("table-deduplicate-criteria-most-recent") }}</option>
                <option value="least_recent">{{ $t("table-deduplicate-criteria-least-recent") }}</option>
              </select>
              <div class="pt-4">
              <p>{{ $t("table-deduplicate-criteria-description") }}</p>
              <ul>
                <li><strong>{{ $t("table-deduplicate-criteria-best-sort") }}:</strong> {{ $t("table-deduplicate-criteria-best-sort-description") }}</li>
                <li><strong>{{ $t("table-deduplicate-criteria-most-recent") }}:</strong> {{ $t("table-deduplicate-criteria-most-recent-description") }}</li>
                <li><strong>{{ $t("table-deduplicate-criteria-least-recent") }}:</strong> {{ $t("table-deduplicate-criteria-least-recent-description") }}</li>
              </ul>
              </div>
            </div>  
            </div>
            <div class="pt-4">
            <button type="button" class="btn btn-primary" v-on:click="stepNumber = 1">
              {{ $t("previous") }}
            </button>
            &nbsp;
            <button type="button" class="btn btn-primary" v-on:click="stepNumber = 3">
              {{ $t("next") }}
            </button>
            </div>
        </div>
        <div v-else>
          <h5 class="pt-4">3. {{ $t("table-output") }}</h5>
          <p class="pt-2 pb-4">{{ $t("table-output-description") }}</p>
          <div>
            <div class="form-group row mt-2">
                <div class="col-sm-2 text-left">{{ $t('api-url') }}</div>
                <div class="col-sm-10">
                    <input type="text" v-model="settings.output.apiUrl" :placeholder="$t('api-url-placeholder')" class="form-control">
                </div>
            </div>
          </div>

          <div class="form-group row mt-2">
            <div class="col-sm-2 text-left">{{ $t('filter-conditions') }}
              <button type="button" class="btn btn-secondary" @click="addFilter">
              {{ $t('add-filter') }}
              </button>

            </div>
            <div class="col-sm-10">
              <div v-for="(filter, index) in settings.output.filters" :key="index" class="mb-2">
                <input type="text" v-model="filter.field" :placeholder="$t('filter-field-placeholder')" class="form-control mb-1" />
                <select v-model="filter.condition" class="form-control mb-1">
                  <option value="equals">{{ $t('filter-condition-equals') }}</option>
                  <option value="not-equals">{{ $t('filter-condition-not-equals') }}</option>
                  <option value="contains">{{ $t('filter-condition-contains') }}</option>
                  <option value="not-contains">{{ $t('filter-condition-not-contains') }}</option>
                  <option value="greater-than">{{ $t('filter-condition-greater-than') }}</option>
                  <option value="less-than">{{ $t('filter-condition-less-than') }}</option>
                </select>
                <input type="text" v-model="filter.value" :placeholder="$t('filter-value-placeholder')" class="form-control mb-1" />
              </div>
            </div>
          
          </div>

          <div class="pt-4">
            <button type="button" class="btn btn-primary" v-on:click="stepNumber = 2">
              {{ $t("previous") }}
            </button>
            &nbsp;
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="saveSettings()"
            >{{ $t("save") }}</button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from "./types/File.vue";
import InputUrl from "./types/Url.vue";
import InputS3 from "./types/S3.vue";
import InputSftp from "./types/Sftp.vue";
import InputMongo from "./types/Mongo.vue";
import InputMysql from "./types/Mysql.vue";
import InputPostgres from "./types/Postgres.vue";
import InputApi from "./types/Api.vue";

export default {
  name: "settings",
  data() {
    return {
      loading: true,
      stepNumber: 1,

      tools: {
        normalize: [],
        validate: [],
        enrich: [],
      },

      sourceNames: [
        { key: "api"},
        { key: "upload"},
        { key: "mongo"},
        { key: "mysql"},
        { key: "postgres"},
        { key: "s3" },
        { key: "sftp" },
        { key: "url" },
      ],

      actions: [
        { name: "normalize", selected: false, showFields: true },
        { name: "validate", selected: false, showFields: true },
        { name: "enrich", selected: false, showFields: true },
        { name: "deduplicate", selected: false, showFields: false },
      ],
      fields: [
        //key
        {
          "name": "email"
        },

        //personal
        {
          "name": "nif",      
        },
        {
          "name": "firstname",
        },
        {
          "name": "lastname",
        },
        {
          "name": "birthdate"
        },
        {
          "name": "phone"
        },
        {
          "name": "mobile"
        },

        //company
        // {
        //   "name": "company"//name, companyname, organization
        // },
        // {
        //   "name": "vat"//vatid, vatnumber, cif
        // },
        // {
        //   "name": "website"//url, domain
        // },
        // {
        //   "name": "industry"
        // },
        // {
        //   "name": "employees"
        // },
        // {
        //   "name": "founded"
        // },
        
        //geo
        {
          "name": "address"
        },
        {
          "name": "city"
        },
        {
          "name": "state"
        },
        {
          "name": "zip"
        },
        {
          "name": "country"
        },
        {
          "name": "phone"
        },
        {
          "name": "ip"
        },
        
      ],
      settings: {
        input: {
          type: "upload",
          properties: {

            sync_every: "1day",
          },
        },
        actions: {
          normalize: {
            fields: {

            }
          },
          validate: {
            fields: {

            }
          },
          enrich: {
            fields: {

            }
          },
          deduplicate: {
            fields: {

            },
            criteria: "",
          },
        },
        output: {
          apiUrl: "",
          payload: "",
        },
      },

      every_data_select: [
        {
          key: "15min",
        },
        {
          key: "30min",
        },
        {
          key: "1hour",
        }
      ],

      every_tool_select: [
      {
          key: "once",
        },
        {
          key: "1day",
        },
        {
          key: "1week",
        },
        {
          key: "1month",
        },
        {
          key: "3months",
        }
      ],

      hasToBeSent: false,
      tableName: "",
    };
  },
  mounted() {
    this.tableName = this.$route.params.name;
    this.getProcessorsDetails();
    this.initActionsWithFields();
    this.initDeduplicateData();
  },
  components: {
    InputFile,
    InputUrl,
    InputS3,
    InputSftp,
    InputMongo,
    InputMysql,
    InputPostgres,
    InputApi,
  },
  methods: {
    initDeduplicateData: function () {
      //field email marked as key
      this.settings.actions.deduplicate.fields.email.key = true;
    },
    initActionsWithFields: function () {
      this.actions.forEach((action) => {
        this.fields.forEach((field) => {
          this.settings.actions[action.name].fields[field.name] = {};
          if (action.name === 'deduplicate') {
            this.settings.actions[action.name].fields[field.name].key = false;
            this.settings.actions[action.name].fields[field.name].order = 0;
          } else {
            this.settings.actions[action.name].fields[field.name].tool = "";
            this.settings.actions[action.name].fields[field.name].when = "";
          }
        });
      });

    },

    addFilter: function () {
      this.settings.output.filters.push({
        field: "",
        condition: "",
        value: ""
      });
      this.$forceUpdate();
    },

    back: function () {
      this.$emit("change-subsection", "view");
    },

    isAllowedRepeatByInputType: function () {
      const allowedTypes = ['api', 'mysql', 'mongo', 'sftp', 's3', 'url'];
      return allowedTypes.includes(this.settings.input.type);
    },
    
    saveSettings: function () {
      console.log(this.settings);
      this.back();
    },

    updateToolOutputProperties: function (action, field) {
      let tool = this.tools[action].find((tool) => {
        return tool.key === this.settings.actions[action].fields[field].tool;
      });

      if (tool) {
        this.settings.actions[action].fields[field].toolOutputProperties = [];
        if (!tool.output || tool.output.length === 0) {
          //TODO: this doesnt work
          this.settings.actions[action].fields[field].toolOutputProperties.push(
            {
              name: "result",
              table_field: field + `_extra.${tool.key}.result`,
              save: true,
            }
          );
        } else {
          tool.output[0].properties.forEach((prop) => {
            this.settings.actions[action].fields[field].toolOutputProperties.push(
              {
                name: prop.name,
                table_field: field + `_extra.${tool.key}.${prop.name}`,
                save: true
              }
            );
          });
        }
      }
    },
    handleFileUpload: function (e) {
      console.log(e.target.files[0]);
    },
    setSubsection: function (subsection) {
      this.subsection = subsection;
    },

    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    filterToolsByType : function (tools, type) {
      let filteredTools = [];
      if (type === 'normalize') {
        filteredTools = tools.filter((tool) => {
          return tool.type === 'normalize' || 
          tool.key.includes('normalize') ||
          tool.key.includes('clean') ||
          tool.key.includes('parse')
        })
      } else if (type === 'validate') {
        filteredTools = tools.filter((tool) => {
          return tool.type === 'check' || 
          tool.key.includes('check') ||
          tool.key.includes('validate')
        })
      } else if (type === 'enrich') {
        filteredTools = tools.filter((tool) => {
          return tool.type === 'get' || 
          tool.key.includes('get') ||
          tool.key.includes('enrich')
        })
      }

      return filteredTools;
    },

    getRestrictedToolsForTables: function (tools) {
      let filtered = tools.filter((tool) => {
        return tool.params.length === 1;
      }).filter((tool) => {
        return tool.public;
      });

      //sort case insensitive
      filtered.sort((a, b) => {
        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
      });

      return filtered;
    },

    getProcessorsDetails: function(callback = null) {
      var url = '/json/' + this.$locale + '/processors.json';
      this.$emit('isLoading', true);
      console.log(url)
      this.$http({
        method: 'GET',
        url: url,
        baseURL: '/'
      }).then((results) => {
        let processors = this.getRestrictedToolsForTables(results.data.processors);
        this.tools.normalize = this.filterToolsByType(processors, 'normalize');
        this.tools.validate = this.filterToolsByType(processors, 'validate');
        this.tools.enrich = this.filterToolsByType(processors, 'enrich');
        this.$emit('isLoading', false);
        if (callback) {
          callback();
        }
      });
    },

    getToolsByField: function (action, field) {
      return this.tools[action].filter((tool) => {
        return tool.params.some(param => param.name == field || param.name.indexOf(field) === 0);
      });
    },

    hasToBeSaved: function () {
      if (JSON.stringify(this.user) === JSON.stringify(this.FromServerUser)) {
        this.hasToBeSent = false;
      } else {
        this.hasToBeSent = true;
      }
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
      });
    },
  },
};
</script>
