<template>
  <div>
    <Header />

    <div class="row no_side_margin content">
      <Sidebar />

      <div
        class="col-md-9 col-lg-10 no_side_pad"
        v-bind:class="{ collapse_active_footer: isActive }"
      >
        <button @click="menuCollapse" class="collapse-btn">
          {{ isActiveIcon }}
        </button>

        <div class="row no_side_margin" style="margin-left: 2px;">
          <div class="total col-lg-12 contact-wrap">

            <div class="tab-content">
              <div id="home1" class="tab-pane container active">
                <div class="row no_side_margin">
                  <div class="col-12 col-title">
                    <h4>{{ $t("changes-title") }}</h4>
                  </div>
                  <div
                    class="col-lg-12 total-contact-form"
                  >
                    <div v-if="changes && changes.length" class="p-2">
                      <p v-html="$t('changes-description', {version: status.version })"></p>
                      <div
                        v-for="change in changes"
                        :key="change.date"
                        class="pt-4"
                      >
                        <strong>{{ change.date }}</strong>
                        <ul>
                          <li v-for="item in change.changes" :key="item.id">
                            {{ item.description.en }}
                            <br />
                            <span
                            v-for="tag in item.tags"
                            :key="tag"
                            class="badge badge-info mr-2"
                            >
                            {{ $t("tags-" + tag) }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-else>
                      <p v-html="$t('changes-soon')"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

import Footer from "@/components/Footer.vue";

import Sidebar from "@/components/Sidebar.vue";

import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      isActive: false,
      isActiveIcon: "<",
      name: "",
      logined: false,
      email: "",
      apiKey: "",
      subject: "",
      sent: true,
      comment: "",
      resultFeedback: "",
      sending: false,
      received: false,
      canBeSent: false,

      changes: [],
      status: {},
    };
  },
  mounted() {
    this.enableInterceptor();
    this.getStatus();
    this.getChanges();
  },
  components: {
    Header,
    Sidebar,
    Footer,
  },
  watch: {},
  methods: {
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use(
        (config) => {
          this.$root.$emit("isLoading", true);

          return config;
        },
        (error) => {
          this.$root.$emit("isLoading", false);

          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.$root.$emit("isLoading", false);

          return response;
        },
        function(error) {
          this.$root.$emit("isLoading", false);

          return Promise.reject(error);
        }
      );
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;

      this.$root.$emit("isActived", this.isActive);

      if (this.isActive) this.isActiveIcon = ">";
      else this.isActiveIcon = "<";
    },

    getStatus: function() {
      this.sending = true;
      var url = "/api/v2/version";
      this.$http
        .get(url)
        .then((results) => {
          this.status = results.data;
          console.log(this.status);
          this.sending = false;
        })
        .catch((error) => {
          this.sending = false;
          console.log(error);
          this.$root.$emit("isLoading", false);
        });
    },

    getChanges: function() {
      this.sending = true;
      var url = "/api/v2/changes";
      this.$http
        .get(url)
        .then((results) => {
          this.changes = results.data;
          this.sending = false;
        })
        .catch((error) => {
          this.sending = false;
          console.log(error);
          this.$root.$emit("isLoading", false);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../../assets/css/global.css";
</style>
