<template>
  <div>
    <div v-if="language === 'es'">
      He visto que quieres
      <strong>mejorar tu proceso de reclutamiento</strong> ;)<br />
      ¿Sabes que con uProc puedes obtener
      <strong>muchos candidatos</strong> para tu equipo de recursos humanos en
      un momento? <br /><br />
      Solo necesitas seguir uno de estos pasos:
      <ul>
        <li><strong><a href="/#/mydata/lists">Crea</a></strong> una lista y utiliza <strong><a
              href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0"
              target="_blank"
              >uProc for LinkedIn</a></strong> para obtener candidatos desde <strong
            ><a
              href="https://www.linkedin.com/sales/search/people"
              target="_blank"
              >Sales Navigator</a
            ></strong> o <strong><a
              href="https://www.linkedin.com/search/results/people"
              target="_blank"
              >LinkedIn</a></strong>.</li>
        <li><strong><a href="/#/wizard/list">Utiliza</a></strong> nuestra base de datos para generar tus listas de candidatos.</li>
      </ul>
      <br>
      <br>
      <br>

    </div>
    <div class="row" v-else-if="language === 'fr'">
      <div class="col-12">
        J'ai vu que vous voulez <strong>améliorer votre processus de recrutement</strong> ;)<br>
        Savez-vous qu'avec uProc vous pouvez obtenir <strong>de nombreux candidats</strong> pour votre équipe des ressources humaines en un rien de temps?
        <br><br>
        Vous avez juste besoin de suivre l'une de ces étapes:
        <ul>
          <li><strong><a href="/#/mydata/lists">Créez</a></strong> une liste et utilisez <strong><a
              href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0"
              target="_blank"
              >uProc for LinkedIn</a></strong> pour obtenir des candidats depuis <strong
            ><a
              href="https://www.linkedin.com/sales/search/people"
              target="_blank"
              >Sales Navigator</a
            ></strong> ou <strong><a
              href="https://www.linkedin.com/search/results/people"
              target="_blank"
              >LinkedIn</a></strong>.</li>
          <li><strong><a href="/#/wizard/list">Utilisez</a></strong> notre base de données pour générer vos listes de candidats.</li>
        </ul>
        <br>
        <br>
        <br>
      </div>

    </div>
    <div v-else>
      I see that you want to
      <strong>improve your recruitment process</strong> ;)<br />
      Did you know that with uProc you can get
      <strong>many candidates</strong> for your human resources team in
      no time? <br /><br />
      You just need to follow one of these steps:
      <ul>
        <li><strong><a href="/#/mydata/lists" target="_blank">Create</a></strong> a list and use <strong><a
          href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0"
          target="_blank"
          >uProc for LinkedIn</a></strong> to get candidates from <strong
        ><a
          href="https://www.linkedin.com/sales/search/people"
          target="_blank"
          >Sales Navigator</a
        ></strong> or <strong><a
          href="https://www.linkedin.com/search/results/people"
          target="_blank"
          >LinkedIn</a></strong>.</li>
        <li><strong><a href="/#/wizard/list" target="_blank">Use</a></strong> our database to generate your candidate lists.</li>
      </ul>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeRecruitment",
  data() {
    return {
      language: this.$i18n.locale,
    };
  },
  created() {},
  mounted() {},
};
</script>
