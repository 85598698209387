<template>
  <div>
    <div class="row no_side_margin">
      <div class="col-lg-12 no_side_pad">
        <div class="pb-4">
          <a v-on:click="back">
            {{ $t("back") }}
          </a>
        </div>
        <div>
          <div class="row">
            <div class="col-lg-12">
              <h2>
                {{ $t("list") }}: {{ list.name }} ({{list.total}})
              </h2>
            </div>
            <!-- <div class="col-lg-3 col-md-3" style="text-align: right;">
              <div v-if="list && list.elements && list.elements.length > 0 && list.type === 'people'">
                <a type="button" class="btn btn-primary btn-lg" v-bind:href="'#/lists/' + list.id + '/report'" :disabled="this.loading">{{ $t('report' ) }} </a>
                <br>
                <br>
              </div>
            </div> -->
          </div>
          <div class="row pb-4">
            <div class="col-12 text-left">
              <div v-if="list.cost > 0">
                {{ $t("list-cost-description") }}: {{ list.cost.toFixed(2) }}{{ currency }}
              </div>
              <div v-html="$t('list-description')"></div>
            </div>
            <div class="col-lg-12 text-center">
              <a
                href="https://chromewebstore.google.com/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea"
                target="_blank"
                class="btn btn-primary btn-lg add-selection add"
                >{{ $t("list-add-extension") }}</a
              >
              &nbsp;
              <a
                :href="'#/wizard/list/' + list.name"
                class="btn btn-primary btn-lg add-selection add"
                target="_blank"
                >{{ $t("list-add-wizard") }}</a
              >              
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-12 no_side_pad request"
        v-if="this.list.elements && this.list.elements.length"
      >
        <div class="col-lg-12 text-center pb-4" v-if="isAdmin">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="this.loading || this.isRunning || !this.list.verifications.can_be_verified"
            v-on:click="verifyEmails"
          >
            <span v-if="this.isRunning">{{ $t("list-verifying-emails") }}</span>
            <span v-else>
              {{ $t("list-verify-emails", { count: this.list.elements.length }) }}
            </span>
          </button>
          
          <div v-if="!isRunning">
            <div v-if="list.verifications.can_be_verified">
              <small>{{ $t("list-verify-emails-description", {currency: currency}) }}</small>
              <div class="text-xs" v-html="$t('list-verify-emails-description-extended')"></div>
            </div>
              
            <div v-if="list.verifications.total > 0">
              <small>{{ $t("list-verify-emails-last", { count: list.verifications.total, cost: list.verifications.cost.toFixed(2) + currency}) }}{{ list.verifications.checked_at | formatDate }}</small>
            </div>

            <div v-if="!list.verifications.can_be_verified">
              <small>{{ $t("list-verify-emails-no-emails-to-verify") }}</small>
            </div>
          </div>

          <div
            class="col-lg-12 text-center"
            v-else
            :key="countdownToRefresh"
          >
            <small>{{
              $t("jobs-pending-refresh", { seconds: countdownToRefresh })
            }}</small>
          </div>
        </div>
        <div class="page-nav">
          <div class="row no_side_margin">
            <div class="col-lg-2 col-sm-4 col-4 text-right">
              <div>
                <a
                  :class="{ disabled: isFirstPage(), '': !isFirstPage() }"
                  v-on:click="movePrevious()"
                >
                  <span class="nav-icon">←</span> {{ $t("previous") }}
                </a>
              </div>
            </div>

            <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
              {{ $t("page") }} {{ this.list.currentPage + 1 }} /
              {{ this.list.totalPages }}
            </div>

            <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
              <div>
                <a
                  :class="{ disabled: isLastPage(), '': !isLastPage() }"
                  v-on:click="moveNext()"
                >
                  {{ $t("next") }} <span class="nav-icon">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row smry-status no_side_margin" style="overflow-x: auto; width: 100%">
        <table class="table table-striped list-table" style="margin-top: 0px">
          <thead class="thead-fixed">
            <tr>
              <th class="t-head col-lg-2">{{ $t("date") }}</th>
              <th class="t-head col-lg-2">{{ $t("name") }}/{{ $t("email") }}</th>
              <th class="t-head col-lg-2">{{ $t("company") }}/{{ $t("domain") }}</th>
              <th class="t-head col-lg-1">{{ $t("country") }}</th>
              <th class="t-head col-lg-2">{{ $t("role") }}</th>
              <th class="t-head text-center col-lg-1">{{ $t("email") }}</th>
              <th class="t-head text-center col-lg-1">{{ $t("phone") }}</th>
              <th class="t-head col-lg-1"></th>
            </tr>
          </thead>
          <tbody v-if="!list.elements.length">
            <tr>
              <td colspan="7" class="table-col text-center" translate>
                {{ $t("no-results") }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="item in list.elements"
              v-bind:key="item.profile + item.updatedAt"
            >
              <td class="table-col">
                {{ item.updatedAt | formatDate }}
              </td>
              <td class="table-col" v-if="item.name.length">
                {{ item.name }} {{ item.surname }}
              </td>
              <td
                class="table-col"
                v-if="!item.name.length && !item.email_str.length"
              >
                -
              </td>
              <td
                class="table-col"
                v-if="!item.name.length && item.email_str.length"
              >
                {{ item.email_str }}
              </td>
              <td class="table-col" v-if="item.company.length">
                {{ item.company }}
              </td>
              <td
                class="table-col"
                v-if="!item.company.length && item.domain && item.domain.length"
              >
                {{ item.domain }}
              </td>
              <td
                class="table-col"
                v-if="
                  !item.company.length && (!item.domain || !item.domain.length)
                "
              >
                -
              </td>
              <td class="table-col" v-if="item.country.length">
                {{ item.country }}
              </td>
              <td class="table-col" v-if="!item.country.length">-</td>
              <td class="table-col">{{ item.role }}</td>
              <td class="table-col text-center">

                <font-awesome-icon
                  v-if="item.email === 'Yes' && item.confidence === 'verified'"
                  icon="check-circle"
                  :title="$t('email-yes-verified')"
                  class="text-success"
                />

                <font-awesome-icon
                  v-if="item.email === 'Yes' && item.confidence !== 'verified'"
                  icon="check-circle"
                  :title="$t('email-yes-risky')"
                  class="text-warning"
                />

                <font-awesome-icon
                  v-if="item.email === 'No'"
                  icon="times-circle"
                  class="text-danger"
                  :title="$t('email-no')"
                />
              </td>
              <td class="table-col text-center">
                <font-awesome-icon
                  v-if="item.phone === 'Yes'"
                  icon="check-circle"
                  class="text-success"
                  :title="$t('phone-yes')"
                />

                <font-awesome-icon
                  v-if="item.phone === 'No'"
                  icon="times-circle"
                  class="text-danger"
                  :title="$t('phone-no')"
                />
                
              </td>
              <td class="table-col text-center">
                <div class="icon">
                  <a
                    class="pointer"
                    :title="$t('list-element-delete-title')"
                    v-on:click="deleteElement(item.id)"
                    v-if="item && item.id.length"
                  >
                    <font-awesome-icon icon="trash"/>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
    <div class="pt-4" v-if="list.elements.length > 0">
      <div class="col-md-9 col-md-9">
        <h4>{{ $t("actions") }}</h4>
      </div>
      <div class="data p-4">
        <div>
          <h6 class="text-left">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                {{ $t("list-actions-fields-people") }}.

                <a href="javascript: void()" v-on:click="showPeopleFields()">
                  <span v-if="shownPeopleFields">{{
                    $t("list-hide-all-fields")
                  }}</span>
                  <span v-else>{{ $t("list-show-all-fields") }}</span>
                </a>
              </div>
            </div>
          </h6>
          <div v-if="shownPeopleFields" class="text-center">
            <ListTypePerson />
          </div>
        </div>
        <h6 class="text-left">{{ $t("list-actions-download-free") }}</h6>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-center">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="this.loading"
              v-on:click="downloadAction()"
            >
              {{ $t("list-download-csv") }}
            </button>
          </div>
          <div class="col-lg-6 col-sm-12 text-center">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="isNoActiveWebhook"
              v-on:click="forwardAction()"
            >
              {{ $t("list-forward-webhooks") }}
            </button>
          </div>
        </div>
        <div class="row" v-if="downloaded || forwarded">
          <div class="col-lg-12 text-center text-success">
            <br />
            <!-- <div v-if="downloaded" v-html="$t('list-downloaded')"></div>
            <div v-if="forwarded" v-html="$t('list-forwarded')"></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4" v-if="list.elements.length > 0">
      <div class="col-md-12 col-md-12">
        <h4>{{ $t("webhooks") }}</h4>
      </div>
      <div class="data text-left p-4">
        <h6 class="text-left" v-html="$t('list-zapier-howto')"></h6>
        <div class="row">
          <div class="col-lg-12 text-center pb-4">
            <button
              type="button"
              class="btn btn-primary add-selection add"
              :disabled="this.loading"
              v-on:click="addForward()"
            >
              {{ $t("webhook-add") }}
            </button>
          </div>
        </div>

        <div class="row no_side_margin" v-if="!new_forward">
          <div class="list-scroller" style="overflow-x: auto">
            <table
              class="table table-striped list-table"
              style="margin-top: 0px"
            >
              <thead>
                <tr>
                  <th class="t-head text-center col-1">{{ $t("enabled") }}</th>
                  <th class="t-head">{{ $t("url") }}</th>
                  <th class="t-head col-1">{{ $t("type") }}</th>
                  <th class="t-head col-1">{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody v-if="!list.forwards.length">
                <tr>
                  <td colspan="4" class="text-center">
                    {{ $t("no-webhooks") }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="list.forwards && list.forwards.length">
                <tr
                  v-for="forward in list.forwards"
                  v-bind:key="forward.createdAt"
                >
                  <td class="table-col text-center">
                    <a v-on:click="toggleForward(forward)" role="button">
                      <Status name="enabled" :value="forward.enabled" />
                    </a>
                  </td>
                  <td class="table-col">{{ forward.to_webhook }}</td>
                  <td class="table-col">{{ forward.type }}</td>
                  <td class="table-col">
                    <div class="total-icon">
                      <div
                        class="icon"
                        v-show="!isZapierWebhook(forward.to_webhook)"
                      >
                        <a
                          :title="$t('view')"
                          v-bind:href="'#/lists/' + list.id"
                          v-on:click="editForward(forward)"
                        >
                          <font-awesome-icon icon="edit" />
                        </a>
                      </div>
                      <div
                        class="icon"
                        v-show="!isZapierWebhook(forward.to_webhook)"
                      >
                        <a
                          :title="$t('delete')"
                          v-bind:href="'#/lists/' + list.id"
                          v-on:click="deleteForward(forward._id)"
                        >
                          <font-awesome-icon icon="trash" />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row no_side_margin" v-if="new_forward">
          <div class="col-lg-12 total-bg no_side_pad text-left">
            <h4>{{ $t("webhook-new") }}</h4>
            <div class="profile-settings">
              <div class="fileld">
                <div class="row">
                  <label class="col-lg-1 col-sm-2">{{ $t("enabled") }}</label>
                  <div class="col-lg-11 col-sm-10">
                    <input
                      type="checkbox"
                      name="optradio"
                      v-model="forward.enabled"
                      v-on:change="hasToBeSaved()"
                    />
                  </div>
                </div>
              </div>
              <div class="fileld">
                <div class="row">
                  <label class="col-lg-1 col-sm-2">{{ $t("url") }}</label>
                  <div class="col-lg-11 col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      id="webhook"
                      v-model="forward.to_webhook"
                      placeholder="Your webhook url"
                      v-on:keyup="hasToBeSaved()"
                      v-on:click="$event.target.select()"
                    />
                    <small>{{ $t("list-forward-url-description") }}</small>
                  </div>
                </div>
              </div>

              <div class="fileld">
                <div class="row">
                  <label class="col-lg-1 col-sm-2">{{ $t("type") }}</label>
                  <div class="col-lg-11 col-sm-10">
                    <select v-model="forward.type" class="form-control" v-on:change="hasToBeSaved()">
                      <option value="json" selected>JSON (default)</option>
                      <option value="form">Form data</option>
                    </select>
                    <small v-html="$t('list-forward-type-description')"></small>

                  </div>
                </div>
              </div>

              <div class="fileld">
                <div class="row">
                  <label class="col-lg-1 col-sm-2">{{ $t("list-forward-headers") }}</label>
                  <div class="col-lg-11 col-sm-10">
                    <textarea
                      class="form-control"
                      v-model="forward.headers"
                      :placeholder="$t('list-forward-headers-placeholder')"
                      v-on:keyup="hasToBeSaved()"
                    ></textarea>
                    <small>{{$t('list-forward-headers-description')}}</small>
                  </div>
                </div>
              </div>
              <div class="fileld">
                <div class="row">
                  <label class="col-lg-1 col-sm-2">{{ $t("list-forward-body") }}</label>
                  <div class="col-lg-11 col-sm-10">
                    <textarea
                    rows="10" 
                      class="form-control"
                      v-model="forward.body"
                      :placeholder="$t('list-forward-body-placeholder')"
                      v-on:keyup="hasToBeSaved()"
                    ></textarea>

                    <small v-html="$t('list-forward-body-description')"></small>
                    <textarea class="form-control" disabled rows="10" cols="50" v-model="sampleBody"></textarea>
                  </div>
                </div>
                
              </div>

              <div class="fileld">
                <div class="row">
                  <div class="col-lg-1 col-sm-2"></div>
                  <div class="col-lg-3 col-sm-5">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm btn-block add-selection add"
                      v-on:click="cancelForward()"
                    >
                      {{ $t("cancel") }}
                    </button>
                  </div>
                  <div class="col-lg-3 col-sm-5">
                    <button
                      :disabled="!this.hasToBeSent"
                      type="button"
                      class="btn btn-primary btn-sm btn-block add-selection add no-margin"
                      v-on:click="saveForward()"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
import ListTypePerson from "@/components/Lists/ListTypePerson.vue";

export default {
  name: "list",
  data() {
    return {
      isAdmin: false,
      script_uri: "https://wetrack.uproc.io/javascripts/main.js",
      lists: [],
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,
      startItems: "-",
      finishItems: "-",
      list: {
        elementsFiltered: "",
        elements: "",
        forwards: [],
        currentPage: 0,
      },
      search: {
        area: "",
        seniority: "",
        email: "",
        phone: "",
      },
      Emails: "",
      processing: [],
      downloaded: false,
      forwarded: false,
      new_forward: false,
      forward: null,
      forwardOriginal: null,
      hasToBeSent: false,

      shownPeopleFields: false,
      shownWebFields: false,
      currency: "$",

      countdown: null,
      countdownToRefresh: 30,
      secondsToRefresh: 30,
      isRunning: false,

      sampleBody: JSON.stringify({
        data: {
          person: {
        id: "5ef85f3f3d17c4000baed6b6",
        added: "2021-03-23 15:05:15",
        name: "Miquel",
        surname: "Colomer Salas",
        gender: "male",
        email: "miquel@uproc.io",
        email_confidence: "risky",
        email_score: "45",
        profile: "https://www.linkedin.com/in/miquelcolomersalas",
        area: "ceo",
        seniority: "executive",
        is_decision_maker: "Yes",
        is_c_level: "Yes",
        role: "Founder & Ceo",
        annual_salary: ">$25K",
        personal_email: "mcolomer@gmail.com",
        personal_phone: "+34 605 28 12 20",
        facebook: "",
        github: "",
        twitter: "",
          },
          company_general: {
        name: "Uproc",
        description:
          "UProc - Grow your business with reliable data: Clean, verify or enrich any data using tools provided by UProc",
          },
          company_contact: {
        phone: "+34 605 28 12 20",
        email: "hello@uproc.io",
        website: "http://uproc.io/",
        domain: "uproc.io",
        address: "",
        zipcode: "",
        city: "Granollers",
        state: "",
        county: "",
        country: "Spain",
        country_code: "ES",
        latitude: "",
        longitude: "",
        language_code: "es",
        language_name: "Spanish (Spain)",
        timezone: "",
        zone: "",
        offset: "",
          },
          company_financial: {
        type: "B2B",
        year_founded: "",
        industry: "Information Technology & Services",
        revenue: "",
        size: "2-10",
          },
          company_website: {
        query_date: "2020-12-29 18:07:09",
        rank: "2836170",
        last_week_visits: "",
        last_month_visits: "",
        last_quarter_visits: "5000",
          },
          company_blog: {
        last_entry_date: "2017-04-21 19:20:54",
        last_entry_title: "Humanizamos tu experiencia",
        last_entry_author: "Miquel Colomer Salas",
        last_entry_link: "https://killia.com/humanizamos-tu-experiencia/",
          },
          company_social: {
        discord: "",
        facebook: "http://www.facebook.com/uprocdataquality",
        github: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/company/uprocllc",
        pinterest: "",
        slack: "",
        slideshare: "",
        telegram: "",
        tiktok: "",
        twitch: "",
        twitter: "https://twitter.com/uproc_io",
        youtube: "https://www.youtube.com/channel/UCn9GLq3_iHLMxeub4YWrWXA",
        activity: 30,
          },
          company_technologies: {
        spend: "",
        has_shop: "No",
        result:
          "Analytics:Google Analytics,Analytics:Hotjar,Email:Amazon SES,Email:Mailgun,Font scripts:Font Awesome,JavaScript frameworks:Vue.js,JavaScript libraries:core-js,JavaScript libraries:Preact,Live chat:Drift,Marketing automation:OneSignal,Miscellaneous:Open Graph,Miscellaneous:PWA,Miscellaneous:webpack,PaaS:Amazon Web Services,Surveys:Hotjar Incoming Feedback,Web servers:Nginx,Webmail:Google Workspace",
          },
          linkedin: {
        search: "",
          },
        },
        list: "my-list",
        type: "people",
      }, null, 2),
    };
  },
  mounted() {
    this.getUserDetails();
    this.updateListData(true);
    this.getProcessingValues();
  },
  components: {
    Status,
    ListTypePerson,
  },
  computed: {
    isNoActiveWebhook: function () {
      var noWebhook = this.loading || !this.list.forwards.length;
      if (this.list && this.list.forwards && this.list.forwards.length) {
        var filtered = this.list.forwards.filter(function (f) {
          return f.enabled;
        });
        if (!filtered.length) {
          noWebhook = true;
        }
      }

      return noWebhook;
    },

    stats: function () {
      var local_stats = {};
      if (this.list.trackStats) {
        local_stats = this.list.trackStats.not_added;
      } else if (this.list.stats) {
        local_stats = this.list.stats.not_added;
      }

      return {
        not_added: local_stats.total ? local_stats.total : 0,
        blacklist: local_stats.blacklist ? local_stats.blacklist : 0,
        filter: local_stats && local_stats.filter ? local_stats.filter : 0,
        balance: local_stats && local_stats.balance ? local_stats.balance : 0,
        duplicated:
          local_stats && local_stats.duplicated ? local_stats.duplicated : 0,
      };
    },
  },

  methods: {
    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    clearCountdown: function() {
      clearInterval(this.countdown);
      //clear all intervals
      for (var i = 1; i < 100; i++) {
        window.clearInterval(i);
      }
    },

    setTimeoutList: function() {
      setTimeout(() => {
        this.clearCountdown();
        this.getList(this.$route.params.name, true);
      }, 30000);
    },

    initCountdown: function() {
      this.countdownToRefresh = this.secondsToRefresh;
      this.countdown = setInterval(() => {
        this.countdownToRefresh--;
        if (this.countdownToRefresh === 0) {
          this.clearCountdown();
          this.getList(this.$route.params.name, true);
        }
      }, 1000);
    },

    back: function () {
      this.$router.push({ path: "/mydata/lists" });
    },

    showPeopleFields() {
      this.shownPeopleFields = !this.shownPeopleFields;
    },
    showWebFields() {
      this.shownWebFields = !this.shownWebFields;
    },

    isFirstPage: function () {
      return this.list.currentPage == 0;
    },

    movePrevious: function () {
      if (!this.isFirstPage()) {
        this.movePreviousProcess();
        this.updateListData(true);
      }
    },

    movePreviousProcess: function () {
      this.list.currentPage--;
    },

    isLastPage: function () {
      return this.list.currentPage == this.list.totalPages - 1;
    },

    moveNext: function () {
      if (!this.isLastPage()) {
        this.moveNextProcess();
        this.updateListData(true);
      }
    },

    moveNextProcess: function () {
      this.list.currentPage++;
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
        this.isAdmin = this.user.role === "admin";
      });
    },

    getEmail: function () {
      return this.user
        ? this.user.email
        : localStorage.configEmail
        ? localStorage.configEmail
        : "test@uproc.io";
    },

    verifyEmails: function () {
      var url = "/api/v2/list/" + this.$route.params.name + "/verify";
      this.$http.get(url, this.getConfigAuth()).then((response) => {
        if (!response.data.result) {
          this.$toast.error(this.$t(response.data.message, {"name": this.list.name}));
        } else {
          this.$toast.success(this.$t(response.data.message, {"name": this.list.name}));
          this.getList(this.$route.params.name, true);
        }
      });
    },

    getBalance: function () {
      return this.user ? this.getCredits() * this.getCostPerCredit() : 0;
    },

    getCostPerCredit: function () {
      return this.processing.costPerCredit;
    },

    getProcessingValues: function () {
      var url = "/api/v2/processing";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.processing = results.data;
        this.currency = this.processing.currency === 'usd' ? '$' : '€';
      });
    },

    showNameInput: function () {
      this.editMode = true;
      this.$nextTick(() => {
        this.$refs.list_name.focus();
        this.$refs.list_name.select();
      });
    },

    //FROM HERE CURRENT LIST AREA FUNCTIONS
    updateListData: function (forced) {
      if (this.$route.params.name !== undefined) {
        this.getList(this.$route.params.name, forced);
      }
    },

    getList: function (name, forced) {
      if (!this.pendingRequest && forced) {
        this.$root.$emit("isLoading", true);
        this.pendingRequest = true;
        this.loading = true;
        var url = "/api/v2/list/" + name + "?page=" + this.list.currentPage;
        this.$http.get(url, this.getConfigAuth()).then((results) => {
          //console.log(this.getCurrentReport(results.data));
          this.list = this.getCurrentReport(results.data); //console.log(this.list);
          this.pendingRequest = false;
          this.loading = false;
          this.startItems = this.list.pageSize * this.list.currentPage;
          this.finishItems = this.startItems + this.list.elements.length;
          this.$root.$emit("isLoading", false);

          this.isRunning = this.list.status === "running" || this.list.status === "verifying";
          //if list.verifications.checked_at more than 1h ago, discard isRunning
          if (this.isRunning && this.list.verifications.checked_at) {
            var checked_at = new Date(this.list.verifications.checked_at);
            var now = new Date();
            var diff = now - checked_at;
            if (diff > 5 * 60 * 1000) {
              this.isRunning = false;
            }
          }

          if (this.isRunning) {
            this.initCountdown();
          } else {
            this.clearCountdown();
          }
        });
      }
    },

    getCurrentReport: function (l) {
      if (l === null || l === undefined) {
        return [];
      }
      var graphs = [];
      var self = this;
      l.elements.forEach(function (e) {
        graphs = self.updateGraphs(graphs, e, "area");
        graphs = self.updateGraphs(graphs, e, "seniority");
        graphs = self.updateGraphs(graphs, e, "country");
        graphs = self.updateGraphs(graphs, e, "gender");
        graphs = self.updateGraphs(graphs, e, "email");
        graphs = self.updateGraphs(graphs, e, "confidence");
        graphs = self.updateGraphs(graphs, e, "phone");
      });
      l.graphs = graphs;

      return l;
    },

    updateGraphs: function (graphs, element, name) {
      var currentGraph = graphs.filter(function (i) {
        return i.key == name;
      });
      var newGraphs = graphs.filter(function (i) {
        return i.key != name;
      });
      var currentGraphItem = null;
      if (!currentGraph.length) {
        currentGraphItem = {
          key: name,
          data: [],
        };
      } else {
        currentGraphItem = currentGraph[0];
      }

      var currentValue =
        element && element[name] && element[name].length
          ? element[name].toLowerCase()
          : "unknown";

      if (name === "email") {
        currentValue = currentValue === "unknown" ? "false" : "true";
      }

      if (name === "phone") {
        currentValue = currentValue === "unknown" ? "false" : "true";
      }

      var currentData = currentGraphItem.data.filter(function (i) {
        return i.key == currentValue;
      });
      var oldData = currentGraphItem.data.filter(function (i) {
        return i.key !== currentValue;
      });

      var item = {};
      if (!currentData.length) {
        item = {
          key: currentValue,
          value: 0,
        };
      } else {
        item = currentData[0];
      }

      item.value++;
      oldData.push(item);

      currentGraphItem.data = oldData;
      newGraphs.push(currentGraphItem);

      return newGraphs;
    },

    deleteElements: function () {
      this.loading = true;
      this.pendingRequest = true;
      var url = "/api/v2/list/" + this.$route.params.name + "/delete";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.pendingRequest = false;
        this.resetFilters();
        this.list = results.data;
        //this.getList(this.$route.params.name, true);
      });
    },

    isZapierWebhook: function (forward) {
      return forward.indexOf("zapier.com") !== -1;
    },

    hasToBeSaved: function () {
      if (this.forward !== null && this.forwardOriginal !== null) {
        this.hasToBeSent =
          JSON.stringify(this.forward) !== JSON.stringify(this.forwardOriginal);
      } else {
        this.hasToBeSent = false;
      }
    },

    addForward: function () {
      this.hasToBeSent = true;
      this.new_forward = true;
      this.forward = {
        enabled: true,
        to_webhook: "https://beeceptor.com/",
        type: "json",
        headers: "",
        body: "",
        filters: {
          area: "",
          seniority: "",
          email: "",
          phone: "",
        },
      };
      this.forwardOriginal = {};
    },

    cancelForward: function () {
      this.hasToBeSent = false;
      this.new_forward = false;
    },

    deleteForward: function (id) {
      var new_forwards = [];
      this.list.forwards.filter(function (f) {
        if (f._id !== id) {
          new_forwards.push(f);
        }
      });

      this.list.forwards = new_forwards;
      this.saveList();
    },

    saveForward: function () {
      var new_forwards = [];
      var aux_forward = JSON.parse(JSON.stringify(this.forward));
      aux_forward.filters = [];

      var that = this;
      //Save filters
      Object.keys(this.forward.filters).forEach(function (key) {
        aux_forward.filters.push({
          name: key,
          value: that.forward.filters[key],
        });
      });

      this.list.forwards.filter(function (f) {
        if (f._id !== aux_forward._id) {
          new_forwards.push(f);
        } else {
          new_forwards.push(aux_forward);
        }
      });

      if (!aux_forward._id) {
        new_forwards.push(aux_forward);
      }

      this.list.forwards = new_forwards;
      this.loading = true;
      this.saveList();
    },

    editForward: function (forward) {
      var str = JSON.stringify(forward);
      this.forward = JSON.parse(str);
      this.forwardOriginal = JSON.parse(str);
      this.new_forward = true;
      this.hasToBeSent = false;
    },

    saveList: function () {
      this.loading = true;
      this.list.name = this.list.name.replace(/\//g, "").trim();

      var url = "/api/v2/list/" + this.list.id;
      this.$http.post(url, this.list, this.getConfigAuth()).then((results) => {
        this.list.forwards = results.data.forwards;
        this.forward = null;
        this.new_forward = false;
        this.loading = false;
      });
    },

    executeGenericAction: function (action) {
      this.downloaded = false;
      this.forwarded = false;
      var filters = this.search;
      filters.confidence =
        filters.email.indexOf("-") !== -1 ? filters.email.split("-")[1] : "";
      filters.email =
        filters.email.indexOf("-") !== -1 ? filters.email.split("-")[0] : "";
      this.loading = true;

      var filtersStr = Object.keys(filters)
        .map(function (key) {
          return key + "=" + filters[key];
        })
        .join("&");
      var url = "/api/v2/list/" + this.$route.params.name + "/" + action;
      if (this.list.type === "people") {
        url += "?" + filtersStr;
      }

      this.$http.get(url, this.getConfigAuth()).then(() => {
        //var data = results.data;
        this.loading = false;
        if (action === "download") {
          this.$toast.success(this.$t("list-downloaded"));
        } else {
          this.$toast.success(this.$t("list-forwarded"));
        }
      });
    },

    forwardAction: function () {
      this.executeGenericAction("forward");
    },

    downloadAction: function () {
      this.executeGenericAction("download");
    },

    deleteElementProcess: function (element_id) {
      var url = "/api/v2/list/" + this.list.name + "/" + element_id + "/delete";
      console.log(url);
      this.$http.get(url, this.getConfigAuth()).then(() => {
        this.getList(this.$route.params.name, true);
      });
    },

    deleteElement: function (element_id) {
      //var that = this;
      this.deleteElementProcess(element_id);
      /*
      that.$modal.show('dialog', {
        title: that.$t('list-element-delete-title'),
        text: that.$t('list-element-delete-description'),
        buttons: [
          {
            title: that.$t('accept'),
            handler: () => {
              that.pendingRequest = true;
              //that.deleteListProcess(listId);
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      });*/
    },

    toggleForward: function (forward) {
      console.log(forward);
      this.forward = forward;
      this.forward.enabled = !this.forward.enabled;
      console.log(this.forward);
      this.saveForward();
    },
  },

  watch: {
    $route: "updateListData",
  },
};
</script>
