<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>


      <div class="row no_side_margin">
        <div class="total col-lg-12 ">

          <div class="tab-content">

            <div id="home" class="tab-pane container active">

              <div class="row">
                <div class="col-sm-9 col-7 col-title ">
                  <h4>{{ $t('list' ) }}
                    <!-- {{ this.totalRecordsSection }} -->
                    <span v-if="this.totalRecordsSection.tools > 0">({{ this.totalRecordsSection.tools }})</span>
                  </h4>
                </div>
              </div>

              <div class="d-flex align-items-center pt-3 mb-4">
                <div class="select_period col-text mr-2">
                  <p class="text">{{ $t('search-by') }}:</p>
                </div>
                <div class="">
                    <input type="text" v-model="filterTool" placeholder="domain, get by domain, ..." class="form-control" style="max-width: 300px;" v-on:keyup="searchToolsInput($event)" v-on:focus="$event.target.select()">
                </div>
                <div class="ml-2">
                    <button @click="searchTools()" class="btn btn-success" :disabled="!filterTool.length">{{ $t('search') }}</button>
                </div>
              </div>

                <div class="col-lg-12 no_side_pad request" v-if="this.tools && this.tools.length">
                <div class="page-nav">
                  <div class="row no_side_margin">
                  <div class="col-lg-2 col-sm-4 col-4 text-right">
                    <div><a :class="{'disabled': isFirstPageBySection('tools'), '': !isFirstPageBySection('tools')}" v-on:click="movePreviousBySection('tools')"><span class="nav-icon">←</span> {{ $t('previous' ) }} </a></div>
                  </div>
                  
                  <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                    {{ $t('page' ) }} {{ this.currentPageSection.tools + 1 }} / {{ this.totalPagesSection.tools }}
                  </div>

                  <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                    <div><a :class="{'disabled': isLastPageBySection('tools'), '': !isLastPageBySection('tools')}" v-on:click="moveNextBySection('tools')"> {{ $t('next' ) }} <span class="nav-icon">→</span></a></div>
                  </div>
                  </div>
                </div>
                </div>

              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('tool' ) }}</th>
                      <th class="t-head">{{ $t('category' ) }}</th>
                      <th class="t-head">{{ $t('params' ) }}</th>
                      <th class="t-head text-center">{{ $t('public' ) }}</th>
                      <th class="t-head text-center">{{ $t('detector' ) }}</th>
                      <th class="t-head text-center">{{ $t('proposed' ) }}</th>
                      <th class="t-head text-right">{{ $t('price_cpt' ) }}</th>
                      <th class="t-head text-right">{{ $t('concurrency' ) }}</th>
                    </tr>
                  </thead>

                  <tbody v-if="!this.tools || !this.tools.length">
                    <tr>
                      <td colspan="7" class="table-col text-center">{{ $t('no-results' ) }}</td>
                    </tr>
                  </tbody>

                  <tbody v-if="this.tools && this.tools.length">
                    <tr v-for="entry in this.tools" v-bind:key="entry.key">
                      <!-- {{ entry }} -->
                      <td class="table-col col-lg-5">
                        {{$t('type-' + entry.type + '-verb') }}
                        {{entry.description[currentLocale]}} 
                        - <a :href="'#/tools/processor/' + entry.key.replace('-', '/').replace('-', '/')" target="_blank">{{entry.key}}</a>
                      </td>
                      <td class="table-col col-lg-1">
                        {{entry.groups.map(group => $t(`group-${group}-name`)).join(', ')}}
                      </td>
                      <td class="table-col col-lg-3">{{entry.params.map(param => param.name).join(', ')}}</td>
                      <td class="table-col col-lg-1 text-center">
                        <font-awesome-icon v-if="entry.public" icon="check-circle" @click="toggleFieldValue(entry.key, 'public')" class="pointer text-success" size="lg"></font-awesome-icon>
                        <font-awesome-icon v-else icon="times-circle" @click="toggleFieldValue(entry.key, 'public')" class="pointer text-danger" size="lg"></font-awesome-icon>
                      </td>
                      <td class="table-col col-lg-1 text-center">
                        <font-awesome-icon v-if="entry.detector" icon="check-circle" @click="toggleFieldValue(entry.key, 'detector')" class="pointer text-success" size="lg"></font-awesome-icon>
                        <font-awesome-icon v-else icon="times-circle" @click="toggleFieldValue(entry.key, 'detector')" class="pointer text-danger" size="lg"></font-awesome-icon>
                      </td>
                      <td class="table-col col-lg-1 text-center">
                        <font-awesome-icon v-if="entry.proposed" icon="check-circle" @click="toggleFieldValue(entry.key, 'proposed')" class="pointer text-success" size="lg"></font-awesome-icon>
                        <font-awesome-icon v-else icon="times-circle" @click="toggleFieldValue(entry.key, 'proposed')" class="pointer text-danger" size="lg"></font-awesome-icon>
                      </td>
                      <td class="table-col col-lg-1 text-right">{{(entry.cost/100).toFixed(2) | numberOnly}}{{currency}}</td>
                      <td class="table-col col-lg-1 text-right" v-if="edited_tool_key != entry.key">
                        <a @click="edited_tool_key = entry.key" class="cursor-pointer" >{{entry.max_requests}}</a>
                      </td>
                      <td class="table-col col-lg-1 text-right" v-else>
                        <div class="input-group">
                          <input type="number" 
                          v-model="entry.max_requests" 
                          v-if="edited_tool_key == entry.key" 
                          class="form-control text-xs" 
                          style="width: 100px;" 
                          v-on:focus="$event.target.select()" 
                          v-on:blur="edited_tool_key = ''"
                          v-on:change="editFieldValue(entry.key, 'max_requests', entry.max_requests)" 
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row" v-if="customSearchTool">
                <div class="d-flex align-items-center pt-3 mb-4 col-md-4 col-text">
                  <button v-on:click="cancelSearch" class="btn btn-info">{{ $t('cancel') }}</button>
                </div>
              </div>
            </div>
                
          </div>

        </div>
      </div>

              
              
      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Dashboard',
  components: {
    Header,
    Sidebar,
    Footer,
    
  },
  data() {
    return {
      isActive: false,
      isAdmin: false,
      isActiveIcon: '<',
      period: 'thisweek',
      periodAmounts: 7,
      selectedUser: 'me',
      selectedUserEmail: '',
      selectedUserObj: {},
      customSearch: false,
      customSearchTool: false,

      currentLocale: this.$i18n.locale,
      filterStatus: 'paid',

      balance: 1,

      kpisAll: {
        total_day: -1,
        cost: -1,
        forecast: "-"
      },
      kpisList: [],
      kpisTotal: {
        all: {
          count: "-",
        }
      },
      isCostByHour: true,
      startDate: '',
      endDate: '',
      graphOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      datacollection: {
        datasets: []
      },
      toggleClass: "ani1",
      state: [],
      config: {},
      graph: {
        data: [],
        options: {}
      },

      loading: false,

      totalPagesSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      currentPageSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      totalRecordsSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      totalRowsSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0,
      },

      rowsPerPageSection: {
        jobs: 10,
        requests: 10,
        tools: 10,
        sales: 10,
        users: 10
      },

      prevStartDate: null,
      prevEndDate: null,
      requests: [],
      requestShown: -1,
      shownAllRequests: false,
      jobs: [],
      jobsRunning: false,
      countdownToRefresh: 30,
      secondsToRefresh: 30,

      totalTools: [],
      totalUsers: [],
      filterText: '',
      filterTool: '',
      
      tools: {
        results: [],
      },
      edited_tool_key: '',

      sales: {
        invoices: [],
        payments: [],
        filteredPayments: [],
        subscriptions: [],
        total: 0
      },
      users: {
        list: [],
        total: []
      },

      result: null,
      currency: '€',
      
      status: {
        apps: [],
        services: []
      },
    };
  },
  mounted() {
    this.enableInterceptor();
    this.getTools();
  },
  methods: {
    recoverStorageValues() {
      this.isAdmin = localStorage.role === 'admin';
      this.period = localStorage.period || 'thisweek';
      this.selectedUser = localStorage.role === 'admin' ? localStorage.panelSelectedUser : 'me';
      if (!this.selectedUser) {
        this.selectedUser = 'me';
      }
    },
    
    getConfigAuth() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    getProcessingValues: function() {
      const url = '/api/v2/processing';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.processing = results.data;
        this.currency = this.processing.currency === 'usd' ? '$' : '€';
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    toggleFieldValue(toolKey, field) {
      this.$http.get(`/api/v2/panel/tools/${toolKey}/toggle/${field}`, this.getConfigAuth()).then(() => {
        this.getTools(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
      });
    },

    editFieldValue(toolKey, field, value) {
      this.$http.get(`/api/v2/panel/tools/${toolKey}/edit/${field}/${value}`, this.getConfigAuth()).then(() => {
        this.edited_tool_key = '';
        this.getTools(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    setSection(section) {
      $('.nav-tabs a[href="#' + section + '"]').tab('show');
    },

    setSectionAndUrl(section) {
      //update url
      if (section.length) {
        section = '/' + section;
      }
      window.history.pushState({}, '', '#/dashboard' + section);
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areDatesDifferents: function(setDates) {
      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    isFirstPageBySection: function(section) {
      return this.currentPageSection[section] === 0;
    },

    
    isLastPageBySection: function(section) {
      return this.currentPageSection[section] === this.totalPagesSection[section] - 1;
    },

    showToolDetails: function(requestIndex) {
      this.requestShown = requestIndex;
    },

    moveNextBySection: function(section) {
      if (this.isLastPageBySection(section)) {
        this.loading = true;
      }
      this.moveNextTools();
    },

    isImage: function(value) {
      return value && value.indexOf('http') !== -1 && (value.indexOf('.png') !== -1 || value.indexOf('.jpg') !== -1 || value.indexOf('.jpeg') !== -1 || value.indexOf('.gif') !== -1);
    },

    isUrl: function(value) {
      return value && value.indexOf('http') !== -1;
    },

    moveNextTools: function() {
      if (this.currentPageSection.tools + 1 < this.totalPagesSection.tools) {
        this.currentPageSection.tools++;
        this.getTools(true);
      }
    },

    movePreviousBySection: function(section) {
      this.clearCountdown();
      if (this.isFirstPageBySection(section)) {
        this.loading = true;
      }
      this.movePreviousTools();
    },

    movePreviousTools: function() {
      if (this.currentPageSection.tools - 1 >= 0) {
        this.currentPageSection.tools--;
        this.getTools(true);
      }
    },

    getTools: function() {
      var url = `/api/v2/panel/tools/${this.currentPageSection.tools}`;
      if (this.filterTool && this.filterTool.length) {
        url += `?filter=${this.filterTool}`;
      }
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.tools = results.data.results;
        this.totalRecordsSection.tools = results.data.count;
        this.totalPagesSection.tools = results.data.totalPages;
        this.rowsPerPageSection.tools = results.data.rowsPerPage;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    searchToolsInput: function(e) {
      if (e.keyCode === 13) {
        this.currentPageSection.tools = 0;
        this.getTools(true);
      }
    },

    searchTools: function() {
      this.getTools(true);
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    fillData() {
      var days = [];
      var costs = [];
      var savings = [];
      this.dayData.map(function(element) {
        var type = element['key'];
        element['values'].map(function(elements) {
          var conv = elements[0];
          var actualDate = parseInt(conv, 10);
          var date = new Date(actualDate);

          var months_arr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

          // Hours part from the timestamp

          var year = date.getFullYear();

          // Month
          var month = months_arr[date.getMonth()];

          // Day
          var day = date.getDate();

          // Hours
          //var hours = date.getHours();

          // Minutes
          //var minutes = "0" + date.getMinutes();

          // Seconds
          //var seconds = "0" + date.getSeconds();

          // Display date time in MM-dd-yyyy h:m:s format
          var convdataTime = day + '/' + month + '/' + year;
          elements.date = convdataTime;
          elements.value = elements[1];
          if (elements.date && days.indexOf(elements.date) === -1) {
            days.push(elements.date)
          }

          if (type === 'cost') {
            costs.push(elements[1])
          } else if (type === 'saving') {
            savings.push(elements[1])
          }
        })
      })

      this.datacollection = {
        labels: days,
        datasets: [
          {
            label: this.$t('expense') + ` (${this.currency})`,
            backgroundColor: "rgb(49, 119, 180)",
            data: costs,
            fill: 'origin'
          },
          // {
          //   label:  this.$t('savings') + ` (${this.currency})`,
          //   backgroundColor: "rgb(174, 199, 232)",
          //   data: savings,
          //   fill: 'origin'
          // }
        ]
      }
    },

    setPeriodAmounts: function() {
      //calculate with startDate and isCostByHour
      const currentDate = this.endDate.getTime() > new Date().getTime() ? new Date() : this.endDate;
      //add 1 day to startDate
      const startDate = new Date(this.startDate);
      var diff = currentDate.getTime() - startDate.getTime();
      //console.log(diff, startDate, currentDate);
      if (diff > 0) {
        var days = diff / 1000 / 60 / 60 / 24;
        this.periodAmounts = days;
        //console.log(days);

        if (this.isCostByHour) {
          this.periodAmounts = days * 24;
        }
      }
    },

    clearCountdown: function() {
      clearInterval(this.countdown);
      //clear all intervals
      for (var i = 1; i < 100; i++) {
        window.clearInterval(i);
      }
    },

    resetCurrentPage: function() {
      this.currentPageSection = {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      };
    },
  },
  watch: {

    state: function() {
    },
  },
  computed: {

    orderedList: function() {
      return _.orderBy(this.kpisList, ['key'], ['asc'])
    },

    dayData() {
      if (this.state != 0) {
        return this.state;
      } else {
        return [{
          "key": "cost",
          "values": [
            [Date.now(), 0]
          ]
        }, {
          "key": "saving",
          "values": [
            [Date.now(), 0]
          ]
        }];
      }
    }
  }
}
</script>

<style scoped>
#secure {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 20px;
  margin-top: 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../../assets/css/global.css';
</style>
