var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.language === 'es')?_c('div',[_vm._v(" He visto que quieres "),_c('strong',[_vm._v("enviar campañas de correo")]),_vm._v(" ;)"),_c('br'),_vm._v(" ¿Sabes que con uProc puedes "),_c('strong',[_vm._v("evitar los rebotes")]),_vm._v(" si "),_c('strong',[_vm._v("verificas tus correos")]),_vm._v(" previamente? "),_c('br'),_c('br'),_vm._v(" Consíguelo en tres sencillos pasos: "),_vm._m(0),_vm._v(" También puedes comprobar tus correos desde "),_vm._m(1),_vm._v(", "),_vm._m(2),_vm._v(", "),_vm._m(3),_vm._v(", "),_vm._m(4),_vm._v(" o "),_vm._m(5),_vm._v(". "),_c('br'),_vm._v(" Elije el método que mejor se adapta a tus necesidades. ")]):(_vm.language === 'fr')?_c('div',{staticClass:"row"},[_vm._m(6)]):_c('div',[_vm._v(" I see you want to "),_c('strong',[_vm._v("send email campaigns")]),_vm._v(" ;)"),_c('br'),_vm._v(" Did you know that with uProc you can "),_c('strong',[_vm._v("avoid bounces")]),_vm._v(" if you "),_c('strong',[_vm._v("verify your emails")]),_vm._v(" beforehand? "),_c('br'),_c('br'),_vm._v(" Achieve it in three simple steps: "),_vm._m(7),_vm._v(" You can also check your emails from "),_vm._m(8),_vm._v(", "),_vm._m(9),_vm._v(", "),_vm._m(10),_vm._v(", "),_vm._m(11),_vm._v(" or "),_vm._m(12),_vm._v(". "),_c('br'),_vm._v(" Choose the method that best suits your needs. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('strong',[_c('a',{attrs:{"href":"/#/wizard/file"}},[_vm._v("Sube")])]),_vm._v(" tu fichero de correos desde el asistente.")]),_c('li',[_c('strong',[_vm._v("Elije")]),_vm._v(" la herramienta "),_c('strong',[_vm._v("\"Comprobar si correo existe\"")]),_vm._v(" y procesa tu fichero.")]),_c('li',[_c('strong',[_vm._v("Recibe")]),_vm._v(" los resultados por correo.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/es/how-to-check-if-email-exists#sheets","target":"_blank"}},[_vm._v("Google Sheets")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/es/how-to-check-if-email-exists#n8n","target":"_blank"}},[_vm._v("n8n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/es/how-to-check-if-email-exists#make","target":"_blank"}},[_vm._v("Make")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/es/how-to-check-if-email-exists#zapier","target":"_blank"}},[_vm._v("Zapier")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/es/how-to-check-if-email-exists#api","target":"_blank"}},[_vm._v("API")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_vm._v(" J'ai vu que vous voulez "),_c('strong',[_vm._v("envoyer des campagnes d'email")]),_vm._v(" ;)"),_c('br'),_vm._v(" Savez-vous qu'avec uProc vous pouvez "),_c('strong',[_vm._v("éviter les rebonds")]),_vm._v(" si vous "),_c('strong',[_vm._v("vérifiez vos emails")]),_vm._v(" au préalable? "),_c('br'),_c('br'),_vm._v(" Obtenez-le en trois étapes simples: "),_c('ul',[_c('li',[_c('strong',[_c('a',{attrs:{"href":"/#/wizard/file"}},[_vm._v("Téléchargez")])]),_vm._v(" votre fichier d'emails depuis l'assistant.")]),_c('li',[_c('strong',[_vm._v("Choisissez")]),_vm._v(" l'outil "),_c('strong',[_vm._v("\"Vérifier si l'email existe\"")]),_vm._v(" et traitez votre fichier.")]),_c('li',[_c('strong',[_vm._v("Recevez")]),_vm._v(" les résultats par e-mail.")])]),_vm._v(" Vous pouvez également vérifier vos emails depuis "),_c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/fr/how-to-check-if-email-exists#sheets","target":"_blank"}},[_vm._v("Google Sheets")])]),_vm._v(", "),_c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/fr/how-to-check-if-email-exists#n8n","target":"_blank"}},[_vm._v("n8n")])]),_vm._v(", "),_c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/fr/how-to-check-if-email-exists#make","target":"_blank"}},[_vm._v("Make")])]),_vm._v(", "),_c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/fr/how-to-check-if-email-exists#zapier","target":"_blank"}},[_vm._v("Zapier")])]),_vm._v(" ou "),_c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/fr/how-to-check-if-email-exists#api","target":"_blank"}},[_vm._v("API")])]),_vm._v(". "),_c('br'),_vm._v("Choisissez la méthode qui convient le mieux à vos besoins. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('strong',[_c('a',{attrs:{"href":"/#/wizard/file"}},[_vm._v("Upload")])]),_vm._v(" your email file from the wizard.")]),_c('li',[_c('strong',[_vm._v("Select")]),_vm._v(" the tool "),_c('strong',[_vm._v("\"Check if email exists\"")]),_vm._v(" and process your file.")]),_c('li',[_c('strong',[_vm._v("Receive")]),_vm._v(" the results by email.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/en/how-to-check-if-email-exists#sheets","target":"_blank"}},[_vm._v("Google Sheets")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/en/how-to-check-if-email-exists#n8n","target":"_blank"}},[_vm._v("n8n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/en/how-to-check-if-email-exists#make","target":"_blank"}},[_vm._v("Make")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/en/how-to-check-if-email-exists#zapier","target":"_blank"}},[_vm._v("Zapier")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('a',{attrs:{"href":"https://uproc.io/blog/en/how-to-check-if-email-exists#api","target":"_blank"}},[_vm._v("API")])])
}]

export { render, staticRenderFns }