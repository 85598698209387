<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">
        <div class="total-wizard">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">{{$t('wizard-form-section')}}</a></li>
              <li class="breadcrumb-item" aria-current="page">{{$t('wizard-list')}}</li>
            </ol>
          </nav>

          <div>
            <div class="row no_side_margin">
              <h2>{{$t('wizard-list-contacts')}}</h2>
              <div class="sub-text col-lg-12"><span v-html="$t('wizard-list1')"></span><a href="javascript: void()" v-on:click="showPeopleFields()"><span
                    v-if="shownPeopleFields">{{$t('wizard-list-hide')}}</span><span v-if="!shownPeopleFields">{{$t('wizard-list-show')}}</span></a>{{$t('wizard-list2')}}
              </div>
              <div v-if="shownPeopleFields" class="text-center">
                <ListTypePerson/>
              </div>
              <div class="sub-text col-lg-12">{{$t('wizard-list-country')}}</div>
              <div class="col-lg-12">
                <div class="total-contact">
                  <div class="row">
                    <div class="col-lg-3 p-2">
                      <div class="col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.country" v-on:change="selectCountry(search.country)" key="country">
                          <option value="Any" selected>{{$t('country')}}</option>
                          <option v-for='country in countries' v-bind:value='country.name' v-bind:key='country.name'>{{country.name}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                        <div class="btn-group ">
                          <input type="text" v-model="search.company" :placeholder="$t('company')" v-on:keyup="enableSearch()" v-on:keyup.enter="prevUpdateListData()" v-on:change="enableSearch()" v-on:blur="enableSearch()" v-on:click="$event.target.select()" class="form-control">
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.size" v-on:change="selectSize(search.size)">
                          <option value="Any" selected>{{$t('size')}}</option>
                          <option value="1-10">1-10</option>
                          <option value="11-50">11-50</option>
                          <option value="51-200">51-200</option>
                          <option value="201-500">201-500</option>
                          <option value="501-1000">501-1000</option>
                          <option value="1001-5000">1001-5000</option>
                          <option value="5001-10000">5001-10000</option>
                          <option value="10001+">10001+</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.industry" v-on:change="selectIndustry(search.industry)">
                          <option value="Any" selected>{{$t('industry')}}</option>
                          <option value="accounting">accounting</option>
                          <option value="airlines/aviation">airlines/aviation</option>
                          <option value="alternative dispute resolution">alternative dispute resolution</option>
                          <option value="alternative medicine">alternative medicine</option>
                          <option value="animation">animation</option>
                          <option value="apparel & fashion">apparel & fashion</option>
                          <option value="architecture & planning">architecture & planning</option>
                          <option value="arts and crafts">arts and crafts</option>
                          <option value="automotive">automotive</option>
                          <option value="aviation & aerospace">aviation & aerospace</option>
                          <option value="banking">banking</option>
                          <option value="biotechnology">biotechnology</option>
                          <option value="broadcast media">broadcast media</option>
                          <option value="building materials">building materials</option>
                          <option value="business supplies and equipment">business supplies and equipment</option>
                          <option value="capital markets">capital markets</option>
                          <option value="chemicals">chemicals</option>
                          <option value="civic & social organization">civic & social organization</option>
                          <option value="civil engineering">civil engineering</option>
                          <option value="commercial real estate">commercial real estate</option>
                          <option value="computer & network security">computer & network security</option>
                          <option value="computer games">computer games</option>
                          <option value="computer hardware">computer hardware</option>
                          <option value="computer networking">computer networking</option>
                          <option value="computer software">computer software</option>
                          <option value="construction">construction</option>
                          <option value="consumer electronics">consumer electronics</option>
                          <option value="consumer goods">consumer goods</option>
                          <option value="consumer services">consumer services</option>
                          <option value="cosmetics">cosmetics</option>
                          <option value="dairy">dairy</option>
                          <option value="defense & space">defense & space</option>
                          <option value="design">design</option>
                          <option value="e-learning">e-learning</option>
                          <option value="education management">education management</option>
                          <option value="electrical/electronic manufacturing">electrical/electronic manufacturing</option>
                          <option value="entertainment">entertainment</option>
                          <option value="environmental services">environmental services</option>
                          <option value="events services">events services</option>
                          <option value="executive office">executive office</option>
                          <option value="facilities services">facilities services</option>
                          <option value="farming">farming</option>
                          <option value="financial services">financial services</option>
                          <option value="fine art">fine art</option>
                          <option value="fishery">fishery</option>
                          <option value="food & beverages">food & beverages</option>
                          <option value="food production">food production</option>
                          <option value="fund-raising">fund-raising</option>
                          <option value="furniture">furniture</option>
                          <option value="gambling & casinos">gambling & casinos</option>
                          <option value="glass, ceramics & concrete">glass, ceramics & concrete</option>
                          <option value="government administration">government administration</option>
                          <option value="government relations">government relations</option>
                          <option value="graphic design">graphic design</option>
                          <option value="health, wellness and fitness">health, wellness and fitness</option>
                          <option value="higher education">higher education</option>
                          <option value="hospital & health care">hospital & health care</option>
                          <option value="hospitality">hospitality</option>
                          <option value="human resources">human resources</option>
                          <option value="import and export">import and export</option>
                          <option value="individual & family services">individual & family services</option>
                          <option value="industrial automation">industrial automation</option>
                          <option value="information services">information services</option>
                          <option value="information technology and services">information technology and services</option>
                          <option value="insurance">insurance</option>
                          <option value="international affairs">international affairs</option>
                          <option value="international trade and development">international trade and development</option>
                          <option value="internet">internet</option>
                          <option value="investment banking">investment banking</option>
                          <option value="investment management">investment management</option>
                          <option value="judiciary">judiciary</option>
                          <option value="law enforcement">law enforcement</option>
                          <option value="law practice">law practice</option>
                          <option value="legal services">legal services</option>
                          <option value="legislative office">legislative office</option>
                          <option value="leisure, travel & tourism">leisure, travel & tourism</option>
                          <option value="libraries">libraries</option>
                          <option value="logistics and supply chain">logistics and supply chain</option>
                          <option value="luxury goods & jewelry">luxury goods & jewelry</option>
                          <option value="machinery">machinery</option>
                          <option value="management consulting">management consulting</option>
                          <option value="maritime">maritime</option>
                          <option value="market research">market research</option>
                          <option value="marketing and advertising">marketing and advertising</option>
                          <option value="mechanical or industrial engineering">mechanical or industrial engineering</option>
                          <option value="media production">media production</option>
                          <option value="medical devices">medical devices</option>
                          <option value="medical practice">medical practice</option>
                          <option value="mental health care">mental health care</option>
                          <option value="military">military</option>
                          <option value="mining & metals">mining & metals</option>
                          <option value="motion pictures and film">motion pictures and film</option>
                          <option value="museums and institutions">museums and institutions</option>
                          <option value="music">music</option>
                          <option value="nanotechnology">nanotechnology</option>
                          <option value="newspapers">newspapers</option>
                          <option value="non-profit organization management">non-profit organization management</option>
                          <option value="nonprofit organization management">nonprofit organization management</option>
                          <option value="oil & energy">oil & energy</option>
                          <option value="online media">online media</option>
                          <option value="outsourcing/offshoring">outsourcing/offshoring</option>
                          <option value="package/freight delivery">package/freight delivery</option>
                          <option value="packaging and containers">packaging and containers</option>
                          <option value="paper & forest products">paper & forest products</option>
                          <option value="performing arts">performing arts</option>
                          <option value="pharmaceuticals">pharmaceuticals</option>
                          <option value="philanthropy">philanthropy</option>
                          <option value="photography">photography</option>
                          <option value="plastics">plastics</option>
                          <option value="political organization">political organization</option>
                          <option value="primary/secondary education">primary/secondary education</option>
                          <option value="printing">printing</option>
                          <option value="professional training & coaching">professional training & coaching</option>
                          <option value="program development">program development</option>
                          <option value="public policy">public policy</option>
                          <option value="public relations and communications">public relations and communications</option>
                          <option value="public safety">public safety</option>
                          <option value="publishing">publishing</option>
                          <option value="railroad manufacture">railroad manufacture</option>
                          <option value="ranching">ranching</option>
                          <option value="real estate">real estate</option>
                          <option value="recreational facilities and services">recreational facilities and services</option>
                          <option value="religious institutions">religious institutions</option>
                          <option value="renewables & environment">renewables & environment</option>
                          <option value="research">research</option>
                          <option value="restaurants">restaurants</option>
                          <option value="retail">retail</option>
                          <option value="security and investigations">security and investigations</option>
                          <option value="semiconductors">semiconductors</option>
                          <option value="shipbuilding">shipbuilding</option>
                          <option value="sporting goods">sporting goods</option>
                          <option value="sports">sports</option>
                          <option value="staffing and recruiting">staffing and recruiting</option>
                          <option value="supermarkets">supermarkets</option>
                          <option value="telecommunications">telecommunications</option>
                          <option value="textiles">textiles</option>
                          <option value="think tanks">think tanks</option>
                          <option value="tobacco">tobacco</option>
                          <option value="translation and localization">translation and localization</option>
                          <option value="transportation/trucking/railroad">transportation/trucking/railroad</option>
                          <option value="utilities">utilities</option>
                          <option value="venture capital & private equity">venture capital & private equity</option>
                          <option value="veterinary">veterinary</option>
                          <option value="warehousing">warehousing</option>
                          <option value="wholesale">wholesale</option>
                          <option value="wine and spirits">wine and spirits</option>
                          <option value="wireless">wireless</option>
                          <option value="writing and editing">writing and editing</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class="col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.area" v-on:change="selectArea(search.area)">
                          <option value="Any" selected>{{$t('area')}}</option>
                          <option value="Ceo">Ceo</option>
                          <option value="Communications">Communications</option>
                          <option value="Consulting">Consulting</option>
                          <option value="Customer Service">Customer Service</option>
                          <option value="Education">Education</option>
                          <option value="Finance">Finance</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Health Professional">Health Professional</option>
                          <option value="Human Resources">Human Resources</option>
                          <option value="Information Technology">Information Technology</option>
                          <option value="Legal">Legal</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Operations">Operations</option>
                          <option value="Owner">Owner</option>
                          <option value="President">President</option>
                          <option value="Product">Product</option>
                          <option value="Public Relations">Public Relations</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Recruiting">Recruiting</option>
                          <option value="Research">Research</option>
                          <option value="Sales">Sales</option>
                          </select>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.seniority" v-on:change="selectSeniority(search.seniority)">
                          <option value="Any" selected>{{$t('seniority')}}</option>
                          <option value="Apprentice">Apprentice</option>
                          <option value="Director">Director</option>
                          <option value="Executive">Executive</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Manager">Manager</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.email" v-on:change="selectEmail(search.email)">
                          <option value="Any" selected>{{$t('email')}}</option>
                          <option value="Yes">{{$t('yes')}}</option>
                          <option value="Yes (verified)">{{$t('email-verified')}}</option>
                          <option value="Yes (risky)">{{$t('email-risky')}}</option>
                          <option value="No">{{$t('no')}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-3 p-2">
                      <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                        <select class="form-control" v-model="search.phone" v-on:change="selectPhone(search.phone)">
                          <option value="Any" selected>{{$t('phone')}}</option>
                          <option value="Yes">{{$t('yes')}}</option>
                          <option value="No">{{$t('no')}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-12 text-right">
                      <button type="button" :disabled="!this.search.enabled" class="btn btn-sm btn-primary" v-on:click="prevUpdateListData()">{{$t('search')}} </button>
                    </div>
                  </div>
                </div>



                
                <div v-if="showNotFound && !loading" class="row pt-4">
                  <div class="col-lg-12">
                    <div class="alert alert-warning text-center" role="alert">
                      <span>{{$t('no-results-found')}}</span>
                    </div>
                  </div>
                </div>

                <div v-if="this.list.elements && this.list.elements.length && !this.showResult && !this.loading">
                  <br>
                  <span v-html="$t('wizard-list-contacts-found', {count: this.list.totalFiltered, length: this.list.elements.length})"></span>
                  <br>
                  <span v-html="$t('wizard-list-contacts-pagination', {startItems: this.startItems + 1, finishItems: this.finishItems})"></span>
                </div>


                <div class="row" v-if="!showResult && !this.loading && this.list.elements && this.list.elements.length">
                  <div class="col-lg-6 col-lg-offset-3">
                    <button class="btn btn-sm btn-primary button-selected" v-if="this.list.selectedMode === 'page'" v-on:click="selectPage()"><font-awesome-icon icon="check"/> {{$t('deselect-page-records', {totalItems: this.list.elements.length})}}</button>
                    <button class="btn btn-sm btn-primary" v-if="this.list.selectedMode !== 'page'" v-on:click="selectPage()">{{$t('select-page-records', {totalItems: this.list.elements.length})}}</button>
                    &nbsp;
                    <button class="btn btn-sm btn-primary button-selected" v-if="this.list.selectedMode === 'all'" v-on:click="selectAll()"><font-awesome-icon icon="check"/> {{$t('deselect-all-records', {totalItems: this.list.totalFiltered})}}</button>
                    <button class="btn btn-sm btn-primary" v-if="this.list.selectedMode !== 'all'" v-on:click="selectAll()">{{$t('select-all-records', {totalItems: this.list.totalFiltered})}}</button>
                    <br/>
                    <br/>
                  </div>
                </div>

                <div class="col-lg-12 no_side_pad" v-if="!showResult && this.list.elements && this.list.elements.length">
                  <br>
                  <div class="page-nav">
                    <div class="row no_side_margin">
                      <div class="col-lg-3 col-sm-4 col-4 no_side_pad">
                        <div class="nav-item"><a type="button" :class="{'disabled': isFirstPage() || this.loading, 'enabled': !isFirstPage() && this.loading}" v-on:click="moveBack()"><span class="nav-icon">←</span> {{ $t('previous' ) }}</a></div>
                      </div>
                      <div class="col-lg-6 col-sm-4 col-4 no_side_pad">
                        <div class="btn-group ">
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-4 text-right col-4 no_side_pad">
                        <div class="nav-item"><a type="button" :class="{'disabled': isLastPage() || this.loading, 'enabled': !isLastPage() && this.loading}" v-on:click="moveNext()">{{ $t('next' ) }} <span class="nav-icon">→</span></a></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="overflow-x: auto;  max-height: 500px;" v-if="!showResult && !this.loading && this.list.elements && this.list.elements.length">
                  <table class="table table-striped list-table pt-4" style="margin-top: 0px !important; margin-bottom: 0px !important; ">
                    <thead>
                      <tr>
                        <th class="t-head">
                          <!--label class="control control-checkbox control-absolute">
                            <input :disabled="!this.list.elements || !this.list.elements.length" type="checkbox" v-model="checkboxPersons" v-on:change="selectAll()" />
                            <div class="control_indicator"></div>
                          </label-->
                        </th>
                        <th class="t-head">{{$t('name')}}</th>
                        <th class="t-head">{{$t('company')}}</th>
                        <th class="t-head">{{$t('role')}}</th>
                        <th class="t-head">{{$t('wizard-country')}}</th>
                        <th class="t-head text-center">{{$t('email')}}</th>
                        <th class="t-head text-center">{{$t('phone')}}</th>
                      </tr>
                    </thead>
                    <tbody v-if="!this.list.elements.length">
                      <tr>
                        <td colspan="7" class="text-center">{{$t('no-results')}}</td>
                      </tr>
                    </tbody>


                    <tbody v-if="this.list.elements.length">
                      <tr v-for="(profile, index) in this.list.elements" v-bind:key="profile.profile + index">
                        <td class="table-col">
                          <label class="control control-checkbox control-bottom-10">
                            <input type="checkbox" id="chk_" value="" :checked="profile.selected" v-on:click="rowClicked(profile)" />
                            <div class="control_indicator"></div>
                          </label>
                        </td>

                        <td class="table-col">{{profile.name}} {{profile.surname}}</td>

                        <td class="table-col">{{profile.company}}</td>

                        <td class="table-col">{{profile.role}}</td>

                        <td class="table-col">{{profile.country}}</td>

                        <td class="table-col text-center">


                          <!-- <font-awesome-icon
                            v-if="profile.email && profile.confidence === 'verified'"
                            icon="check"
                            size="lg"
                            :title="$t('email-yes-verified')"
                            class="text-success pointer"
                          />

                          <font-awesome-icon
                            v-if="profile.email && profile.confidence !== 'verified'"
                            icon="check"
                            :title="$t('email-yes-risky')"
                            size="lg"
                            class="text-warning pointer"
                          />

                          <font-awesome-icon
                            v-if="!profile.email"
                            icon="times"
                            class="text-danger pointer"
                            size="lg"
                            :title="$t('email-no')"
                          /> -->
                          <font-awesome-icon
                            v-if="profile.email && profile.confidence === 'verified'"
                            icon="check-circle"
                            :title="$t('email-yes-verified')"
                            class="text-success"
                          />

                          <font-awesome-icon
                            v-if="profile.email && profile.confidence !== 'verified'"
                            icon="check-circle"
                            :title="$t('email-yes-risky')"
                            class="text-warning"
                          />

                          <font-awesome-icon
                            v-if="!profile.email"
                            icon="times-circle"
                            class="text-danger"
                            :title="$t('email-no')"
                          />

                        </td>

                        <td class="table-col text-center">

                          <!-- <font-awesome-icon
                            v-if="profile.phone"
                            icon="check"
                            class="text-success pointer"
                            size="lg"
                            :title="$t('phone-yes')"
                          />

                          <font-awesome-icon
                            v-if="!profile.phone"
                            icon="times"
                            class="text-danger pointer"
                            size="lg"
                            :title="$t('phone-no')"
                          /> -->
                          <font-awesome-icon
                            v-if="profile.phone"
                            icon="check-circle"
                            class="text-success"
                            :title="$t('phone-yes')"
                          />

                          <font-awesome-icon
                            v-if="!profile.phone"
                            icon="times-circle"
                            class="text-danger"
                            :title="$t('phone-no')"
                          />
                        </td>

                      </tr>



                    </tbody>
                  </table>
                </div>


                <div class="cntct-bottom" v-if="this.list.selected.length">
                  <!-- <div class="row" v-if="areCreditsNeeded()">
                    <div class="col-lg-12 text-center">
                      <h6 v-html="$t('purchase-required-description')"></h6>
                      <div class="row ">
                        <div class="col-lg-12">
                          <h4 class="amount"><strong>{{ this.getMinimumRequired() | numFormat("0.00")  }}€</strong></h4>
                        </div>
                      </div>
                      <a class="btn btn-primary btn-lg btn-block" href="/#/settings/billing" target="_blank">{{ $t('buy' ) }} {{ getMinimumRequired() }}€</a>
                    </div>
                  </div> -->

                  <div class="row" v-if="!areCreditsNeeded() && !showResult">
                    <div class="col-lg-7 col-md-12 no_side_pad ctct-text" v-html="$t('wizard-list-contacts-add', {length: this.list.selectedAmount, cost: this.selectedCost})"></div>
                    <div class="col-lg-5 col-md-12">
                      <div class="row">
                      <div class="col-lg-12 col-md-12 no_side_pad select_period total-select cntct-select">
                        <div class="btn-group">
                        <select class="form-control" v-model="list.name">
                          <option value="" selected>{{$t('select-list')}}</option>
                          <option value="new-list">{{$t('lists-create-new')}}: {{ new_list_name }}</option>
                          <option v-for='item in userLists' v-bind:value='item.name' v-bind:key='item.name'>{{item.name}}</option>
                        </select>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 text-center" v-if="this.list.selectedAmount > 0">
                      <button type="button" :disabled="!this.list.name.length" class="btn btn-md btn-primary" v-on:click="addToList()">{{$t('save')}}</button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-lg-12 text-center">
                      <h6 v-html="$t('purchase-required-description')"></h6>
                      <a class="btn btn-primary btn-lg btn-block" href="/#/settings/billing" target="_blank">{{ $t('buy' ) }} {{ getMinimumRequired() }}€</a>
                    </div>
                  </div>

                  <div v-if="showResult && !loading && list && list.id && list.id.length">
                    <br>
                    <div v-html="$t('wizard-list-contacts-added', {id: list.id, name: list.name})"></div>
                  </div>
                  <div v-if="!showResult && !loading && list && list.id && !list.id.length">
                    <br>
                    <div v-html="$t('wizard-list-contacts-not-added')"></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import ListTypePerson from '@/components/Lists/ListTypePerson.vue'

import axios from 'axios';

export default {
  name: 'wizard_list',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      stepNumber: 1,
      selectedSource: '',
      selectedSecondarySource: '',
      selectedSecondarySourceDb: '',
      processing: {},
      startItems: '-',
      finishItems: '-',
      files: '',
      isError: false,
      isProcessing: false,
      option: '',
      selectedProcessors: [],
      groups: [],
      mode: 'fields',
      startRow: 1,
      totalRows: '',
      previewData: true,
      groupView: 'select-option',
      group: '',
      type: '',
      availableTypes: [],
      selectType: 'item-tools disabled',
      typeView: 'select-option',
      selectTool: 'item-tools disabled',
      selectColumn: 'item-tools brd-none disabled',
      processors: [],
      toolView: 'select-option',
      columnView: 'Select column',
      processorKey: '',
      processor: {},
      processorP: {
        current: {},
        defaultParams: {},
        relatedProcessors: []
      },
      user: null,
      totalCols: '',
      fileAssignedCols: [],
      addSelection: true,
      source: {},
      countries: [],
      d: '',
      errorMessage: '',
      search: {
        country: 'Any',
        company: '',
        area: 'Any',
        seniority: 'Any',
        email: 'Any',
        email_confidence: '',
        phone: 'Any',
        size: 'Any',
        industry: 'Any',
        enabled: false
      },
      showResult: false,
      showNotFound: false,
      loading: false,
      list: {
        type: 'people',
        name: "",
        selected: [],
        selectedMode: '',
        selectedAmount: 0,
        total: "",
        elements: [],
        currentPage: -1,
        totalPages: 0
      },
      lists: [],
      userLists: [],
      shownPeopleFields: false,

      checkboxPersons: false,
      selectedCost: 0,
      currentPage: 0,
      new_list_name: ''
    }
  },
  mounted() {
      this.detectPreselectedList();
      this.resetFilters();
      this.enableInterceptor();
      var that = this;
      this.getUserDetails(function() {
        that.searchCountries(function() {
          that.getLists();
        });
      });
  },
  components: {
    Header,
    Sidebar,
    Footer,
    ListTypePerson
  },
  methods: {
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    detectPreselectedList() {
      if (this.$route.params.name) {
        this.list.name = this.$route.params.name
      }
    },
    getMinimumRequired: function() {
      var amount = parseInt(Math.ceil((this.processing.processCredits - this.getCredits()) / 10000), 10);
      if (amount < 25) {
        amount = 25;
      }
      return amount;
    },

    selectSource: function(source) {

      this.selectedSource = source;
      this.selectedSecondarySource = '';
      this.stepNumber = 2;

    },

    getUserDetails: function(callback) {
      var that = this;
      var url = '/api/v2/profile';
      that.$root.$emit('isLoading', true);
      that.$http.get(url, that.getConfigAuth()).then((results) => {
        that.user = results.data;
        that.$root.$emit('isLoading', false);
        if (callback) {
          callback();
        }
      });
    },

    getCredits: function() {
      return this.user ? this.user.credits : 0;
    },

    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      //console.log(this.processing.processCredits, this.getCredits(), this.user.autocredits);
      //return this.processing.processCredits > this.getCredits() && !this.user.autocredits;
      return this.processing.processCredits > this.getCredits()
    },

    //FROM HERE LIST AREA FUNCTIONS
    selectCountry: function(selectedCountry) {
      this.search.country = selectedCountry;
      this.enableSearch();
    },

    changeCompany: function() {


    },

    selectArea: function(selectedArea) {

      this.search.area = selectedArea;
      this.enableSearch();
    },

    selectSeniority: function(selectedSeniority) {

      this.search.seniority = selectedSeniority;
      this.enableSearch();
    },

    selectPhone: function(selectedPhone) {

      this.search.phone = selectedPhone;
      this.enableSearch();
    },

    selectSize: function(selectedSize) {
      this.search.size = selectedSize;
      this.enableSearch();
    },

    selectIndustry: function(selectedIndustry) {
      this.search.industry = selectedIndustry;
      this.enableSearch();
    },

    showPeopleFields() {
      this.shownPeopleFields = !this.shownPeopleFields
    },

    selectEmail: function(selectedEmail) {

      this.search.email = selectedEmail;
      this.enableSearch();

    },

    resetFilters: function() {

      this.list.elements = [];
      this.list.selected = [];


      this.search = {
        country: 'Any',//this.$t('country'),
        company: '',
        size: 'Any',//this.$t('size'),
        industry: 'Any',//this.$t('industry'),
        area: 'Any',//this.$t('area'),
        seniority: 'Any',//this.$t('seniority'),
        email: 'Any',//this.$t('email'),
        phone: 'Any',//this.$t('phone')
      };

    },

    cbSuccessPeopleCallback: function(data) {
      this.list.elements = data.items;
      this.startItems = (data.currentPage * data.pageSize)
      this.finishItems = this.startItems + this.list.elements.length;
      this.list.currentPage = data.currentPage;
      this.list.totalPages = data.totalPages;
      this.list.totalFiltered = data.totalFiltered;
      this.total = data.total;
      this.list.selected = [];
      this.list.total = data.total;
      this.loading = false;
      this.checkboxPersons = false;

      this.showNotFound = this.list.elements.length == 0;
    },

    errorPeopleCallback: function() {
      this.loading = false;
    },

    removeDiacritics: function(str) {
      var defaultDiacriticsRemovalMap = [
        {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
        {'base':'AA','letters':/[\uA732]/g},
        {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
        {'base':'AO','letters':/[\uA734]/g},
        {'base':'AU','letters':/[\uA736]/g},
        {'base':'AV','letters':/[\uA738\uA73A]/g},
        {'base':'AY','letters':/[\uA73C]/g},
        {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
        {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
        {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
        {'base':'DZ','letters':/[\u01F1\u01C4]/g},
        {'base':'Dz','letters':/[\u01F2\u01C5]/g},
        {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
        {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
        {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
        {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
        {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
        {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
        {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
        {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
        {'base':'LJ','letters':/[\u01C7]/g},
        {'base':'Lj','letters':/[\u01C8]/g},
        {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
        {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
        {'base':'NJ','letters':/[\u01CA]/g},
        {'base':'Nj','letters':/[\u01CB]/g},
        {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
        {'base':'OI','letters':/[\u01A2]/g},
        {'base':'OO','letters':/[\uA74E]/g},
        {'base':'OU','letters':/[\u0222]/g},
        {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
        {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
        {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
        {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
        {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
        {'base':'TZ','letters':/[\uA728]/g},
        {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
        {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
        {'base':'VY','letters':/[\uA760]/g},
        {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
        {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
        {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
        {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
        {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
        {'base':'aa','letters':/[\uA733]/g},
        {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
        {'base':'ao','letters':/[\uA735]/g},
        {'base':'au','letters':/[\uA737]/g},
        {'base':'av','letters':/[\uA739\uA73B]/g},
        {'base':'ay','letters':/[\uA73D]/g},
        {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
        {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
        {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
        {'base':'dz','letters':/[\u01F3\u01C6]/g},
        {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
        {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
        {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
        {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
        {'base':'hv','letters':/[\u0195]/g},
        {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
        {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
        {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
        {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
        {'base':'lj','letters':/[\u01C9]/g},
        {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
        {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
        {'base':'nj','letters':/[\u01CC]/g},
        {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
        {'base':'oi','letters':/[\u01A3]/g},
        {'base':'ou','letters':/[\u0223]/g},
        {'base':'oo','letters':/[\uA74F]/g},
        {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
        {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
        {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
        {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
        {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
        {'base':'tz','letters':/[\uA729]/g},
        {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
        {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
        {'base':'vy','letters':/[\uA761]/g},
        {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
        {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
        {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
        {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
      ];

      if (str) {
        for(var i=0; i<defaultDiacriticsRemovalMap.length; i++) {
          str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
        }
      }

      return str;
    },

    cleanSearch: function(search) {
      var that = this;
      Object.keys(search).forEach(function(k) {
        if (typeof search[k] === 'string') {
          search[k] = search[k].trim();
          search[k] = that.removeDiacritics(search[k]);
        }
      });
      return search;
    },

    enableSearch: function() {
      var isValidSearch = function(search, fn) {
        var isValid = false;
        if (
            (fn(search['country']) != 'Pais' && search['country'] != 'Country' && search['country'] != 'Any') ||
            (search['company'].length > 0) ||
            (search['size'] != 'Size' && search['size'] != 'Tamaño' && search['size'] != 'Any') ||
            (search['industry'] != 'Industry' && search['industry'] != 'Industria' && search['industry'] != 'Any') ||
            (search['area'] != 'Área' && search['area'] != 'Area' && search['area'] != 'Any') ||
            (search['seniority'] != 'Experiencia' && search['seniority'] != 'Seniority' && search['seniority'] != 'Any') ||
            (search['email'] != 'Correo' && search['email'] != 'Email' && search['email'] != 'Any') ||
            (fn(search['phone']) != 'Teléfono' && search['phone'] != 'Phone' && search['phone'] != 'Any')
          )
        {
              isValid = true;
        }
        return isValid;
      }
      this.search.enabled = false;
      this.search.enabled = isValidSearch(this.search, this.removeDiacritics);
      console.log(this.search.enabled)
    },

    prevUpdateListData: function() {
      this.list.currentPage = -1;
      this.list.selectedMode = '';
      this.list.selectedAmount = 0;
      this.generateNewListName();
      this.updateListData();
    },

    generateNewListName: function() {
      let name = 'List';
      if (this.search.country !== 'Any') name += `_${this.search.country}`;
      if (this.search.company) name += `_${this.search.company}`;
      if (this.search.size !== 'Any') name += `_${this.search.size}`;
      if (this.search.industry !== 'Any') name += `_${this.search.industry}`;
      if (this.search.area !== 'Any') name += `_${this.search.area}`;
      if (this.search.seniority !== 'Any') name += `_${this.search.seniority}`;
      if (this.search.email !== 'Any') name += `_email_${this.search.email}`;
      if (this.search.phone !== 'Any') name += `_phone_${this.search.phone}`;

      //add yyyymmdd
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = ("0" + date.getDate()).slice(-2);
      name += `_${year}${month}${day}`;
      this.new_list_name = name.toLowerCase().replace(/ /g, "_");
    },

    getCleanedSearch: function() {
      var searches = {};
      this.search = this.cleanSearch(this.search);

      if (this.search['country'] && this.removeDiacritics(this.search['country']) != 'Pais' && this.search['country'] != 'Country' && this.search['country'] != 'Any') {
        searches["country"] = this.search['country'];
      }

      if (this.search['company'] && this.search['company'] != '') {
        searches["company"] = this.search['company'];
      }

      if (this.search['area'] && this.search['area'] !== 'Area' && this.search['area'] != 'Área' && this.search['area'] != 'Any') {
        searches["area"] = this.search['area'].toLowerCase().replace(/ /g, "_");
      }

      if (this.search['seniority'] && this.search['seniority'] != 'Seniority' && this.search['seniority'] != 'Experiencia' && this.search['seniority'] != 'Any') {
        searches["seniority"] = this.search['seniority'].toLowerCase().replace(/ /g, "_");
      }

      if (this.search['size'] && this.search['size'] != 'Size' && this.search['size'] != 'Tamaño' && this.search['size'] != 'Any') {
        searches["size"] = this.search['size'].toLowerCase().replace(/ /g, "");
      }

      if (this.search['industry'] && this.search['industry'] != 'Industry' && this.search['industry'] != 'Industria' && this.search['industry'] != 'Any') {
        searches["industry"] = this.search['industry'].toLowerCase();
      }

      if (this.search['email'] && this.search['email'] != 'Email' && this.search['email'] != 'Correo' && this.search['email'] != 'Any') {
        var parts = this.search['email'].toLowerCase().replace(/\(/g, "").replace(/\)/g, "").replace(" ", "-").split("-");
        searches["email"] = parts[0];
        if (parts.length > 1) {
          searches["email_confidence"] = parts[1];
        }
      }

      if (this.search['phone'] && this.search['phone'] != 'Phone' && this.removeDiacritics(this.search['phone']) != 'Telefono' && this.search['phone'] != 'Any') {
        searches["phone"] = this.search['phone'].toLowerCase().replace(/ /g, "_");
      }

      return searches;
    },

    updateListData: function() {
      if(this.list.currentPage == -1)
      {
      this.list.currentPage = 0;
      }
      this.showResult = false;
      this.showNotFound = false;
      this.loading = true;

      var searches = this.getCleanedSearch();
      this.list.selected = [];

      var successCallback = this.cbSuccessPeopleCallback;
      var errorCallback = this.errorPeopleCallback;
      this.ListBusinessPeople(searches, successCallback, errorCallback);
    },

    isFirstPage: function() {
      return this.list.currentPage <= 0;
    },

    moveBack: function() {
      if(!this.isFirstPage())
      {
        this.moveBackProcess();
        this.updateListData();
      }
    },

    moveBackProcess: function() {
      this.list.currentPage--;
    },

    isLastPage: function() {
      //console.log(this.list.currentPage, this.list.totalPages - 1)
      return this.list.currentPage == this.list.totalPages - 1;
    },

    moveNext: function() {
      if(!this.isLastPage())
      {
        this.moveNextProcess();
        this.updateListData();
      }
    },

    moveNextProcess: function() {
      this.list.currentPage++;
    },

    getConfigAuth: function() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },

    searchCountries: function(callback) {
      var that = this;
      this.$root.$emit('isLoading', true);
      var url = '/api/v2/people_countries';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.$root.$emit('isLoading', false);
        that.countries = results.data;

        if (callback) {
          callback();
        }
      }, function() {
        this.$root.$emit('isLoading', false);
        that.countries = [
        {name: 'Afghanistan'},
        {name: 'Albania'},
        {name: 'Algeria'},
        {name: 'American Samoa'},
        {name: 'Andorra'},
        {name: 'Angola'},
        {name: 'Anguilla'},
        {name: 'Antarctica'},
        {name: 'Antigua And Barbuda'},
        {name: 'Argentina'},
        {name: 'Armenia'},
        {name: 'Aruba'},
        {name: 'Australia'},
        {name: 'Austria'},
        {name: 'Azerbaijan'},
        {name: 'Bahamas'},
        {name: 'Bahrain'},
        {name: 'Bangladesh'},
        {name: 'Barbados'},
        {name: 'Belarus'},
        {name: 'Belgium'},
        {name: 'Belize'},
        {name: 'Benin'},
        {name: 'Bermuda'},
        {name: 'Bhutan'},
        {name: 'Bolivia'},
        {name: 'Bosnia And Herzegovina'},
        {name: 'Botswana'},
        {name: 'Bouvet Island'},
        {name: 'Brazil'},
        {name: 'British Indian Ocean Territory'},
        {name: 'Brunei Darussalam'},
        {name: 'Bulgaria'},
        {name: 'Burkina Faso'},
        {name: 'Burundi'},
        {name: 'Cambodia'},
        {name: 'Cameroon'},
        {name: 'Canada'},
        {name: 'Cape Verde'},
        {name: 'Cayman Islands'},
        {name: 'Central African Republic'},
        {name: 'Chad'},
        {name: 'Chile'},
        {name: 'China'},
        {name: 'Christmas Island'},
        {name: 'Cocos (keeling) Islands'},
        {name: 'Colombia'},
        {name: 'Comoros'},
        {name: 'Congo'},
        {name: 'Congo, The Democratic Republic Of The'},
        {name: 'Cook Islands'},
        {name: 'Costa Rica'},
        {name: 'Cote D\'ivoire'},
        {name: 'Croatia'},
        {name: 'Cuba'},
        {name: 'Cyprus'},
        {name: 'Czech Republic'},
        {name: 'Denmark'},
        {name: 'Djibouti'},
        {name: 'Dominica'},
        {name: 'Dominican Republic'},
        {name: 'East Timor'},
        {name: 'Ecuador'},
        {name: 'Egypt'},
        {name: 'El Salvador'},
        {name: 'Equatorial Guinea'},
        {name: 'Eritrea'},
        {name: 'Estonia'},
        {name: 'Ethiopia'},
        {name: 'Falkland Islands (malvinas)'},
        {name: 'Faroe Islands'},
        {name: 'Fiji'},
        {name: 'Finland'},
        {name: 'France'},
        {name: 'French Guiana'},
        {name: 'French Polynesia'},
        {name: 'French Southern Territories'},
        {name: 'Gabon'},
        {name: 'Gambia'},
        {name: 'Georgia'},
        {name: 'Germany'},
        {name: 'Ghana'},
        {name: 'Gibraltar'},
        {name: 'Greece'},
        {name: 'Greenland'},
        {name: 'Grenada'},
        {name: 'Guadeloupe'},
        {name: 'Guam'},
        {name: 'Guatemala'},
        {name: 'Guinea'},
        {name: 'Guinea-bissau'},
        {name: 'Guyana'},
        {name: 'Haiti'},
        {name: 'Heard Island And Mcdonald Islands'},
        {name: 'Holy See (vatican City State)'},
        {name: 'Honduras'},
        {name: 'Hong Kong'},
        {name: 'Hungary'},
        {name: 'Iceland'},
        {name: 'India'},
        {name: 'Indonesia'},
        {name: 'Iran, Islamic Republic Of'},
        {name: 'Iraq'},
        {name: 'Ireland'},
        {name: 'Israel'},
        {name: 'Italy'},
        {name: 'Jamaica'},
        {name: 'Japan'},
        {name: 'Jordan'},
        {name: 'Kazakstan'},
        {name: 'Kenya'},
        {name: 'Kiribati'},
        {name: 'Korea, Democratic People\'s Republic Of'},
        {name: 'Korea, Republic Of'},
        {name: 'Kosovo'},
        {name: 'Kuwait'},
        {name: 'Kyrgyzstan'},
        {name: 'Lao People\'s Democratic Republic'},
        {name: 'Latvia'},
        {name: 'Lebanon'},
        {name: 'Lesotho'},
        {name: 'Liberia'},
        {name: 'Libyan Arab Jamahiriya'},
        {name: 'Liechtenstein'},
        {name: 'Lithuania'},
        {name: 'Luxembourg'},
        {name: 'Macau'},
        {name: 'Macedonia, The Former Yugoslav Republic Of'},
        {name: 'Madagascar'},
        {name: 'Malawi'},
        {name: 'Malaysia'},
        {name: 'Maldives'},
        {name: 'Mali'},
        {name: 'Malta'},
        {name: 'Marshall Islands'},
        {name: 'Martinique'},
        {name: 'Mauritania'},
        {name: 'Mauritius'},
        {name: 'Mayotte'},
        {name: 'Mexico'},
        {name: 'Micronesia, Federated States Of'},
        {name: 'Moldova, Republic Of'},
        {name: 'Monaco'},
        {name: 'Mongolia'},
        {name: 'Montserrat'},
        {name: 'Montenegro'},
        {name: 'Morocco'},
        {name: 'Mozambique'},
        {name: 'Myanmar'},
        {name: 'Namibia'},
        {name: 'Nauru'},
        {name: 'Nepal'},
        {name: 'Netherlands'},
        {name: 'Netherlands Antilles'},
        {name: 'New Caledonia'},
        {name: 'New Zealand'},
        {name: 'Nicaragua'},
        {name: 'Niger'},
        {name: 'Nigeria'},
        {name: 'Niue'},
        {name: 'Norfolk Island'},
        {name: 'Northern Mariana Islands'},
        {name: 'Norway'},
        {name: 'Oman'},
        {name: 'Pakistan'},
        {name: 'Palau'},
        {name: 'Palestinian Territory, Occupied'},
        {name: 'Panama'},
        {name: 'Papua New Guinea'},
        {name: 'Paraguay'},
        {name: 'Peru'},
        {name: 'Philippines'},
        {name: 'Pitcairn'},
        {name: 'Poland'},
        {name: 'Portugal'},
        {name: 'Puerto Rico'},
        {name: 'Qatar'},
        {name: 'Reunion'},
        {name: 'Romania'},
        {name: 'Russia'},
        {name: 'Rwanda'},
        {name: 'Saint Helena'},
        {name: 'Saint Kitts And Nevis'},
        {name: 'Saint Lucia'},
        {name: 'Saint Pierre And Miquelon'},
        {name: 'Saint Vincent And The Grenadines'},
        {name: 'Samoa'},
        {name: 'San Marino'},
        {name: 'Sao Tome And Principe'},
        {name: 'Saudi Arabia'},
        {name: 'Senegal'},
        {name: 'Serbia'},
        {name: 'Seychelles'},
        {name: 'Sierra Leone'},
        {name: 'Singapore'},
        {name: 'Slovakia'},
        {name: 'Slovenia'},
        {name: 'Solomon Islands'},
        {name: 'Somalia'},
        {name: 'South Africa'},
        {name: 'South Georgia And The South Sandwich Islands'},
        {name: 'Spain'},
        {name: 'Sri Lanka'},
        {name: 'Sudan'},
        {name: 'Suriname'},
        {name: 'Svalbard And Jan Mayen'},
        {name: 'Swaziland'},
        {name: 'Sweden'},
        {name: 'Switzerland'},
        {name: 'Syrian Arab Republic'},
        {name: 'Taiwan, Province Of China'},
        {name: 'Tajikistan'},
        {name: 'Tanzania, United Republic Of'},
        {name: 'Thailand'},
        {name: 'Togo'},
        {name: 'Tokelau'},
        {name: 'Tonga'},
        {name: 'Trinidad And Tobago'},
        {name: 'Tunisia'},
        {name: 'Turkey'},
        {name: 'Turkmenistan'},
        {name: 'Turks And Caicos Islands'},
        {name: 'Tuvalu'},
        {name: 'Uganda'},
        {name: 'Ukraine'},
        {name: 'United Arab Emirates'},
        {name: 'United Kingdom'},
        {name: 'United States'},
        {name: 'United States Minor Outlying Islands'},
        {name: 'Uruguay'},
        {name: 'Uzbekistan'},
        {name: 'Vanuatu'},
        {name: 'Venezuela'},
        {name: 'Viet Nam'},
        {name: 'Virgin Islands, British'},
        {name: 'Virgin Islands, U.s.'},
        {name: 'Wallis And Futuna'},
        {name: 'Western Sahara'},
        {name: 'Yemen'},
        {name: 'Zambia'},
        {name: 'Zimbabwe'}
        ]

        if (callback) {
          callback();
        }
      });
    },

    ListBusinessPeople: function(params) {
      if (params && Object.keys(params).length) {
        var that = this;

        var parametersStr = Object.keys(params).map(function(key) {
          return key + "=" + params[key];
        }).join("&");

        var url = '/api/v2/people?' + parametersStr + "&page=" + this.list.currentPage;
        this.$http.get(url, this.getConfigAuth()).then((results) => {
          that.cbSuccessPeopleCallback(results.data);
        });
      }
    },

    rowClicked: function(obj) {
      obj.selected = !obj.selected;
      this.updateSelectedListElements();
    },

    updateSelectedListElements: function() {
      this.list.selected = this.list.elements.filter(function(item) {
        return item.selected;
      });

      this.list.selectedAmount = this.list.selectedMode === "all" ? this.list.totalFiltered : this.list.selected.length;
      this.processing.processCredits = 0.025 * this.list.selectedAmount;
      this.selectedCost = parseInt(this.processing.processCredits * 100, 10) / 100;
    },

    toggleObjSelection: function($event) {
      $event.stopPropagation();
      this.updateSelectedListElements();
    },

    getLists: function() {
      var that = this
      var url = '/api/v2/lists';
      this.startItems = this.list.pageSize * this.list.currentPage;
      this.finishItems = this.startItems + this.list.elements.length;

      this.$root.$emit('isLoading', true);
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        that.userLists = results.data;
        that.initLists();
        this.$root.$emit('isLoading', false);
      });
    },

    initLists: function() {
      var that = this;
      this.lists = [this.list.name];
      if (this.userLists && this.userLists.length) {
        this.userLists.filter(function(l) {
          if (l.name !== 'default' && l.type === 'people') {
            that.lists.push(l.name);
          }
        });
      }
    },

    changeListName: function(name) {
      this.list.name = name;
      //console.log(this.list.name)
    },

    addToList: function() {
      if (this.list.name === 'new-list') {
        this.list.name = this.new_list_name;
      }
      this.loading = true;
      var elements = this.list.selected.map(function(i) {
        return i.id;
      });
      var successCallback = this.successSaveListCallback;
      var errorCallback = this.errorSaveListCallback;
      this.addElements(this.list.name, elements, successCallback, errorCallback);
    },

    successSaveListCallback: function(data) {
      //this.resetFilters();
      this.showResult = true;
      this.list.id = data.id;
      //this.list.name = data.name;
      this.loading = false;
    },

    errorSaveListCallback: function() {
      this.loading = false;
    },

    addElements: function(name, ids) {
      var that = this;
      var url = '/api/v2/list/' + encodeURIComponent(name) + '/add_background/wizard';
      this.$http.post(url, {
        mode: that.list.selectedMode,
        search: that.getCleanedSearch(),
        elements: ids
      }, this.getConfigAuth()).then((results) => {
        that.successSaveListCallback(results.data);
      });
    },

    selectPage: function() {
      var checked = this.list.selectedMode !== 'page';
      this.list.selectedMode = this.list.selectedMode === 'page' ? '' : 'page';
      this.list.elements.forEach(function(item) {
        item.selected = checked;
      });
      this.updateSelectedListElements();

    },

    selectAll: function() {
      var checked = this.list.selectedMode !== 'all';
      this.list.selectedMode = this.list.selectedMode === 'all' ? '' : 'all';
      this.list.elements.forEach(function(item) {
        item.selected = checked;
      });
      this.updateSelectedListElements();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../../assets/css/wizards.css';
@import '../../../assets/css/global.css';
</style>
