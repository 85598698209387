<template>
  <div class="tab-content">
    <div id="home" class="tab-pane container active">

      <div class="row smry-status no_side_margin" style="overflow-x: auto;">
        <table class="table table-striped list-table" style="margin-top: 0px">
          <thead>
            <tr>
              <th class="t-head">{{ $t("date") }}</th>
              <th class="t-head">{{ $t("repeat-every") }}</th>
              <th class="t-head">{{ $t("tool") }}</th>
              <th class="t-head">{{ $t("params") }}</th>
              <th class="t-head">{{ $t("status") }}</th>
              <th class="t-head">{{ $t("executed-times") }}</th>
              <th class="t-head">{{ $t("last-execution") }}</th>
              <th class="t-head">{{ $t("next-execution") }}</th>
              <th class="t-head text-right"></th>
            </tr>
          </thead>

          <tbody v-if="!this.schedules.length">
            <tr>
              <td colspan="9" class="text-center table-col">
                {{ $t("no-results") }}
              </td>
            </tr>
          </tbody>

          <tbody v-if="this.schedules.length">
            <tr v-for="(schedule, index) in this.schedules" v-bind:key="index">
              <td>{{ schedule.createdAt }}</td>
              <td>{{ schedule.every }}</td>
              <td>{{ schedule.processor }}</td>
              <td>{{ schedule.params }}</td>
              <td>{{ schedule.active ? $t("active") : $t("inactive") }}</td>
              <td>{{ schedule.executedTimes }}</td>
              <td>{{ schedule.lastExecution }}</td>
              <td>{{ schedule.nextExecution }}</td>
              <td>
                <button
                  class="btn"
                  :class="schedule.active ? 'btn-success' : 'btn-secondary'"
                  @click="toggleSchedule(schedule)"
                >
                  {{ schedule.active ? $t("deactivate") : $t("activate") }}
                </button>
              </td>
              <td class="text-right">
                <button
                  class="btn btn-primary"
                  @click="editSchedule(schedule)"
                >
                  {{ $t("edit") }}
                </button>
                <button
                  class="btn btn-danger"
                  @click="deleteSchedule(schedule)"
                >
                  {{ $t("delete") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schedules",
  data() {
    return {
      orderByField: "startDate",
      pendingRequest: false,
      schedules: [],
    
      loading: false,
    };
  },
  mounted() {
    this.getSchedules(true);
  },
  components: {},
  methods: {
    getConfigAuth() {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    getSchedules: function(forced) {
      //alert(
      this.$root.$emit("isLoading", true);
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;
        var url = "/api/v2/schedules";

        this.$http.get(url, this.getConfigAuth()).then((results) => {
          this.$root.$emit("isLoading", false);
          var result = results.data;
          this.schedules = result.results;
          this.pendingRequest = false;
        });
      }
    },

    toggleSchedule: function(schedule) {
      var url = "/api/v2/schedules/" + schedule.id + "/toggle_status";
      this.$http.post(url, {}, this.getConfigAuth()).then((results) => {
        console.log(results);
        this.getSchedules(true);
      });
    },

    editSchedule: function(schedule) {
      console.log(schedule);
    },

    deleteSchedule: function(schedule) {
      var url = "/api/v2/schedules/" + schedule.id + "/delete";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        console.log(results);
        this.getSchedules(true);
      });
    },

    addSchedule: function(data) {
      var url = "/api/v2/schedules";
      this.$http.post(url, data, this.getConfigAuth()).then((results) => {
        console.log(results);
        this.getSchedules(true);
      });
    }
  },
};
</script>