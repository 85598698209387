import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import VueRouter from 'vue-router'
import VueDrift from 'vue-drift'
import VueAnalytics from 'vue-analytics'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import numFormat from 'vue-filter-number-format';
import moment from 'moment'
import VModal from 'vue-js-modal'
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import VueSimpleMarkdown from 'vue-simple-markdown'
import Toast from "vue-toastification";
import * as Sentry from "@sentry/vue";

import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import "vue-toastification/dist/index.css";

import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faKey,
  faUser,
  faUsers,
  faCog,
  faGift,
  faHandshake,
  faCode,
  faReceipt,
  faVolumeUp,
  faCubes,
  faFileInvoiceDollar,
  faHome,
  faShoppingCart,
  faMagic,
  faLightbulb,
  faList,
  faTasks,
  faChartLine,
  faChartPie,
  faTags,
  faFileCode,
  faBuilding,
  faComments,
  faMoneyCheckAlt,
  faMapMarkedAlt,
  faShieldAlt,
  faImages,
  faAlignJustify,
  faGlobe,
  faInfoCircle,
  faPaperPlane,
  faExpandArrowsAlt,
  faCheck,
  faEraser,
  faIdCard,
  faBook,
  faFileExcel,
  faFile,
  faDatabase,
  faCloud,
  faUpload,
  faServer,
  faHistory,
  faCloudDownloadAlt,
  faTrash,
  faDownload,
  faCreditCard,
  faMoneyCheck,
  faEdit,
  faTimes,
  faPhone,
  faBullseye,
  faInfo,
  faPlus,
  faEye,
  faEyeSlash,
  faExchangeAlt,
  faTable,
  faSignOutAlt,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faCheckDouble,
  faClock,
  faLock,
  faSignInAlt,
  faColumns,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'

import {
  faWpforms,
  faMailchimp,
  faPaypal,
  faTelegram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'


library.add(
  faCog,
  faEnvelope,
  faUser,
  faUsers,
  faGift,
  faHandshake,
  faCode,
  faReceipt,
  faVolumeUp,
  faWpforms,
  faCubes,
  faFileInvoiceDollar,
  faHome,
  faShoppingCart,
  faMagic,
  faLightbulb,
  faList,
  faTasks,
  faChartLine,
  faChartPie,
  faExchangeAlt,
  faTags,
  faFileCode,
  faBuilding,
  faComments,
  faMoneyCheckAlt,
  faMapMarkedAlt,
  faShieldAlt,
  faImages,
  faAlignJustify,
  faGlobe,
  faInfoCircle,
  faPaperPlane,
  faExpandArrowsAlt,
  faCheck,
  faTimes,
  faEraser,
  faIdCard,
  faBook,
  faFileExcel,
  faFile,
  faDatabase,
  faCloud,
  faMailchimp,
  faUpload,
  faServer,
  faHistory,
  faCloudDownloadAlt,
  faTrash,
  faDownload,
  faCreditCard,
  faPaypal,
  faMoneyCheck,
  faEdit,
  faYoutube,
  faTelegram,
  faPhone,
  faBullseye,
  faPlus,
  faEye,
  faEyeSlash,
  faInfo,
  faTable,
  faSignOutAlt,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faCheckDouble,
  faClock,
  faLock,
  faSignInAlt,
  faColumns,
  faPlay,
)

import Login from './components/Login/Login.vue'
import SignUp from './components/Login/SignUp.vue'
import Forgot from './components/Login/Forgot.vue'
import Confirm from './components/Login/Confirm.vue'

import Welcome from './components/Welcome/Index.vue'
import Dashboard from './components/Dashboard/Index.vue'
import WizardUpload from './components/Wizards/types/Upload.vue'
import WizardList from './components/Wizards/types/List.vue'

// import Forms from './components/Forms/Forms.vue'
// import FormsView from './components/Forms/FormsView.vue'

import MyData from './components/MyData/Index.vue'

import Settings from './components/Settings/Index.vue'

import Tools from './components/Tools/Tools.vue'
import ToolsProcessor from './components/Tools/ToolsProcessor.vue'
import ToolsCategory from './components/Tools/ToolsCategory.vue'
import ToolsCategoryProcessors from './components/Tools/ToolsCategoryProcessors.vue'
import Contact from './components/Contact/Contact.vue'
import Changes from './components/Changes/Changes.vue'

import AdminSales from './components/Admin/Sales/Index.vue'
import AdminUsers from './components/Admin/Users/Index.vue'
import AdminTools from './components/Admin/Tools/Index.vue'
import AdminChanges from './components/Admin/Changes/Index.vue'
import AdminSystem from './components/Admin/System/Index.vue'

/*import Chart from './components/Chart.vue'*/


import i18n from '@/plugins/i18n';

console.log("NODE_ENV", process.env.NODE_ENV);
var base = 'https://api.uproc.io';
if (process.env.NODE_ENV === 'development') {
  base = 'http://localhost:3002';
} else if (process.env.NODE_ENV === 'development2prod') {
  base = "https://uproc-api.cr.vps2.clients.killia.com"
} else if (process.env.NODE_ENV === 'staging') {
  base = 'http://127.0.0.1:4000';
}

axios.defaults.baseURL = base;
axios.defaults.headers = {
  'Content-Type': 'application/json'
};

var getPreferredLanguage = function(langs) {
  var detectedEs = langs.split(";").map(function(l) {
    return l.toLowerCase().trim();
  }).filter(function(l) {
    return l.indexOf("es") !== -1;
  });
  return (detectedEs && detectedEs.length > 0) ? "es" : "en";
}

Vue.prototype.$prefix = new Date().getTime();
Vue.prototype.$locale = getPreferredLanguage(navigator.language);
Vue.prototype.$http = axios
Vue.prototype.$apiBase = base;
Vue.prototype.$defaultKey = 'bf572286a9ea25a28b9c896b03b7176e'
Vue.use(VueRouter)
Vue.use(VModal, {
  dialog: true
})
Vue.use(Toast, {
  position: "top-right",
  timeout: 2000
});
Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VTooltip);
Vue.use(Vue2Filters);
Vue.use(VueSimpleMarkdown);

const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login/:email?',
    component: Login
  },
  {
    path: '/signup/:referral?',
    component: SignUp
  },
  {
    path: '/forgot/:email?',
    component: Forgot
  },
  {
    path: '/invitation/:referral',
    redirect: '/signup/:referral'
  },
  {
    path: '/confirm/signup/:id',
    component: Confirm
  },
  {
    path: '/welcome',
    component: Welcome
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/dashboard/details',
    component: Dashboard
  },
  {
    path: '/wizard',
    redirect: '/wizard/file'
  },
  {
    path: '/wizard/file',
    component: WizardUpload
  },
  {
    path: '/wizard/list',
    component: WizardList
  },
  {
    path: '/wizard/list/:name',
    component: WizardList
  },
  {
    path: '/mydata/bulks',
    component: MyData
  },
  {
    path: '/mydata/lists',
    component: MyData
  },
  {
    path: '/mydata/lists/:name',
    component: MyData
  },
  {
    path: '/mydata/lists/:name/report',
    component: MyData
  },
  {
    path: '/mydata/tables',
    component: MyData
  },
  {
    path: '/mydata/schedules',
    component: MyData
  },
  {
    path: '/mydata/tables/:name',
    component: MyData
  },
  {
    path: '/settings/profile',
    component: Settings
  },
  {
    path: '/settings/profile/data',
    redirect: '/settings/profile'
  },
  {
    path: '/settings/profile/notifications',
    component: Settings
  },
  {
    path: '/settings/profile/change-password',
    component: Settings
  },
  {
    path: '/settings/profile/system',
    component: Settings
  },
  {
    path: '/settings/billing',
    component: Settings
  },
  {
    path: '/settings/integration',
    component: Settings
  },
  {
    path: '/settings/integration/:method/:provider/:app',
    component: Settings
  },
  {
    path: '/settings/agreements',
    component: Settings
  },
  {
    path: '/settings/affiliate',
    component: Settings
  },
  {
    path: '/settings/billing/invoice/:year/:number',
    component: Settings
  },
  {
    path: '/tools',
    component: Tools
  },
  {
    path: '/tools/processor/:type/:module/:name/:query',
    component: ToolsProcessor
  },
  {
    path: '/tools/processor/:type/:module/:name',
    component: ToolsProcessor
  },
  {
    path: '/tools/category/:category',
    component: ToolsCategory
  },
  {
    path: '/tools/category/:category/type/:type',
    component: ToolsCategoryProcessors
  },
  {
    path: '/tools/field/:field',
    component: ToolsCategory
  },
  {
    path: '/tools/field/:field/type/:type',
    component: ToolsCategoryProcessors
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/changes',
    component: Changes
  },
  {
    path: '/history',
    redirect: '/dashboard'
  },
  {
    path: '/lists',
    redirect: '/mydata/lists'
  },
  {
    path: '/lists/:name',
    redirect: '/mydata/lists/:name'
  },
  {
    path: '/lists/:name/report',
    redirect: '/mydata/lists/:name/report'
  },
  {
    path: '/wizard/process/Files/Upload',
    redirect: '/wizard'
  },
  {
    path: '/settings',
    redirect: '/settings/profile'
  },
  {
    path: '/purchase',
    redirect: '/settings/billing'
  },
  {
    path: '/purchase/packs',
    redirect: '/settings/billing'
  },
  {
    path: '/purchase/packs/:amount',
    redirect: '/settings/billing'
  },
  {
    path: '/payments',
    redirect: '/settings/billing'
  },
  {
    path: '/settings/invite',
    redirect: '/settings/affiliate'
  },

  //admin section. all prefixed with /admin
  {
    path: '/admin',
    redirect: '/admin/dashboard'
  },
  {
    path: '/admin/dashboard',
    component: Dashboard
  },
  {
    path: '/admin/dashboard/details',
    component: Dashboard
  },
  {
    path: '/admin/mydata',
    component: MyData
  },
  {
    path: '/admin/mydata/bulks',
    component: MyData
  },
  {
    path: '/admin/mydata/lists',
    component: MyData
  },
  {
    path: '/admin/mydata/lists/:name',
    component: MyData
  },
  {
    path: '/admin/mydata/lists/:name/report',
    component: MyData
  },
  {
    path: '/admin/mydata/tables',
    component: MyData
  },
  {
    path: '/admin/mydata/schedules',
    component: MyData
  },
  {
    path: '/admin/mydata/tables/:name',
    component: MyData
  },
  {
    path: '/admin/sales',
    component: AdminSales
  },
  {
    path: '/admin/users',
    component: AdminUsers
  },
  {
    path: '/admin/tools',
    component: AdminTools
  },
  {
    path: '/admin/changes',
    component: AdminChanges
  },
  {
    path: '/admin/system',
    redirect: '/admin/system/applications'
  },
  {
    path: '/admin/system/applications',
    component: AdminSystem
  },
  {
    path: '/admin/system/services',
    component: AdminSystem
  },
  {
    path: '/admin/system/queues',
    component: AdminSystem
  },
  {
    path: '/admin/system/caches',
    component: AdminSystem
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  var token = localStorage && localStorage.token && localStorage.token !== 'undefined' ? localStorage.token : "";
  var isLogged = token && token.length > 0;
  var role = localStorage && localStorage.role && localStorage.role !== 'undefined' ? localStorage.role : "";

  if (isLogged) {
    if (to.path.startsWith('/admin') && role !== 'admin') {
      next('/welcome');
    } else if (to.path == '/' || to.path == '/login' || to.path == '/forgot' || to.path == '/signup') {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    if (to.path != '/login' && to.path != '/forgot' && to.path != '/signup' &&
      to.path.indexOf("signup/") === -1 && to.path.indexOf("confirm/") === -1 && to.path.indexOf("invitation/") === -1 && to.path.indexOf("login/") === -1) {
      next({
        path: "/login",
        query: {
          redirect: to.path
        }
      });
    } else {
      next();
    }
  }
})

library.add(faEnvelope, faKey)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.filter('numFormat', numFormat);

Vue.filter('minimizeNumber', function(input) {
  if (input !== undefined && input !== null) {
    var inputStr = input.replace(".", "").replace(",", ".");
    var inputNumber = parseFloat(inputStr);
    if (inputStr.indexOf(".") === -1) {
      inputStr += ".00";
    }
    if (inputStr.indexOf(".") > 6) {
      inputNumber = inputNumber / 1000000;
      return parseFloat(inputNumber).toFixed(1) + "m";
    } else if (inputStr.indexOf(".") > 3) {
      inputNumber = inputNumber / 1000;
      return parseFloat(inputNumber).toFixed(0) + "k";
    } else if (inputStr.indexOf(".") > 1) {
      inputNumber = Math.ceil(inputNumber);
      return parseFloat(inputNumber).toFixed(0);
    } else {
      return input;
    }
  }
  return input;
});

Vue.filter('uppercase', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})


Vue.filter('numberOnly', function(value) {
  if (isNaN(value)) {
    return ''
  } else {
    return value
  }
})

Vue.filter('toInt', function(value) {
  if (isNaN(value)) {
    return ''
  } else {
    return parseInt(value)
  }
})


Vue.filter('numberOnlyNaM', function(value) {
  if (isNaN(value)) {
    return 0
  } else {
    return value
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})

Vue.filter('formatDateSeconds', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
  }
})


Vue.filter('formatDateShort', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})

Vue.filter('makeRange', function(input) {

  var lowBound, highBound;
  switch (input.length) {
    case 1:
      lowBound = 0;
      highBound = parseInt(input[0], 10) - 1;
      break;
    case 2:
      lowBound = parseInt(input[0], 10);
      highBound = parseInt(input[1], 10);
      break;
    default:
      return input;
  }
  var result = [];
  for (var i = lowBound; i <= highBound; i++) {
    result.push(i);
  }
  return result;

});

Vue.filter('typeFilter', function(input) {

  var returned = '';

  if (input == 'type-normalize') {
    returned = 'Clean';
  } else if (input == 'type-normalize-description') {
    returned = 'Clean and format your data by field';
  } else if (input == 'type-check') {
    returned = 'Verify';
  } else if (input == 'type-check-description') {
    returned = 'Check validity of your data against rules';
  } else if (input == 'type-get') {
    returned = 'Enrich';
  } else if (input == 'type-get-description') {
    returned = 'Complete data with related information';
  } else if (input == 'type-unify') {
    returned = 'Unify';
  } else if (input == 'type-unify-description') {
    returned = 'Detect duplicated leads and unify them';
  } else if (input == 'type-get-verb') {
    returned = 'Get';
  } else if (input == 'type-check-verb') {
    returned = 'Check if';
  } else if (input == 'type-send-verb' || input == 'type-send') {
    returned = 'Send';
  } else if (input == 'group-all-description') {
    returned = 'Full tool list';
  } else if (input == 'group-communication-description') {
    returned = 'Email, Phone, Mobile';
  } else if (input == 'group-audio-description') {
    returned = 'Text2Speech';
  } else if (input == 'group-company-description') {
    returned = 'Cif, Name, Employee';
  } else if (input == 'group-editorial-description') {
    returned = 'Isbn, Author, Editor';
  } else if (input == 'group-finance-description') {
    returned = 'Credit card, Account, Currency';
  } else if (input == 'group-geographic-description') {
    returned = 'Country, City, Route';
  } else if (input == 'group-image-description') {
    returned = 'Screenshot, EXIF, QR, Barcode';
  } else if (input == 'group-internet-description') {
    returned = 'Domain, Ip, Uri';
  } else if (input == 'group-lead-description') {
    returned = 'Full record';
  } else if (input == 'group-personal-description') {
    returned = 'Name, Surname, Gender';
  } else if (input == 'group-product-description') {
    returned = 'Ean, Upc, Asin, Gtin, Isbn';
  } else if (input == 'group-security-description') {
    returned = 'Password, Md5, Luhn';
  } else if (input == 'group-text-description') {
    returned = 'Uppercase, Lowercase, Split';
  } else if (input == 'group-realestate-description') {
    returned = 'Reference, Address';
  } else if (input == 'group-audio-name') {
    returned = 'Audio';
  } else if (input == 'group-image-name') {
    returned = 'Image';
  } else if (input == 'group-communication-name') {
    returned = 'Communication';
  } else if (input == 'group-company-name') {
    returned = 'Company';
  } else if (input == 'group-editorial-name') {
    returned = 'Editorial';
  } else if (input == 'group-finance-name') {
    returned = 'Finance';
  } else if (input == 'group-geographic-name') {
    returned = 'Geographic';
  } else if (input == 'group-internet-name') {
    returned = 'Internet';
  } else if (input == 'group-lead-name') {
    returned = 'Lead';
  } else if (input == 'group-product-name') {
    returned = 'Product';
  } else if (input == 'group-personal-name') {
    returned = 'Personal';
  } else if (input == 'group-security-name') {
    returned = 'Security';
  } else if (input == 'group-text-name') {
    returned = 'Text';
  } else if (input == 'group-realestate-name') {
    returned = 'Real Estate';
  } else {
    returned = "select-option";
  }


  return returned;

})

console.log("NODE_ENV", process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://41de136c6d44f49c6594169faa36abcf@o4507191300128768.ingest.us.sentry.io/4508313176899584",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Vue.use(VueDrift, {
    appId: '5hv8nz32nbr4',
    backgroundColor: '#0300e2',
    locale: 'en', //this.$i18n.locale.toLowerCase().indexOf("es") !== -1 ? "es" : "en",
  })
  Vue.use(VueAnalytics, {
    id: 'UA-42919027-5',
    router
  });
}

import {
  store
} from './store/store'

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
