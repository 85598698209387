<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

      <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

        <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>


        <div class="row no_side_margin total-wizard">
          <div class="col-lg-12 ">
            <div id="content">
              <h3>{{ $t('wizard-welcome-reason' ) }}</h3>
              <div class="total-upload-file pt-4 text-left">
                <WelcomeEmail v-if="reason === 'my-email-marketing-campaigns'"/>
                <WelcomeSms v-else-if="reason === 'my-sms-marketing-campaigns'"/>
                <WelcomeSales v-else-if="reason === 'my-sales-funnel'"/>
                <WelcomeRecruitment v-else-if="reason === 'my-recruitment-process'"/>
                <WelcomeData v-else/>
                <WelcomeChange @change-reason="setReason" :reason="reason"/>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios';

import WelcomeEmail from './cases/Email.vue'
import WelcomeSms from './cases/Sms.vue'
import WelcomeData from './cases/Data.vue'
import WelcomeSales from './cases/Sales.vue'
import WelcomeRecruitment from './cases/Recruitment.vue'
import WelcomeChange from './cases/Change.vue'

export default {
  name: 'welcome',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      reason: '',
      source: "",
      user: {},
    }
  },
  async created() {
    
  },
  mounted() {
    this.enableInterceptor();
    this.getUserDetails();
  },
  components: {

    Header,
    Sidebar,
    Footer,

    WelcomeEmail,
    WelcomeSms,
    WelcomeData,
    WelcomeSales,
    WelcomeRecruitment,
    WelcomeChange,

  },
  methods: {
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    getConfigAuth: function() {
      return {
        headers: {
          Authorization: "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    getUserDetails: function() {
      var url = "/api/v2/profile";
      this.$root.$emit('isLoading', true);
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.$root.$emit('isLoading', false);
        if (results.data.signup_reason.length) {
          this.reason = results.data.signup_reason.toLowerCase().replace(/ /g, '-');
        } else {
          this.reason = 'my-existing-data';
        }
      });
    },

    setReason: function(reason) {
      //console.log(reason);
      this.reason = reason;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/wizards.css';
@import '../../assets/css/global.css';
</style>
