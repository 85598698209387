<template>
<div>
  <div v-if="language === 'es'">
    He visto que quieres <strong>enviar campañas de correo</strong> ;)<br>
    ¿Sabes que con uProc puedes <strong>evitar los rebotes</strong> si <strong>verificas tus correos</strong> previamente?
    <br><br>
    Consíguelo en tres sencillos pasos:
    <ul>
      <li><strong><a href="/#/wizard/file">Sube</a></strong> tu fichero de correos desde el asistente.</li>
      <li><strong>Elije</strong> la herramienta <strong>"Comprobar si correo existe"</strong> y procesa tu fichero.</li>
      <li><strong>Recibe</strong> los resultados por correo.</li>
    </ul>
    También puedes comprobar tus correos desde
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists#make" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists#zapier" target="_blank">Zapier</a></strong> o
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists#api" target="_blank">API</a></strong>.

    <br>
    Elije el método que mejor se adapta a tus necesidades.
    
    </div>  
    <div class="row" v-else-if="language === 'fr'">
      <div class="col-12">
        J'ai vu que vous voulez <strong>envoyer des campagnes d'email</strong> ;)<br>
        Savez-vous qu'avec uProc vous pouvez <strong>éviter les rebonds</strong> si vous <strong>vérifiez vos emails</strong> au préalable?
        <br><br>
        Obtenez-le en trois étapes simples:
        <ul>
          <li><strong><a href="/#/wizard/file">Téléchargez</a></strong> votre fichier d'emails depuis l'assistant.</li>
          <li><strong>Choisissez</strong> l'outil <strong>"Vérifier si l'email existe"</strong> et traitez votre fichier.</li>
          <li><strong>Recevez</strong> les résultats par e-mail.</li>
        </ul>
        Vous pouvez également vérifier vos emails depuis
        <strong><a href="https://uproc.io/blog/fr/how-to-check-if-email-exists#sheets" target="_blank">Google Sheets</a></strong>,
        <strong><a href="https://uproc.io/blog/fr/how-to-check-if-email-exists#n8n" target="_blank">n8n</a></strong>,
        <strong><a href="https://uproc.io/blog/fr/how-to-check-if-email-exists#make" target="_blank">Make</a></strong>,
        <strong><a href="https://uproc.io/blog/fr/how-to-check-if-email-exists#zapier" target="_blank">Zapier</a></strong> ou
        <strong><a href="https://uproc.io/blog/fr/how-to-check-if-email-exists#api" target="_blank">API</a></strong>.
        <br>Choisissez la méthode qui convient le mieux à vos besoins.
      </div>
    </div>
    <div v-else>
      I see you want to <strong>send email campaigns</strong> ;)<br>
      Did you know that with uProc you can <strong>avoid bounces</strong> if you <strong>verify your emails</strong> beforehand?
      <br><br>
      Achieve it in three simple steps:
      <ul>
        <li><strong><a href="/#/wizard/file">Upload</a></strong> your email file from the wizard.</li>
        <li><strong>Select</strong> the tool <strong>"Check if email exists"</strong> and process your file.</li>
        <li><strong>Receive</strong> the results by email.</li>
      </ul>
      You can also check your emails from
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists#sheets" target="_blank">Google Sheets</a></strong>,
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists#n8n" target="_blank">n8n</a></strong>,
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists#make" target="_blank">Make</a></strong>,
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists#zapier" target="_blank">Zapier</a></strong> or
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists#api" target="_blank">API</a></strong>.

      <br>
      Choose the method that best suits your needs.
      
    </div>
  </div>
</template>
<script>
export default {
  name: 'WelcomeEmail',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
