<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>


      <div class="row no_side_margin">
        <div class="total col-lg-12 ">

          <div class="tab-content">

            <div id="home" class="tab-pane container active">
              <div class="row pb-4" v-if="changeEditId !== null">
                <div class="col-12">
                  <h4 v-if="changeEditId === -1">{{ $t('admin-changes-new') }}</h4>
                  <h4 v-else>{{ $t('admin-changes-editing') }}</h4>
                  <p class="pb-4 pt-2" v-html="$t('admin-changes-edit-description')"></p>
                  <form @submit.prevent="saveChange">
                  <div class="form-group row mb-3">
                    <label for="date" class="col-12 col-sm-2 text-sm">{{ $t('admin-changes-date') }}</label>
                    <div class="col-12 col-sm-5">
                    <input type="date" v-model="changeToEdit.date" class="form-control" id="date" required />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="description" class="col-12 col-sm-2 text-sm">{{ $t('admin-changes-description') }}</label>
                    <div class="col-12 col-sm-5">
                    {{ $t('admin-changes-language-en') }}
                    <textarea v-model="changeToEdit.description.en" class="form-control" id="description" rows="6" required></textarea>
                    {{ $t('admin-changes-language-es') }}
                    <textarea v-model="changeToEdit.description.es" class="form-control" id="description" rows="6"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="public" class="col-12 col-sm-2 text-sm">{{ $t('admin-changes-public') }}</label>
                    <div class="col-12 col-sm-5">
                    <input type="checkbox" v-model="changeToEdit.public" id="public" />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="tags" class="col-12 col-sm-2 text-sm">{{ $t('admin-changes-tags') }}</label>
                    <div class="col-12 col-sm-5">
                    <select v-model="changeToEdit.tags" class="form-control" id="tags" multiple required>
                      <option value="tool">{{ $t('tags-tool') }}</option>
                      <option value="performance">{{ $t('tags-performance') }}</option>
                      <option value="processing">{{ $t('tags-processing') }}</option>
                      <option value="webapp">{{ $t('tags-webapp') }}</option>
                    </select>
                    </div>
                  </div>
                  <div class="form-group row justify-content-start">
                    <div class="col-auto">
                    <button type="button" @click="changeEditId = null" class="btn btn-secondary mr-2">{{ $t('cancel') }}</button>
                    <button type="submit" class="btn btn-primary">{{ $t('save') }}</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-9 col-7 col-title ">
                  <h4>{{ $t('list' ) }}
                    <!-- {{ this.totalRecordsSection }} -->
                    <span v-if="this.totalRecordsSection.changes > 0">({{ this.totalRecordsSection.changes }})</span>
                  </h4>
                </div>
              
                <div class="col-12 pb-2" v-if="changeEditId === null">
                  <button @click="addNewChange" class="btn btn-primary">{{ $t('admin-changes-create') }}</button>
                </div>
              </div>
             
              <div class="col-lg-12 no_side_pad request" v-if="this.changes && this.changes.length">
                <div class="page-nav">
                  <div class="row no_side_margin">
                  <div class="col-lg-2 col-sm-4 col-4 text-right">
                    <div><a :class="{'disabled': isFirstPageBySection('changes'), '': !isFirstPageBySection('changes')}" v-on:click="movePreviousBySection('changes')"><span class="nav-icon">←</span> {{ $t('previous' ) }} </a></div>
                  </div>
                  
                  <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                    {{ $t('page' ) }} {{ this.currentPageSection.changes + 1 }} / {{ this.totalPagesSection.changes }}
                  </div>

                  <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                    <div><a :class="{'disabled': isLastPageBySection('changes'), '': !isLastPageBySection('changes')}" v-on:click="moveNextBySection('changes')"> {{ $t('next' ) }} <span class="nav-icon">→</span></a></div>
                  </div>
                  </div>
                </div>
                </div>

              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('admin-changes-date' ) }}</th>
                      <th class="t-head">{{ $t('admin-changes-description' ) }}</th>
                      <th class="t-head text-center">{{ $t('admin-changes-public' ) }}</th>
                      <th class="t-head">{{ $t('admin-changes-tags' ) }}</th>
                      <th class="t-head"></th>
                    </tr>
                  </thead>

                  <tbody v-if="!this.changes || !this.changes.length">
                    <tr>
                      <td colspan="5" class="table-col text-center">{{ $t('no-results' ) }}</td>
                    </tr>
                  </tbody>

                  <tbody v-if="this.changes && this.changes.length">
                    <tr v-for="entry in this.changes" v-bind:key="entry.key">
                      <!-- {{ entry }} -->
                      <td>{{ entry.date }}</td>
                      <td>
                        {{ entry.description.en }}
                      </td>
                      <td class="text-center">
                        <a v-on:click="togglePublicChange(entry.id)" :class="{'badge badge-success': entry.public, 'badge badge-danger': !entry.public}">
                          {{ entry.public ? $t('yes') : $t('no') }}
                        </a>
                      </td>
                      <td>
                        <div v-for="tag in entry.tags" :key="tag">
                          <span class="badge badge-info">{{ $t('tags-' + tag) }}</span>
                        </div>
                      </td>
                      <td>
                        <a v-on:click="editChange(entry)" class="blue-link">
                          <font-awesome-icon icon="edit" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
                
          </div>

        </div>
      </div>

              
              
      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Dashboard',
  components: {
    Header,
    Sidebar,
    Footer,
    
  },
  data() {
    return {
      isActive: false,
      isAdmin: false,
      isActiveIcon: '<',
      period: 'thisweek',
      periodAmounts: 7,
      selectedUser: 'me',
      selectedUserEmail: '',
      selectedUserObj: {},
      customSearch: false,
      customSearchTool: false,

      currentLocale: this.$i18n.locale,
      filterStatus: 'paid',

      balance: 1,

      kpisAll: {
        total_day: -1,
        cost: -1,
        forecast: "-"
      },
      kpisList: [],
      kpisTotal: {
        all: {
          count: "-",
        }
      },
      isCostByHour: true,
      startDate: '',
      endDate: '',
      graphOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      datacollection: {
        datasets: []
      },
      toggleClass: "ani1",
      state: [],
      config: {},
      graph: {
        data: [],
        options: {}
      },

      loading: false,

      totalPagesSection: {
        jobs: 0,
        requests: 0,
        changes: 0,
        sales: 0,
        users: 0
      },

      currentPageSection: {
        jobs: 0,
        requests: 0,
        changes: 0,
        sales: 0,
        users: 0
      },

      totalRecordsSection: {
        jobs: 0,
        requests: 0,
        changes: 0,
        sales: 0,
        users: 0
      },

      totalRowsSection: {
        jobs: 0,
        requests: 0,
        changes: 0,
        sales: 0,
        users: 0,
      },

      rowsPerPageSection: {
        jobs: 10,
        requests: 10,
        changes: 10,
        sales: 10,
        users: 10
      },

      prevStartDate: null,
      prevEndDate: null,
      requests: [],
      requestShown: -1,
      shownAllRequests: false,
      jobs: [],
      jobsRunning: false,
      countdownToRefresh: 30,
      secondsToRefresh: 30,

      totalChanges: [],
      totalUsers: [],
      filterText: '',
      filterTool: '',
      
      changes: {
        results: [],
      },
      edited_tool_key: '',

      sales: {
        invoices: [],
        payments: [],
        filteredPayments: [],
        subscriptions: [],
        total: 0
      },
      users: {
        list: [],
        total: []
      },

      result: null,
      currency: '€',
      
      status: {
        apps: [],
        services: []
      },

      changeEditId: null,
      changeToEdit: {
        date: '',
        description: {
          en: '',
          es: ''
        },
        public: true,
        tags: []
      }
    };
  },
  mounted() {
    this.enableInterceptor();
    this.getChanges();
  },
  methods: {
    recoverStorageValues() {
      this.isAdmin = localStorage.user.role === 'admin';
      this.period = localStorage.period || 'thisweek';
      this.selectedUser = localStorage.user.role  === 'admin' ? localStorage.panelSelectedUser : 'me';
      if (!this.selectedUser) {
        this.selectedUser = 'me';
      }
    },
    
    getConfigAuth() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    getProcessingValues: function() {
      const url = '/api/v2/processing';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.processing = results.data;
        this.currency = this.processing.currency === 'usd' ? '$' : '€';
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    addNewChange() {
      this.changeEditId = -1;
      this.changeToEdit = {
        date: new Date().toISOString().split('T')[0],
        description: {
          en: '',
          es: ''
        },
        public: true,
        tags: []
      };
    },

    toggleFieldValue(toolKey, field) {
      this.$http.get(`/api/v2/panel/changes/${toolKey}/toggle/${field}`, this.getConfigAuth()).then(() => {
        this.getChanges(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
      });
    },

    editFieldValue(toolKey, field, value) {
      this.$http.get(`/api/v2/panel/changes/${toolKey}/edit/${field}/${value}`, this.getConfigAuth()).then(() => {
        this.edited_tool_key = '';
        this.getChanges(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    editChange(change) {
      this.changeEditId = change.id;
      this.changeToEdit = {
        date: change.date,
        description: change.description,
        public: change.public,
        tags: change.tags
      };
    },

    setSection(section) {
      $('.nav-tabs a[href="#' + section + '"]').tab('show');
    },

    setSectionAndUrl(section) {
      //update url
      if (section.length) {
        section = '/' + section;
      }
      window.history.pushState({}, '', '#/dashboard' + section);
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areDatesDifferents: function(setDates) {
      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    isFirstPageBySection: function(section) {
      return this.currentPageSection[section] === 0;
    },

    
    isLastPageBySection: function(section) {
      return this.currentPageSection[section] === this.totalPagesSection[section] - 1;
    },

    showToolDetails: function(requestIndex) {
      this.requestShown = requestIndex;
    },

    moveNextBySection: function(section) {
      if (this.isLastPageBySection(section)) {
        this.loading = true;
      }
      this.moveNextChanges();
    },

    moveNextChanges: function() {
      if (this.currentPageSection.changes + 1 < this.totalPagesSection.changes) {
        this.currentPageSection.changes++;
        this.getChanges(true);
      }
    },

    movePreviousBySection: function(section) {
      this.clearCountdown();
      if (this.isFirstPageBySection(section)) {
        this.loading = true;
      }
      this.movePreviousChanges();
    },

    movePreviousChanges: function() {
      if (this.currentPageSection.changes - 1 >= 0) {
        this.currentPageSection.changes--;
        this.getChanges(true);
      }
    },

    getChanges: function() {
      var url = `/api/v2/changes/${this.currentPageSection.changes}`;
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.changes = results.data.results;
        this.totalRecordsSection.changes = results.data.count;
        this.totalPagesSection.changes = results.data.totalPages;
        this.rowsPerPageSection.changes = results.data.rowsPerPage;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
      });
    },

    saveChange: function() {
      if (this.changeEditId === -1) {
        //console.log(this.changeToEdit);
        this.$http.post('/api/v2/changes', this.changeToEdit, this.getConfigAuth()).then(() => {
          this.getChanges(true);
          this.changeEditId = null;
        }).catch(() => {
          this.$root.$emit('isLoading', false);
        });
      } else {
        this.$http.post(`/api/v2/changes/${this.changeEditId}`, this.changeToEdit, this.getConfigAuth()).then(() => {
          this.getChanges(true);
          this.changeEditId = null;
        }).catch(() => {
          this.$root.$emit('isLoading', false);
        });
      }
    },

    togglePublicChange: function(change_id) {
      this.$http.get(`/api/v2/changes/${change_id}/toggle_public`, this.getConfigAuth()).then(() => {
        this.getChanges(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
      });
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    resetCurrentPage: function() {
      this.currentPageSection = {
        jobs: 0,
        requests: 0,
        changes: 0,
        sales: 0,
        users: 0
      };
    },
  },
  watch: {

    state: function() {
    },
  },
  computed: {

    orderedList: function() {
      return _.orderBy(this.kpisList, ['key'], ['asc'])
    },

    dayData() {
      if (this.state != 0) {
        return this.state;
      } else {
        return [{
          "key": "cost",
          "values": [
            [Date.now(), 0]
          ]
        }, {
          "key": "saving",
          "values": [
            [Date.now(), 0]
          ]
        }];
      }
    }
  }
}
</script>

<style scoped>
#secure {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 20px;
  margin-top: 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../../assets/css/global.css';
</style>
