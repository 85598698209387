<template>
  <div id="top">
    <Header />
    <div class="row no_side_margin content">
      <Sidebar />
      <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">
        <button @click="menuCollapse" class="collapse-btn">{{ isActiveIcon }}</button>
        <div class="">
          <div class="total-wizard">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb pl-3">
                <li class="breadcrumb-item ">{{ $t('menu-wizard') }}</li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('source-upload') }}</li>
              </ol>
            </nav>
            <div v-if="this.stepNumber == 1">
              <div class="row no_side_margin">
                <div class="col-lg-12">
                  <div class="total-upload-file">
                    <div class="row" v-if="this.user && this.user.signup_reason && this.user.signup_reason.length">
                      <div class="col-lg-12 text-left pb-4">
                        {{ $t('wizard-reason') }}
                        <strong>{{ $t("feedback-reason-" + this.user.signup_reason) }}</strong>
                      </div>
                    </div>
                    <div v-html="$t('wizard-platform-description')" class="text-left pb-4"></div>
                    <div v-if="this.source === 'upload'">
                      <div class="row">
                        <div class="col-lg-12 pb-4">
                          <div class="select-file">
                            <div class="icon">
                              <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M10 6H27.4142L38 16.5858V42H10V6ZM12 8V40H36V18H26V8H12ZM28 9.41421L34.5858 16H28V9.41421Z"
                                  fill="#0300e2" />
                              </svg>
                            </div>
                            <input type="file" accept=".csv,.xls,.xlsx,.zip,.tgz,.gz" id="file" ref="file"
                              class="custom-file-input upload-data" lang="es" v-on:change="uploadFile()">
                            <h5>{{ $t('wizard-file-computer') }}</h5>
                            <div class="error text-center pb-4" v-if="isError">
                              <strong v-html="$t('wizard-file-error')"></strong>
                            </div>
                            <div class="small">
                              <p v-html="$t('wizard-file-format')"></p>
                            </div>
                          </div>
                          <ChangeSource currentType="upload" v-on:change-source="setSource" v-if="isAdmin" />
                        </div>
                      </div>
                    </div>
                    <div v-if="this.source == 'url'">
                      <SourceUrl v-on:upload="uploadUrl" v-on:change-source="setSource" />
                    </div>
                    <div v-if="this.source == 'sftp'">
                      <SourceSftp v-on:upload="uploadSftp" v-on:change-source="setSource" />
                    </div>
                    <div v-if="this.source == 's3'">
                      <SourceS3 v-on:upload="uploadS3" v-on:change-source="setSource" />
                    </div>
                    <div v-if="this.source == 'mongo'">
                      <SourceMongo v-on:upload="uploadMongo" v-on:change-source="setSource" />
                    </div>
                    <div v-if="this.source == 'mysql'">
                      <SourceMysql v-on:upload="uploadMysql" v-on:change-source="setSource" />
                    </div>
                    <div v-if="this.source == 'postgres'">
                      <SourcePostgres v-on:upload="uploadPostgres" v-on:change-source="setSource" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no_side_margin" v-if="this.stepNumber == 2">
              <div class="col-lg-12">
                <h4 class="break-word">{{ $t('wizard-data-info') }} <i>{{ this.processing.upload.originalName }}</i></h4>
                <div class="data-count">
                  <div class="row no_side_margin">
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div>
                      <p class="font-weight-bold">{{ $t('total-rows', { rows: this.totalRows }) }}</p>
                      </div>
                      <div>
                      <a @click="cleanAll()" :alt="$t('cancel')" :title="$t('cancel')" class="btn btn-danger">
                      <font-awesome-icon icon="trash" />
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="overflow-x:auto;" v-if="this.previewData && this.fileContents" id="previewData">
                  <table class="table table-striped list-table no-mar preview-table">
                    <thead>
                      <tr>
                        <th class="t-head font-15"><small>{{ $t('row') }}</small></th>
                        <th class="t-head font-15" v-for="(row, index) in this.fileContents[0]" v-bind:key="index">
                          <small>{{ processing.headers[index] ? processing.headers[index] + " " : "" }}(COL{{ index +
                            1}})</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, rowNumber) in this.fileContents" v-bind:key="rowNumber"
                        style="max-height: 20px;">
                        <td class="table-col font-15"><small>{{ rowNumber + 1 }}</small></td>
                        <td class="table-col font-15" v-for="(col, colNumber) in row" v-bind:key="colNumber">
                          <small>{{ fileContents[rowNumber][colNumber] }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <a id="wizardSection"></a>
                <div class="data-count" v-if="!showCustomWizard">
                    <div v-if="this.proposedTools && this.proposedTools.length">
                    <p class="font-weight-bold">{{ $t('wizard-proposed-tools') }}:</p>
                    <ul class="list-group pt-4">
                      <li
                      v-for="(tool) in this.proposedTools.slice(0, showAllProposed ? this.proposedTools.length : maxShownTools)"
                      :key="tool.key" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <div class="col-12 col-md-9">
                        <strong>{{ tool.description }}
                        <a class="btn btn-sm" :href="'#/tools/processor/' + tool.path" target="_blank"
                          :title="$t('view')" :alt="$t('view')">
                          <i class="fa fa-eye"></i>
                        </a>
                        </strong>
                        <div class="small" v-if="tool.extended_description">
                        <div style="font-weight: normal;" v-html="tool.extended_description.replace('<br>', '')">
                        </div>
                        <br>
                        <div style="font-weight: normal;">
                          <span class="text-muted">{{ $t('params') }}:</span>
                          <span>
                          {{ tool.mappedColumns.map((col) => col.field + " (COL" + (col.column + 1) + ")").join(', ') }}
                          </span>
                        </div>
                        <div style="font-weight: normal;">
                          <span class="text-muted">{{ $t('output') }}:</span>
                          <span v-if="tool.output.length">
                          {{ tool.output.map((prop) => prop.name).join(', ') }}
                          </span>
                          <span v-else>
                          result
                          </span>
                        </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-2 mt-2 mt-md-0">
                        <small>{{ $t('price-initial') }}</small> <span class="badge badge-primary badge-pill">{{
                        tool.price.toFixed(2) }}{{ currency }}/1000 {{ $t('queries') }}</span>
                        <!-- <small>{{ $t('price-final') }}</small>  -->
                      </div>
                      <div class="col-6 col-md-1 text-right mt-2 mt-md-0">
                        <button class="btn btn-sm btn-primary" @click="addProposedTool(tool)" :title="$t('add')"
                        :alt="$t('add')">
                        <i class="fa fa-plus"></i>
                        </button>
                      </div>
                      </li>
                    </ul>
                    <small class="empty-text">{{ $t('price-initial-help') }}</small>
                    <div class="text-center mt-2" v-if="this.proposedTools.length > maxShownTools">
                      <a @click="showAllProposed = !showAllProposed" class="link text-md">
                          {{ showAllProposed ? $t('show-less') : $t('show-more') }}
                      </a>
                    </div>
                    </div>
                  <div class="text-center mt-5">
                    <p>{{ $t('wizard-proposed-none') }}</p>
                    <a @click="enableCustomWizard(true)" href="#/wizard" class="btn btn-primary">
                      {{ $t('wizard-go-custom') }}
                    </a>
                  </div>
                </div>
                <div class="col-lg-12 data-count padding_external" v-else>
                  <p class="font-weight-bold pb-4">{{ $t('wizard-select-processors') }}</p>
                  <div class="wizard_custom">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="item-tools">
                          <h4>
                            <span class="number">1</span>&nbsp;&nbsp;
                            <span v-if="this.mode == 'fields'">{{ $t('wizard-budget-field') }}</span>
                            <!-- <span v-if="this.mode == 'groups'">{{ $t('wizard-budget-category') }}</span> -->
                          </h4>

                          <div class="col-12 no_side_pad select_period total-select">
                            <span v-if="this.mode == 'fields'">
                              <span>{{ $t('wizard-budget-field-help') }}</span>
                            </span>
                            <span v-if="this.mode == 'groups'">{{ $t('wizard-budget-category-help') }}</span>
                            <div class="py-2">
                              <select class="form-control" v-model="group">
                                <option value="" selected>{{ $t("select-option") }}</option>
                                <option v-for="group in this.groups" v-bind:key="group.name"
                                  v-on:click="selectCategory(group.translated, group.name)">{{ group.translated }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 no_side_pad">
                        <div class="item-tools" :class="{ 'disabled': !group.length }">
                          <h4>
                            <span class="number">2</span>&nbsp;&nbsp;
                            <span>{{ $t('wizard-budget-action') }} </span>
                          </h4>
                          <div class=" col-12 no_side_pad select_period total-select">
                            <span>{{ $t('wizard-budget-type-help') }}</span>
                            <div class="py-2" :key="group">
                              <select class="form-control" v-model="selectType">
                                <option value="" selected>{{ $t("select-option") }}</option>
                                <option v-for="type in this.availableTypes" v-bind:key="type"
                                  v-on:click="changeType('type-' + type, type)">{{ $t('type-' + type) }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 no_side_pad">
                        <!-- --{{ selectType }}-- -->
                        <div class="item-tools" :class="{ 'disabled': !selectType.length }">
                          <h4>
                            <span class="number">3</span>&nbsp;&nbsp;
                            <span>{{ $t('wizard-budget-processor') }} </span>
                          </h4>
                          <div class=" col-12 no_side_pad  select_period total-select">
                            <span>{{ $t('wizard-budget-processor-help') }}</span>
                            <div class="py-2" :key="selectType">
                              <select class="form-control" v-model="selectTool">
                                <option value="" selected>{{ $t("select-option") }}</option>
                                <option v-for="processor in this.processors" v-bind:key="processor.key"
                                  v-on:click="selectProcessor(processor.key, true)">{{ $t('type-' + processor.type +
                                  '-verb') }} {{ processor.description }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 no_side_pad">
                        <div class="item-tools" :class="{ 'brd-none disabled': !selectTool.length }" :key="selectTool">
                          <h4>
                            <span class="number">4</span>&nbsp;&nbsp;
                            <span>{{ $t('wizard-budget-column') }}</span>
                          </h4>
                          <div class=" col-12 no_side_pad select_period total-select select-col-drp">
                            <span>{{ $t('wizard-budget-column-help') }}</span>
                            <div class="" v-if="selectTool.length">
                              <span v-if="processor.params && processor.params.length" v-bind:key="selectTool">
                                <div v-for="(param, paramNumber) in processor.params" v-bind:key="param.name"
                                  class="py-2">
                                  <!-- {{param.name}} - {{ assignedParameters[param.name] }} -->
                                  <select class="form-control" v-model="assignedParameters[param.name]">
                                    <option value="-1" selected>{{ $t('select-column') }}: {{ param.name }} {{
                                      param.required ? "(*)" : '' }}</option>
                                    <option v-for="(n) in makeRange(processing.totalCols)"
                                      v-bind:key="param.name + '-' + n"
                                      v-on:click="setAssignedCol(paramNumber, param.name, n + 1)" :value="n">
                                      {{ processing.headers[n] }} (COL{{ (n + 1) }})
                                    </option>
                                  </select>
                                </div>
                              </span>
                              <small class="empty-text">(*) {{ $t('required') }}</small>
                            </div>
                            <div v-else>
                              <div class="py-2">
                                <select class="form-control">
                                  <option value="">{{ $t('select-column') }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!this.addSelection" class="col-lg-12 text-center">
                    <button type="button" class="btn btn-primary btn-block add-selection add"
                      v-on:click="enqueueProcessor()">{{ $t('wizard-budget-add') }}</button>
                    <button type="button" class="btn btn-secondary btn-block add-selection cancel"
                      v-on:click="resetCustomWizard()">{{ $t('cancel') }}</button>
                  </div>
                  <div class="text-center col-lg-12 p-5" v-if="proposedTools.length > 0">
                    <p>{{ $t('wizard-too-complex') }}</p>
                    <a @click="enableCustomWizard(false)" href="#/wizard" class="btn btn-primary">{{
                      $t('wizard-go-proposed') }}</a>
                  </div>
                </div>
                <div class="data-count" v-if="selectedProcessors.length" id="selectedProcessors" :key="uniqueToolStep">
                  <div>
                    <p class="font-weight-bold">{{ $t('wizard-budget-details') }}</p>
                    <div>
                      <div style="overflow-x:auto;">
                        <table class="table table-striped list-table font-15">
                          <thead>
                            <tr>
                              <th class="t-head">{{ $t('processor') }}</th>
                              <th class="t-head">{{ $t('params') }}</th>
                              <th class="t-head text-right">{{ $t('output-columns') }}</th>
                              <th class="t-head text-right">{{ $t('rows') }}</th>
                              <th class="t-head text-right">{{ $t('price_cpt') }}</th>
                              <th class="t-head text-right">{{ $t('subtotal') }}</th>
                              <th class="t-head text-right"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(processor, index) in this.selectedProcessors"
                              v-bind:key="processor.key + index">
                              <td class="table-col">{{ $t('group-' + processor.groups[0] + '-name') }} - {{ $t('type-' +
                                processor.type + '-verb') }} {{ processor.description }}
                                
                                <a class="btn btn-sm" href="javascript:void(0)" v-on:click="testTool(index)" :title="$t('test')" :alt="$t('test')" v-if="isAdmin">
                                  <font-awesome-icon icon="play" size="sm" />
                                </a>
                                
                                <a class="btn btn-sm" :href="'#/tools/processor/' + processor.type + '/' + processor.module + '/' + processor.name"
                                  target="_blank" :title="$t('view')" :alt="$t('view')">
                                  <i class="fa fa-eye" />
                                </a>  
                              </td>
                              <td class="table-col">
                                <div v-for="(col, colNumber) in processor.fileAssignedCols" v-bind:key="colNumber">
                                  <div v-if="processor.fileAssignedCols[colNumber].length">
                                    {{ processor.fileAssignedCols[colNumber] | uppercase }}:
                                    {{ processing.headers[colNumber] }} (COL{{ colNumber + 1 }})</div>
                                </div>
                              </td>
                              <td class="table-col text-right">{{ processor.extraOutputFields ? processor.extraOutputFields.length : "0" }}</td>
                              <td class="table-col text-right">{{ totalRows }}</td>
                              <td class="table-col text-right">{{ getProcessorCost(processor) * 1000 | numFormat("0.00")
                                }}{{ currency }}</td>
                              <td class="table-col text-right">{{ getProcessedCostPerRow(processor) |
                                numFormat("0.00")}}{{ currency }}</td>
                              <td class="table-col text-right col-1">
                                <div class="d-flex flex-row align-items-center">
                                  <a :title="$t('edit-output-columns')" v-on:click="editProcessorOutput(index)" class="mb-2" v-if="isAdmin">
                                  <font-awesome-icon icon="columns" size="lg" />
                                  </a>
                                  <a :title="$t('delete-tool')" v-on:click="deleteProcessor(index)" class="mb-2 ml-2">
                                  <font-awesome-icon icon="trash" size="lg" />
                                  </a>
                                </div>
                                
                              </td>
                            </tr>
                            
                          </tbody>
                          <tfoot v-if="selectedProcessors.length" class="font-bold">
                            <tr>
                              <td colspan="3" class="text-left empty-text">
                                (*) {{ $t('wizard-maximum-cost') }}
                              </td>
                              <td colspan="2" class="table-col-foot text-right">* {{ $t('total') }}</td>
                              <td class="table-col-foot text-right">{{ getTotalCost() | numFormat("0.00") }}{{ currency }}
                              </td>
                              <td class="table-col-foot"></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>

                      <div v-if="editingProcessorOutputIndex > -1" class="text-left">
                        <h5>{{ $t('group-' + editingProcessorOutput.groups[0] + '-name') }} - {{ $t('type-' + editingProcessorOutput.type + '-verb') }} {{ editingProcessorOutput.description }}</h5>
                        <h6 class="mt-0 mb-0 font-weight-bold">{{ $t('editing-output-columns') }}</h6>
                        <p class="mb-2 mt-2" v-html="$t('editing-output-columns-description')"></p>
                        <button @click="editingProcessorOutputIndex = -1" class="btn btn-secondary btn-sm mt-0 mr-2">{{ $t('cancel') }}</button>
                        <button @click="addExtraOutputField" class="btn btn-sm btn-primary mt-0">{{ $t('add') }}</button>
                        <div v-if="editingProcessorOutput.extraOutputFields.length">
                            <div class="row pl-3 pr-3 text-xs" style="overflow-x:auto;">
                            <table class="table mt-3">
                              <thead>
                              <tr>
                                <th style="min-width: 200px;">{{ $t('output-type') }}</th>
                                <th style="min-width: 150px;">{{ $t('output-name') }}</th>
                                <th style="min-width: 150px;">{{ $t('output-property') }}</th>
                                <th style="min-width: 200px;">{{ $t('output-condition') }}</th>
                                <th style="min-width: 150px;">{{ $t('output-value') }}</th>
                                <th style="min-width: 150px;">{{ $t('output-value-extract') }}</th>
                                <th style="min-width: 50px;"></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(field, index) in editingProcessorOutput.extraOutputFields" :key="index">
                              <td>
                                <select v-model="field.type" class="form-control" v-on:change="onExtraOutputFieldTypeChange(index)">
                                  <option value="logical">{{ $t('output-type-logical') }}</option>
                                  <option value="extract">{{ $t('output-type-extract') }}</option>
                                </select>
                              </td>
                              <td>
                                <input v-model="field.name" type="text" class="form-control" :placeholder="$t('name')" v-on:focus="$event.target.select()" />
                              </td>
                              <td class="col-lg-2">
                                <select v-model="field.property" class="form-control">
                                  <option v-for="(prop, propIndex) in editingProcessorOutput.finalProperties" :key="propIndex" :value="prop.key">
                                    {{ $t("output-params-" + prop.type) + ": " + prop.name }}
                                  </option>
                                </select>
                                <!-- <div v-if="editingProcessorOutput.output && editingProcessorOutput.output.length && editingProcessorOutput.output[0].properties">
                                  <select v-model="field.property" class="form-control">
                                  <option v-for="(prop, propIndex) in editingProcessorOutput.output[0].properties" :key="propIndex"
                                  :value="prop.name">{{ prop.name }}</option>
                                  </select>
                                </div>
                                <div v-else>
                                  <select v-model="field.property" class="form-control">
                                  <option :value="result" selected>{{ $t('result') }}</option>
                                  </select>
                                </div> -->
                              </td>
                              <td class="col-lg-3">
                              <select v-model="field.condition" class="form-control">
                                <option value="equals">{{ $t('output-condition-equals') }}</option>
                                <option value="equals-ignore-case">{{ $t('output-condition-equals-ignore-case') }}</option>
                                <option value="not-equals">{{ $t('output-condition-not-equals') }}</option>
                                <option value="not-equals-ignore-case">{{ $t('output-condition-not-equals-ignore-case') }}</option>
                                <option value="contains">{{ $t('output-condition-contains') }}</option>
                                <option value="contains-ignore-case">{{ $t('output-condition-contains-ignore-case') }}</option>
                                <option value="not-contains">{{ $t('output-condition-not-contains') }}</option>
                                <option value="not-contains-ignore-case">{{ $t('output-condition-not-contains-ignore-case') }}</option>
                                <option value="starts-with">{{ $t('output-condition-starts-with') }}</option>
                                <option value="starts-with-ignore-case">{{ $t('output-condition-starts-with-ignore-case') }}</option>
                                <option value="ends-with">{{ $t('output-condition-ends-with') }}</option>
                                <option value="ends-with-ignore-case">{{ $t('output-condition-ends-with-ignore-case') }}</option>
                                <option value="regex">{{ $t('output-condition-regex') }}</option>
                                <option value="not-regex">{{ $t('output-condition-not-regex') }}</option>
                                <option value="greater-than">{{ $t('output-condition-greater-than') }}</option>
                                <option value="greater-than-equals">{{ $t('output-condition-greater-than-equals') }}</option>
                                <option value="less-than">{{ $t('output-condition-less-than') }}</option>
                                <option value="less-than-equals">{{ $t('output-condition-less-than-equals') }}</option>
                              </select>
                              </td>
                              <td>
                                <input v-model="field.value" type="text" class="form-control" :placeholder="$t('output-value-placeholder')"  v-on:focus="$event.target.select()" required/>
                              </td>
                              <td>
                                <select v-model="field.extractType" class="form-control" v-if="field && field.type === 'extract'">
                                  <option value="first">{{ $t('output-value-extract-first') }}</option>
                                  <option value="last">{{ $t('output-value-extract-last') }}</option>
                                  <option value="all">{{ $t('output-value-extract-all') }}</option>
                                </select>
                              </td>
                              <td class="text-right">
                              <a title="Delete Field" v-on:click="removeExtraOutputField(index)" class="pl-2">
                                <font-awesome-icon icon="trash" size="lg" />
                              </a>
                              </td>
                              </tr>
                              </tbody>
                            </table>
                            <small class="empty-text mt-0">{{ $t('delete-output-column-help') }}</small>
                          </div>
                          <!-- <button class="btn btn-primary btn-sm mt-0" @click="editingProcessorOutputIndex = -1">{{ $t('save') }}</button> -->
                        </div>
                      </div>

                      <div class="text-center col-lg-12 p-5">
                        <p>{{ $t('wizard-more-tools') }}</p>
                        <button class="btn btn-primary" @click="addMoreTools()">{{ $t('wizard-add-more-tools')
                          }}</button>
                      </div>
                    </div>
                  </div>
                  
                  <div v-if="selectedProcessors.length" :key="uniqueToolStep">
                    <div class="data" v-if="areCreditsNeeded()">
                      <h6 v-html="$t('payment-needed', { balance: this.getCredits() / 10000 })"></h6>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="data_amount">{{ this.getTotalCost() | numFormat("0.00") }}{{ currency }}</div>
                        </div>
                      </div>
                      <div v-if="this.amountPackage > 0" class="pt-5" :key="this.amountPackage">
                        <div class="text-md">{{ $t('balance-required-description') }}</div>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-12 text-center" v-if="amountPackage > 0 && amountPackage < packs[packs.length - 1]">
                                <button v-for="pack in packs" :key="pack" type="button"
                                class="btn btn-primary" :disabled="pack < amountPackage"
                                @click="buyCredits(pack, 'stripe')" style="margin: 2px;">
                                {{ $t('add') }} {{ pack }}{{ currency }}
                                </button>
                              </div>
                              <div class="col-lg-12 text-center" v-else>
                                  <button :key="amountPackage" type="button" class="btn btn-primary"
                                   style="margin: 2px;"
                                    @click="payWithStripe">
                                    {{ $t('add') }} {{ this.amountPackage }}{{ currency }}
                                  </button>
                              </div>
                              
                              <div class="col-lg-12 text-center">
                                <small class="help text-muted">{{$t('payment-supported-methods')}}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="data" v-else>
                      <h6 v-html="$t('payment-needed', { balance: this.getCredits() / 10000 })"></h6>
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <div class="data_amount">{{ this.getTotalCost() | numFormat("0.00") }}{{ currency }}</div>
                        </div>
                      </div>
                      <button type="button" class="btn btn-primary btn-lg btn-block add-selection add process"
                        :disabled="this.isProcessing || !this.selectedProcessors.length" v-on:click="checkFile()">
                        {{ $t('process') }}
                      </button>
                      <button type="button" class="btn btn-primary btn-lg btn-block add-selection add cancel"
                        :disabled="this.isProcessing" v-on:click="cleanAll()">
                        {{ $t('cancel') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="data-count p-5" v-if="this.stepNumber == 3" id="jobSubmitted">
              <div class="text-center col-lg-12" v-html="$t('wizard-preview-job-submitted')"></div>
              <div class="text-center">
                <a v-on:click="restart()" class="btn btn-primary" href="#/wizard/file">
                  {{ $t("wizard-new-file") }}
                </a>
              </div>
            </div>

            <div class="row p-2" v-if="this.user && this.user.email && this.stepNumber < 3">
              <div class="col-lg-12 text-center p-4">
                <div>{{ $t('wizard-doubts') }}</div>
                <a :href="calendlyUrl" target="_blank" class="btn btn-primary btn-lg btn-block add-selection add">
                  {{ $t('wizard-demo') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <br><br>
          {{this.processing}} -->
        <!-- <br><br>
          {{this.selectedProcessors}}  -->
        <!-- {{this.stepNumber}} -->
        <!-- <div class="calendly-params" v-if="calendlyUrl.length > 0 && user && user.name && user.email && calendlyUrl.indexOf('?a1=') > -1" :key="calendlyUrl">
            <p><strong>a1:</strong></p>
            <pre>{{ decodeURIComponent(calendlyUrl.split('?a1=')[1].split('&')[0]).replace(/\\n\\n/g, '') }}</pre>
            <p><strong>name:</strong> {{ user.name }}</p>
            <p><strong>email:</strong> {{ user.email }}</p>
          </div> -->

        <Footer />
      </div>
    </div>
  </div>
</template>
  
<script>
  import Vue from 'vue'
  import axios from 'axios'

  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import Sidebar from '@/components/Sidebar.vue'

  import SourceUrl from '@/components/Wizards/sources/Url.vue'
  import SourceSftp from '@/components/Wizards/sources/Sftp.vue'
  import SourceS3 from '@/components/Wizards/sources/S3.vue'
  import SourceMongo from '@/components/Wizards/sources/Mongo.vue'
  import SourceMysql from '@/components/Wizards/sources/Mysql.vue'
  import SourcePostgres from '@/components/Wizards/sources/Postgres.vue'
  import ChangeSource from '@/components/Wizards/sources/ChangeSource.vue'
   
  export default {
    name: 'wizard_process',
    data() {
      return {
  
        isActive: false,
        isActiveIcon: '<',
        stepNumber: 1,
        source: "upload",
        availableSources: [
          {
            'key': 'url',
          },
          {
            'key': 's3',
          },
          {
            'key': 'sftp',
          },
          {
            'key': 'mongo',
          },
          { 
            'key': "postgres"
          },
          {
            'key': 'mysql',
          },
        ],

        maxFileUploadSize: this.getMaxFileUploadSize(),
        processing: {
          upload: {
            detected: false,
            contents: [],
            proposed: [],
            fileName: '',
            originalName: '',
            fileNeedsPreview: false
          },
        },
        files: '',
        formcheck: false,
        isError: false,
        isProcessing: false,
        option: '',
        selectedProcessors: [],
        assignedProcessors: [],
        fileContents: [],
        name: '',

        groups: [],
        group: '',

        mode: 'fields',
        totalRows: '',
        previewData: true,
        type: '',
        availableTypes: [],

        selectType: '',
        selectTool: '',
        selectColumn: '',

        processors: [],
        processorKey: '',
        processor: {},

        columnsDetected: null,
        proposedTools: null,

        showCustomWizard: false,
        detectedHeaders: [],
        processorP: {
          current: {},
          defaultParams: {},
          relatedProcessors: []
        },
        user: null,
        isAdmin: false,
        totalCols: '',
        fileAssignedCols: [],
        assignedParameters: {},
        addSelection: true,
        d: '',
        errorMessage: '',
        externalProviderAccounts: [],
        processorOrg: {},
        colNumber: -1,
  
        purchaseRequired: false,
        purchaseAmount: 0,
        amountPackage: 0,

        reason: '',

        gateways: {
          paypal: false,
          stripe: false
        },
        paypalButton: null,

        promo_code: {
          code_sent: false,
          available: false,
          requested: "",
          accepted: "",
          type: "",
          amount: "",
          originalAmount: ""
        },
        packs: [25, 50, 100, 250],
        showAllPackages: false,
        showAllProposed: false,
        maxShownTools: 3,
        currency: '€',

        sectionsShow: {
          wizard: false,
          budget: false,
        },

        calendlyUrl: 'https://calendly.com/uproc/15min',
        uniqueToolStep: 0,

        editingProcessorOutputIndex: -1,
        editingProcessorOutput: {},
      }
    },
    mounted() {
      this.enableInterceptor();
      this.checkIfSavedPaymentSession((result) => {
        if (!result) {
          this.getProcessorDetails();
          this.getUserDetails();
          //this.initPaypal();
          this.initPromoCode();
          this.getProcessingValuesMain();
        }
      });
    },
    components: {
      Header,
      Sidebar,
      Footer,

      //sources
      ChangeSource,
      SourceUrl,
      SourceSftp,
      SourceS3,
      SourceMongo,
      SourceMysql,
      SourcePostgres,
    },
    watch: {
      amountPackage(newVal, oldVal) {
        if (newVal > 0) {
          console.log(newVal, oldVal);
          if (!this.gateways.paypal) {
            //this.configurePayPal();
          }
        }
      },
    },

    methods: {
      setSource: function(key) {
        this.source = key;
      },

      enableInterceptor() {
        this.axiosInterceptor = axios.interceptors.request.use((config) => {
          this.$root.$emit('isLoading', true);
          return config
        }, (error) => {
          this.$root.$emit('isLoading', false);
          return Promise.reject(error)
        })
  
        axios.interceptors.response.use((response) => {
          this.$root.$emit('isLoading', false);
          return response
        }, function(error) {
          console.log(error);
          return Promise.reject(error)
        })
      },

      menuCollapse: function() {
        this.isActive = !this.isActive;
        this.$root.$emit('isActived', this.isActive);
  
        if (this.isActive)
          this.isActiveIcon = '>'
        else
          this.isActiveIcon = '<'
      },
  
      modeChange: function(key) {
        this.mode = key;
  
        if (this.mode == 'fields') {
          this.getFieldDetails();
        } else {
          this.getCategoryDetails();
        }
        this.groupView = 'select-option';
      },
      
      resetFields: function() {
        this.formcheck = false
      },

      editProcessorOutput: function(index) {
        this.editingProcessorOutputIndex = index;
        if (!this.selectedProcessors[index].extraOutputFields) {
          this.selectedProcessors[index].extraOutputFields = [];
        }
        this.editingProcessorOutput = this.selectedProcessors[index];
      },

      removeExtraOutputField: function(index) {
        this.editingProcessorOutput.extraOutputFields.splice(index, 1);

        if (!this.editingProcessorOutput.extraOutputFields.length) {
          this.resetEditingProcessorOutput();
        }
        this.$forceUpdate();
      },

      addExtraOutputField: function() {
        let newOutputFieldName = 'output-column-' + (this.editingProcessorOutput.extraOutputFields.length + 1);
        //sort properties alphabetically
        let properties = []
        if (this.editingProcessorOutput.output && this.editingProcessorOutput.output.length) {
          if (this.editingProcessorOutput.output[0].properties && this.editingProcessorOutput.output[0].properties.length) {
            properties = this.editingProcessorOutput.output[0].properties.map((prop) => {
              return {
                key: 'tool-' + prop.name,
                type: 'tool',
                name: prop.name
              };
            });
          } else {
            properties.push({
              key: 'tool-result',
              type: 'tool',
              name: 'result'
            });
          }
        } else {
          properties.push({
            key: 'tool-result',
            type: 'tool',
            name: 'result'
          });
        }
        properties = properties.sort((a, b) => a.name.localeCompare(b.name));

        //add file headers
        if (this.processing.headers && this.processing.headers.length) {
            properties = this.processing.headers.map((header) => {
              return {
                key: 'file-' + header,
                type: 'file',
                name: header
              };
            }).concat(properties);
        }

        //console.log(properties)

        //sort only tool properties
        this.editingProcessorOutput.finalProperties = properties;
        let filteredToolProperties = properties.filter((prop) => prop.type === 'tool');

        let firstSelectedProperty = filteredToolProperties && filteredToolProperties.length ? filteredToolProperties[0].key : 'tool-result';
        this.editingProcessorOutput.extraOutputFields.push({
          name: newOutputFieldName,
          type: 'logical',
          property: firstSelectedProperty,
          condition: 'contains-ignore-case',
          value: '',
          extractType: 'first'
        });

        this.$forceUpdate();
      },

      onExtraOutputFieldTypeChange: function(index) {
        if (this.editingProcessorOutput.extraOutputFields[index].type === 'extract') {
          this.editingProcessorOutput.extraOutputFields[index].condition = 'match';
        } else {
          this.editingProcessorOutput.extraOutputFields[index].condition = 'equals';
        }
        this.$forceUpdate();
      },

      parseUrl: function(url) {
        var parser = document.createElement('a'),
          searchObject = {},
          queries, split, i;
        // Let the browser do the work
        parser.href = url;
        // Convert query string to object
        queries = parser.search.replace(/^\?/, '').split('&');
        for (i = 0; i < queries.length; i++) {
          split = queries[i].split('=');
          searchObject[split[0]] = split[1];
        }
        return searchObject;
      },
  
      getMaxFileUploadSize: function() {
        return 70 * 1024 * 1024; //max 70Mb
      },

      getAuthConfig: function() {
        return {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };
      },
      
      getProcessingValuesMain: function(callback = null) {
        var config = this.getAuthConfig();
  
        var url = '/api/v2/processing';
        this.$http.get(url, config).then((results) => {
          this.processing = results.data;
          this.processing.assignedProcessors = [];
          this.processing.currentIndex = 0;
          this.processing.processCredits = 0;
          this.processing.neededCredits = 0;
          this.currency = this.processing.currency === 'usd' ? '$' : '€';

          if (callback) {
            callback();
          }
        });
      },
  
      getGroupsValues: function() {
        var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
        this.$http({
          method: 'GET',
          url: url,
          baseURL: '/'
        }).then((results) => {
          this.groups = results.data.groups.sort();
        });
      },
  
      uploadFile: function() {
        this.files = this.$refs.file && this.$refs.file.files && this.$refs.file.files.length ? this.$refs.file.files[0] : null;
        if (this.files) {
          this.isError = false;
          this.isProcessing = true;
          var completeCallback = this.cbFinishedUploading;
  
          var file = this.files;
          var mainFunction = this.setFileUploadProcessingValues;
  
          let formData = new FormData();
  
          formData.append('file', file);
          this.$http.post('/api/v1/' + localStorage.token + '/upload-simple', formData, this.getAuthConfig()).then((results) => {
            localStorage.setItem('uploadData', JSON.stringify(results.data));
            mainFunction(results.data, completeCallback);
          });
        }
      },
  
      setFileUploadProcessingValues: function(data, callback) {
        this.setFileUploadProcessingValuesSec(data);
        this.processing = this.getProcessingValues();
        if (callback) {
          callback(data);
        }
      },
  
      setFileUploadProcessingValuesSec: function(data) {
        this.processing.totalRows = parseInt(data.lines + 1, 10);
        this.processing.totalRowsOk = 0;
        this.processing.userCredits = parseInt(data.credits, 10);
        this.processing.totalCols = parseInt(data.totalCols, 10);
        this.processing.assignedProcessors = [];
        this.processing.allParamsNames = [];
        this.processing.areAllParamsAssigned = false;
        this.processing.assignedCols = [];
        this.processing.assignedValues = [];
        this.processing.headers = data.headers;
  
        //Set values related to upload
        this.processing.upload = {
          detected: data.detected,
          contents: data.contents,
          proposed: this.completeNameForProposedTools(data.proposed),
          fileName: data.name,
          originalName: data.originalName,
          fileNeedsPreview: this.processingFileNeedsPreview()
        };
  
        var tool = (this.$route.params.tool && this.$route.params.tool.length) ? this.$route.params.tool : "";
        if (tool && tool.length) {
          var parts = tool.split("-");
          var type = parts.shift();
          var field = parts.shift();
  
          var filteredTools = this.processorOrg.filter(function(item) {
            return item.key === tool;
          });
  
          if (filteredTools && filteredTools.length) {
            field = filteredTools[0].params[0].name;
          }
  
          var filteredGroup = this.groups.filter(function(item) {
            return item.name === field;
          });
  
          if (filteredGroup.length) {
            this.selectCategory(filteredGroup[0].translated, filteredGroup[0].name);
          }
  
          if (type.length) {
            this.changeType('type-' + type, type);
          }
        }
      },

      uploadUrl: function() {
        
      },

      uploadSftp: function() {
        
      },

      uploadS3: function() {
        
      },

      uploadMongo: function() {
        
      },

      uploadMysql: function() {
        
      },

      uploadPostgres: function() {
        
      },

      completeNameForProposedTools: function(proposedTools) {
        let filtered = proposedTools.map((tool) => {
          var filteredTools = this.processorOrg.filter(function(item) {
            return item.key === tool.key;
          });
          //console.log('filteredTools', filteredTools);
          if (filteredTools && filteredTools.length) {
            tool.name = filteredTools[0].name;
            tool.description = filteredTools[0].description;
            tool.extended_description = filteredTools[0].extended_description;
            if (tool.extended_description.indexOf(".") > -1) {
              tool.extended_description = tool.extended_description.split(".")[0];
            }

            if (this.$i18n.locale === 'en') {
              tool.description = filteredTools[0].description_en;
            }
            
            tool.max_cost = filteredTools[0].max_cost;
            tool.min_cost = filteredTools[0].min_cost;
            tool.type = filteredTools[0].type;
            tool.price = filteredTools[0].cost / 10;
            tool.path = tool.key;
            tool.path = tool.path.replace(/-/, '/').replace(/-/, '/');
            //properties params
            tool.output = [];
            if (filteredTools[0].output && filteredTools[0].output.length && filteredTools[0].output[0].properties && filteredTools[0].output[0].properties.length) {
              tool.output = filteredTools[0].output[0].properties;
            }
          }
          return tool;
        })
        
        filtered.sort((a, b) => {
          return a.description.localeCompare(b.description);
        });

        return filtered;
      },

      fixEmptyValues : function() {
        this.processing.currentIndex = this.selectedProcessors.length;
          if (!this.processing.assignedProcessors) {
            this.processing.assignedProcessors = [];
          }
          if (!this.processing.assignedProcessors[this.processing.currentIndex]) {
            this.processing.assignedProcessors[this.processing.currentIndex] = {};
          }

          if (!this.processing.assignedProcessors[this.processing.currentIndex].params) {
            this.processing.assignedProcessors[this.processing.currentIndex].params = [];
          }

          if (!this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols) {
            this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols = [];
          }

      },

      addMoreTools: function() {
        this.resetEditingProcessorOutput();
        this.scrollTo('previewData')
      },

      resetEditingProcessorOutput: function() {
        this.editingProcessorOutputIndex = -1;
        this.editingProcessorOutput = {};
      },

      addProposedTools: function(tools) {
        for (var i = 0; i < tools.length; i++) {
          this.addProposedTool(tools[i], false);
        }

        this.$toast.success(this.$t('wizard-tools-added'));
        this.scrollTo('selectedProcessors');

        this.updateCalendlyUrl();
      },

      addProposedTool: function(selectedTool, allowNotify = true) {
        var processorKey = selectedTool.key;

        // Check if the processor key already exists in selectedProcessors
        var existingProcessor = this.selectedProcessors.find(function(item) {
          return item.key === processorKey;
        });

        if (existingProcessor) {
          this.$toast.error(this.$t('wizard-tool-already-added'));
          //this.scrollTo('selectedProcessors');
          return; // If the processor already exists, do not add it again
        }

        var filteredTools = this.processorOrg.filter(function(item) {
          return item.key === processorKey;
        });

        if (filteredTools && filteredTools.length) {
          var processor = filteredTools[0];
          
          const firstMatchedParamByCol = processor.params.find((param) => {
            return selectedTool.mappedColumns.find((col) => col.field === param.name);
          });

          if (!firstMatchedParamByCol) {
            return;
          }

          this.fixEmptyValues();
          
          //console.log('firstMatchedParamByCol', firstMatchedParamByCol.name);
          this.selectCategoryByFileField(firstMatchedParamByCol.name);
          this.changeType('type-' + processor.type, processor.type);
          
          var paramNumber = 0;
          this.processor = processor;
          this.selectProcessor(processorKey);
          processor.params.forEach((param) => {
            //search if param is detected in selectedItem.mappedColumns
            var col = selectedTool.mappedColumns.find((col) => col.field === param.name);
            if (col) {
              //console.log('setAssignedCol', paramNumber, param.name, col.column + 1);
              this.setAssignedCol(paramNumber, param.name, col.column + 1);
            }
            paramNumber++;
          });
          this.enqueueProcessor();
          //show toast
          if (allowNotify) {
            this.$toast.success(this.$t('wizard-tool-added'))

            //scroll to id selectedProcessors
            this.scrollTo('selectedProcessors');
          }
        }
      },

      scrollTo: function(id) {
        this.$nextTick(() => {
          var element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        });
      },

      processingFileNeedsPreview: function() {
        return this.getFileColsNumber() > 0;
      },
  
      getFileColsNumber: function() {
        return this.processing.totalCols;
      },
  
      cbFinishedUploading: function(data) {
        this.option = 'local';
        this.resetSelectedProcessors();
        this.isProcessing = false;
        this.isUploaded = true;
        if (this.getTotalRows() === -1) {
          this.isError = true;
          this.errorMessage = data.status;
        } else if (this.getTotalRows() > 20000 && !this.user.advanced) {
          this.isError = true;
        } else {
          this.stepNumber = 2;
          this.isError = false;
          this.loadProcessingData();
          this.getProcessorDetails();
          this.updateCalendlyUrl();
        }
      },
  
      cbErrorUploading: function() {
        this.resetSelectedProcessors();
        this.isProcessing = false;
        this.isUploaded = false;
        this.isError = true;
      },
  
      loadProcessingData: function() {
        this.initGroups();
        this.totalRows = this.getTotalRows();
        this.totalCols = this.getTotalCols();
        this.fileContents = this.getFileContents();
        this.costPerCredit = this.getCostPerCredit();
        this.processing = this.getProcessingValues();
        this.detectedColumns = this.getDetectedColumns();
        this.proposedTools = this.getProposedTools();
        this.showCustomWizard = this.detectedColumns.length === 0 || this.proposedTools.length === 0;
      },
  
      resetSelectedProcessors: function() {
        this.selectedProcessors = [];
      },
  
      getTotalRows: function() {
        return this.processing.totalRows + 1;
      },
  
      getTotalCols: function() {
        return this.processing.totalCols;
      },
  
      getFileContents: function() {
        return this.processing.upload.contents;
      },
  
      getDetectedColumns: function() {
        return this.processing.upload.detected;
      },

      getProposedTools: function() {
        return this.processing.upload.proposed;
      },
  
      getCostPerCredit: function() {
        return this.processing.costPerCredit;
      },
  
      getProcessingValues: function() {
        return this.processing;
      },
  
      initGroups: function() {
        this.groups = this.mode == 'groups' ? this.getGroupsValues() : this.getFieldDetails();
      },
  
      getFieldDetails: function() {
        var fields = this.getUniqueAvailableParamNames(this.processor).map(function(item) {
          return {
            name: item,
            translated: item
          }
        });
        this.groups = fields;
        return fields;
      },
  
      getCategoryDetails: function() {
        var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
        this.$http({
          method: 'GET',
          url: url,
          baseURL: '/'
        }).then((results) => {
          this.groups = results.data.groups.sort();
        });
      },
  
      getFieldDetail: function() {
        return this.getUniqueAvailableParamNames(this.processor).map(function(item) {
          return {
            name: item,
            translated: item
          }
        });
      },
  
      getUniqueAvailableParamNames: function(items) {
        var params = [];
        if (items && items.forEach) {
          items.forEach(function(p) {
            var paramNames = p.params.map(function(p) {
              return p.name.replace(/[0-9]+/g, '');
            });
            var firstParam = paramNames.length > 0 ? paramNames[0] : "";
            if (params.indexOf(firstParam) === -1) {
              params.push(firstParam);
            }
          });
        }
        return params.sort();
      },
  
      getProcessorDetails: function(callback = null) {
        var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
        this.loading = true;
        this.$http({
          method: 'GET',
          url: url,
          baseURL: '/'
        }).then((results) => {
          this.processor = results.data.processors;
          this.processorOrg = results.data.processors;
          this.groups = this.getUniqueAvailableParamNames(this.processor).map(function(item) {
            return {
              name: item,
              translated: item
            }
          });

          if (callback) {
            callback();
          }
        });
      },


      selectCategoryByFileField: function(field) {
        this.selectCategory(field, field);
      },
  
      selectCategory: function(translation, name) {
        this.resetCustomWizard();
        this.processor = this.processorOrg;

        this.selectType = '';
        this.selectTool = '';
        this.selectColumn = '';
        this.processors = [];

        this.group = name;
        this.type = '';
        this.filterTypes();
      },
  
      filterTypes: function() {
        this.availableTypes = [];
        if (this.group.length) {
          var fn = this.mode == 'groups' ? this.getFilteredProcessorsWizard : this.getFilteredProcessorsByParamNameWizard;
          if (fn(this.group, 'normalize', this.processing).length > 0) {
            this.availableTypes.push('normalize');
          }
  
          if (fn(this.group, 'check', this.processing).length > 0) {
            this.availableTypes.push('check');
          }
  
          if (fn(this.group, 'get', this.processing).length > 0) {
            this.availableTypes.push('get');
          }
  
          if (fn(this.group, 'send', this.processing).length > 0) {
            this.availableTypes.push('send');
          }
        }
      },
  
      getFilteredProcessorsWizard: function(group, type, processing) {
        var finalProcessors = [];
        if (group && group.length && type && type.length) {
          this.processor.forEach(function(processor) {
            if (processor.groups.indexOf(group) !== -1 && processor.type === type) {
              processor.price = processor.cost * processing.costPerCredit;
              finalProcessors.push(processor);
            }
          });
          return finalProcessors.sort(function(item1, item2) {
            return item1.description.localeCompare(item2.description);
          });
  
        } else {
          finalProcessors = this.processor;
        }
  
        return finalProcessors;
      },
  
      getFilteredProcessorsByParamNameWizard: function(param, type, processing) {
        var finalProcessors = [];
  
        if (param && param.length && type && type.length) {
          if (this.processor && this.processor.forEach) {
            this.processor.forEach(function(processor) {
              var found = processor.params.filter(function(p) {
                return p.name.indexOf(param) !== -1;
              });
              if (found.length && processor.type === type) {
  
                processor.price = processor.cost * processing.costPerCredit;
                finalProcessors.push(processor);
              }
            });
            finalProcessors.sort(function(item1, item2) {
              return item1.description.localeCompare(item2.description);
            });
          }
        } else {
          finalProcessors = this.processor;
        }
  
        return finalProcessors;
      },
  
      changeType: function(translation, type) {
        this.selectTool = '';
        this.selectColumn = '';

        this.processor = this.processorOrg;
  
        if (this.group.length && type.length) {
          var fn = this.mode == 'groups' ? this.getFilteredProcessorsWizard : this.getFilteredProcessorsByParamNameWizard;
          this.processors = fn(this.group, type, this.processing);
  
        }
      },

      autoAssignColumns: function() {
        const localProcessor = this.processor;
        const detectedColumns = this.detectedColumns;
        const that = this;
        that.assignedParameters = {};
        localProcessor.params.forEach(function(param, index) {
          var found = detectedColumns.find(function(col) {
            //console.log(col.field.toLowerCase(), param.name.toLowerCase());
            return col.field.toLowerCase().indexOf(param.name.toLowerCase()) !== -1;
          });
          if (found) {
            that.assignedParameters[param.name] = found.column;
            that.setAssignedCol(index, param.name, index + 1);
          } else {
            that.assignedParameters[param.name] = -1;
          }
        });
      },
  
      selectProcessor: function(pKey, autoAssignColumns = false) {
        this.processorKey = pKey;
        var processor = this.getProcessorByKey();
        if (processor) {
          processor.normalized = false;
          this.processor = this.addProcessor(processor);
          this.loadProcessorData();
          this.selectColumn = '';

          if (autoAssignColumns) {
            this.autoAssignColumns();
          }

          this.updateCalendlyUrl();
        }
      },
  
      getProcessorByKey: function() {
        var processor = null;
        if (this.processorKey && this.processorKey.length) {
          var processorKey = this.processorKey;
          var selected = this.processorOrg.filter(function(item) {
            return item.key === processorKey;
          });
          if (selected.length) {
            processor = JSON.parse(JSON.stringify(selected[0]));
          }
        }
        return processor;
      },
  
      addProcessor: function(processor) {
        this.processing.assignedProcessors.push(processor);
        this.processing.currentIndex = this.processing.assignedProcessors.length - 1;
        this.setCurrentProcessorValues();

        return processor;
      },
  
      setCurrentProcessorValues: function() {
        if (this.processing.assignedProcessors.length) {
          var curItem = this.processing.assignedProcessors[this.processing.currentIndex];
          this.processing.allParamsNames = this.getAvailableParamNames(curItem);
          curItem.fileAssignedCols = curItem.fileAssignedCols ? curItem.fileAssignedCols : Array(this.processing.totalCols).join(".").split(".");
        } else {
          this.processing.allParamsNames = [];
        }
        this.checkAllParamsAssigned();
        this.completeProcessingValues();
      },
  
      getAvailableParamNames: function(currentProcessor) {
        var proc = currentProcessor && Object.keys(currentProcessor).length ? currentProcessor : this.getCurrentSelectedProcessor();
        var paramsNames = [];
        if (proc.params) {
          proc.params.forEach(function(param) {
            paramsNames.push(param.name);
          });
        }
        return paramsNames;
      },
  
      checkAllParamsAssigned: function() {
        var allAssigned = true;
        //Delete other params with same name, not current
        if (this.processing.allParamsNames && this.processing.allParamsNames.length &&
          this.processing.assignedProcessors && this.processing.assignedProcessors.length) {
          var fileAssignedCols = this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols;
          var self = this;
          this.processing.allParamsNames.forEach(function(paramName) {
            var found = self.searchInArray(fileAssignedCols, paramName);
            if (!found) {
              allAssigned = false;
            }
          });
        }
  
        this.processing.areAllParamsAssigned = allAssigned;
        //return this.areAllParamsAssigned();
        return this.processing.areAllParamsAssigned;
      },
  
      searchInArray: function(arr, string) {
        var matches = arr.filter(function(str) {
          var pos = str.indexOf(string);
          return pos !== -1;
        });
  
        return matches.length > 0;
      },
  
      areAllParamsAssigned: function() {
        return this.processing.areAllParamsAssigned;
      },
  
      completeProcessingValues: function() {
        this.setTableData();
        this.calculateProcessorsCost();
        // console.log(this.processing.processCredits)
        this.processing.userCredits = this.getCredits();
        this.processing.neededCredits = (this.processing.userCredits - this.processing.processCredits < 0) ? this.processing.processCredits - this.processing.userCredits : 0;
        // console.log(this.processing.processCredits)
        this.processing.neededCredits = this.processing.neededCredits && this.processing.neededCredits < this.processing.minimumCredits ? this.processing.minimumCredits : this.processing.neededCredits;
        // console.log(this.processing.processCredits)
        this.processing.neededCost = this.processing.neededCredits * this.processing.costPerCredit;
        this.processing.neededCostWithVat = this.processing.neededCost * (1 + (this.processing.vat / 100));

        this.setAmountPackage();
      },

      setAmountPackage : function() {
        this.$nextTick(() => {
          this.amountPackage = parseInt(this.assignAmountPackage(), 10);
          console.log(this.amountPackage);
        });
      },

      getRequiredCredits: function() {
        return this.getTotalCost() / this.processing.costPerCredit;
      },

      assignAmountPackage: function() {
        let assignedPackage = 0;
        const requiredCredits = this.getRequiredCredits() - this.processing.userCredits;
        let requiredAmount = requiredCredits * this.processing.costPerCredit;
        //substract current user credits
        //add vat
        //requiredAmount = requiredAmount * this.processing.vatMultiplier;
        if (requiredAmount > 0 && requiredAmount <= 25) {
          assignedPackage = 25;
        } else if (requiredAmount > 25 && requiredAmount <= 50) {
          assignedPackage = 50;
        } else if (requiredAmount > 50 && requiredAmount <= 100) {
          assignedPackage = 100;
        } else if (requiredAmount > 100 && requiredAmount <= 250) {
          assignedPackage = 250;
        } else {
          assignedPackage = requiredAmount;
        }

        console.log("Required credits: ", requiredCredits, "Required amount: ", requiredAmount, "Assigned package: ", assignedPackage);

        return assignedPackage;
      },
  
      setTableData: function() {
        this.processing.dataTable = {
          data: this.prepareTableData()
        };
      },
  
      prepareTableData: function() {
        var col = 0;
  
        var data = [];
        if (this.processorP.current.params) {
          var self = this;
          this.processorP.current.params.forEach(function(param) {
            if (param.value && param.value.length) {
              var value = self.formatParamValue(param.value);
              var row = 0;
              self.splitRow(value).forEach(function(cellData) {
                if (self.isArrayUndefined(data[row])) {
                  data[row] = [];
                }
                if (self.isArrayUndefined(data[row][col])) {
                  data[row][col] = [];
                }
                data[row][col].push(cellData);
                row++;
              });
            }
            col++;
          });
        }
  
  
        return data;
      },
  
      formatParamValue: function(value) {
        return value.replace(/(\r\n|\n|\r)/gm, ";");
      },
  
      splitRow: function(str) {
        return this.formatParamValue(str).split(";");
      },
  
      isArrayUndefined: function(arr) {
        return (typeof arr === "undefined" || !(arr instanceof Array));
      },
  
      calculateProcessorsCost: function() {
        this.processing.processCredits = 0;
        this.processing.processCost = 0;
        //If assigned processors, calculate value
        if (this.processing.assignedProcessors.length) {
          var that = this;
          this.processing.assignedProcessors.forEach(function(processor) {
            if (processor && processor.max_cost) {
              that.processing.processCredits += (that.processing.totalRows) * processor.max_cost;
              // console.log("calculateProcessorsCost: ", that.processing.processCredits)
            }
          });
        } else {
          this.processing.processCredits = (this.processing.totalRows) * this.getCurrentSelectedProcessor().max_cost;
        }
        this.processing.processCost = this.processing.processCredits * this.processing.costPerCredit;
        // console.log("calculateProcessorsCost: ", this.processing.processCredits)
      },
  
      loadProcessorData: function() {
        this.allParamsNames = this.getCurrentParamNames();
        this.areAllParamsAssigned = this.checkAllParamsAssigned();
        this.fileAssignedCols = this.getFileAssignedCols();
        this.totalRows = this.getTotalRows();
        this.showJobScheduled = this.isJobScheduled();
      },
  
      getCurrentParamNames: function() {
        return this.processing.allParamsNames;
      },
  
      getFileAssignedCols: function() {
        return this.processing.currentIndex > -1 && this.processing.currentIndex < this.processing.assignedProcessors.length ?
          this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols : new Array(this.processing.totalCols);
      },
  
      isJobScheduled: function() {
        return this.processing.receivedData !== undefined &&
          this.processing.receivedData !== null &&
          this.processing.receivedData.status === 1;
      },

      updateCalendlyUrl: function() {
        this.calendlyUrl = 'https://calendly.com/uproc/15min'
        let a1 = ''
        if (this.details) {
          //a1 += encodeURIComponent('I want to ' + this.details + '.\n\n');
          a1 += encodeURIComponent(this.$t('wizard-calendly-details', { details: this.details })) + '\n\n';
        }

        //a1 += "I need help with wizard on step " + this.stepNumber + ".\n"
        a1 += this.$t('wizard-calendly-need-help', { step: this.stepNumber }) + ".\n\n";
        
        if (this.stepNumber >= 2) {
          //a1 += "I have uploaded a file with " + this.getTotalRows() + " rows and " + this.getTotalCols() + " columns.\n"
          a1 += this.$t('wizard-calendly-file-uploaded', { rows: this.getTotalRows(), cols: this.getTotalCols() }) + ".\n\n";
        }

        if (this.detectedColumns && this.detectedColumns.length) {
          //a1 += "Columns detected: " + this.detectedColumns.map((field) => field.field + " (COL" + field.column + ")").join(", ") + ".\n"
          a1 += this.$t('wizard-calendly-columns-detected', { columns: this.detectedColumns.map((field) => field.field + " (COL" + field.column + ")").join(", ") }) + ".\n\n";
        }

        if (this.proposedTools && this.proposedTools.length) {
          //a1 += "Proposed tools: " + this.proposedTools.map((tool) => tool.key).join(", ") + ".\n\n"
          a1 += this.$t('wizard-calendly-proposed-tools', { tools: this.proposedTools.map((tool) => tool.key).join(", ") }) + ".\n\n";
        }

        if (this.selectedProcessors && this.selectedProcessors.length) {
          //a1 += "I have selected the next tools: " + this.selectedProcessors.map((tool) => tool.key).join(", ") + ".\n"
          a1 += this.$t('wizard-calendly-selected-tools', { tools: this.selectedProcessors.map((tool) => tool.key).join(", ") }) + ".\n";
        }

        this.calendlyUrl += '?a1=' + encodeURIComponent(a1);

        if (this.user.name) {
          this.calendlyUrl += '&name=' + encodeURIComponent(this.user.name);
        }
        if (this.user.email) {
          this.calendlyUrl += '&email=' + encodeURIComponent(this.user.email);
        }
      },
  
      getUserDetails: function(callback = null) {
        var url = '/api/v2/profile';
        this.$http.get(url, this.getAuthConfig()).then((results) => {
          this.user = results.data;
          this.details = this.user && this.user.signup_reason ? this.user.signup_reason : ""; 
          this.isAdmin = this.user && this.user.role && this.user.role === 'admin';
          this.updateCalendlyUrl();
          // if (this.$store.getters.needs_complete) {
          //   alert(this.$t('profile-complete'))
          //   this.$router.push('/settings/profile')
          // }
          if (callback) {
            callback();
          }
        });
      },
  
      getUserDetailsExternalAPI: function() {
        var url = '/api/v2/profile';
        this.$http.get(url, this.getAuthConfig()).then((results) => {
          this.user = results.data;
          this.loadExternalProviderAccounts();
        });
      },
  
      getCredits: function() {
        return this.user && this.user.credits !== undefined ? this.user.credits : 0;
      },

      makeRange: function(input) {
        var nums = Array.apply(null, Array(input)).map(function(x, i) {
          return i;
        });
        return nums;
      },
  
      setAssignedCol: function(colIndex, paramName, item) {
        this.fileAssignedCols = colIndex;
        this.unsetAssignedCol(paramName);
        if (item !== undefined) {
          this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols[item - 1] = paramName;
        }
  
        var mustBeDisabled = false;
        var that = this;
        this.processor.params.forEach(function(p) {
          if (!mustBeDisabled && p.required && that.processing.assignedProcessors[that.processing.currentIndex].fileAssignedCols.indexOf(p.name) === -1) {
            mustBeDisabled = true
          }
        });
        this.addSelection = mustBeDisabled;
      },
  
      unsetAssignedCol: function(name) {
        for (var i = 0; i < this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols.length; i++) {
          if (this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols[i] === name) {
            this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols[i] = "";
          }
        }
      },
  
      enqueueProcessor: function() {
        this.enqueueProcessorPush();
        this.resetProcessorValues();
        this.group = "";
        this.type = "";
        this.processorKey = "";
        this.processor = {};
        this.groupView = '';

        this.selectType = '';
        this.selectTool = '';
        this.selectColumn = '';

        this.addSelection = true;

        this.$nextTick(() => {
          this.uniqueToolStep = new Date().getTime();
        });
      },
      
  
      enqueueProcessorPush: function() {
        var currentProcessor = this.getCurrentSelectedProcessor();
        this.resetSelectedProcessorsenqueue();
        this.selectedProcessors.push(currentProcessor);
      },
  
      getCurrentSelectedProcessor: function() {
        return this.processing.assignedProcessors.length ? this.processing.assignedProcessors[this.processing.currentIndex] : {};
      },
  
      resetSelectedProcessorsenqueue: function() {
        if (this.processing.assignedProcessors) {
          this.processing.assignedProcessors = [];
          this.processing.assignedCols = [];
        }
      },
  
      getProcessorCost: function(processor) {
        return processor.max_cost * this.processing.costPerCredit;
      },
  
      deleteProcessor: function(index) {
        this.selectedProcessors.splice(index, 1);
        this.$toast.success(this.$t('wizard-tool-removed'));

        if (this.selectedProcessors.length === 0) {
          this.stepNumber = 2;
          this.scrollTo('previewData');
        }

        this.resetCustomWizard();
        this.setAmountPackage();

        this.resetEditingProcessorOutput();
      },

      resetCustomWizard: function() {
        this.group = "";
        this.selectType = '';
        this.selectTool = '';
        this.selectColumn = '';
        this.addSelection = true;
      },
  
      resetProcessorValues: function() {
        this.processor = {};
        this.group = "";
        this.type = "";
        this.processorKey = "";
        this.fileAssignedCols = [];
        this.areAllParamsAssigned = false;
        this.resetProcessors();
      },
  
      resetProcessors: function() {
        if (this.processing.assignedProcessors) {
          this.processing.assignedProcessors = [];
          this.processing.assignedCols = [];
        }
      },
  
      cleanAll: function() {
        if (confirm(this.$t('wizard-discard'))) {
          this.restart();
          this.$toast.success(this.$t('wizard-discard-success'));
        }
      },

      restart: function() {
        this.resetSelectedProcessors();
        this.stepNumber = 1;
        this.clearPaymentSessionFromStorage();
        this.resetWizard();
        this.scrollTo('top');
      },
  
      resetWizard: function() {
        this.initValues();
      },
  
      initValues: function() {
        this.option = '';
        this.stepNumber = 1;
        this.isProcessing = false;
        this.isUploaded = false;
        this.selectedProcessors = [];
        this.resetUploadValues();
        this.resetGroupValues();
        this.resetProcessorValues();
        this.maxFileUploadSize = this.getMaxFileUploadSize();
        this.getProcessorDetails();
      },
  
      resetUploadValues: function() {
        this.isUploaded = false;
      },
  
      resetGroupValues: function() {
        this.groupProcessors = [];
        this.groups = [];
        this.group = "";
      },
  
      getTotalCost: function() {
        var total = 0;
        for (var i = 0; i < this.selectedProcessors.length; i++) {
          total += this.getTotalCostPerRow(this.selectedProcessors[i]);
        }
  
        return total;
      },
  
      getTotalCostPerRow: function(processor) {
        return processor.normalized ? this.getProcessedCostPerRow(processor) + this.getNormalizedCostPerRow() : this.getProcessedCostPerRow(processor);
      },
  
      getProcessedCostPerRow: function(processor) {
        return Math.ceil(processor.max_cost * (this.processing.totalRows) * this.processing.costPerCredit * 100) / 100;
      },
  
      getMinimumRequired: function() {
        var amount = parseInt(Math.ceil((this.processing.processCredits - this.getCredits()) / 10000), 10);
        if (amount < 25) {
          amount = 25;
        }
        return amount;
      },
  
      checkFile: function() {
        if (this.isUploaded) {
          this.updateProcessingValues();
          if (this.areCreditsNeeded()) {
            this.purchaseRequired = true
            this.purchaseAmount = this.getMinimumRequired();
          } else if (!this.isJobScheduled()) {
            this.isProcessing = true;
            this.processUpload();
          }
        }
      },
  
      cbCheckSuccess: function() {
        this.initValues();
        this.stepNumber = 3;
        this.scrollTo('jobSubmitted');
        this.clearPaymentSessionFromStorage();
        this.resetEditingProcessorOutput();
      },
  
      updateProcessingValues: function() {
        this.setAssignedProcessors(this.selectedProcessors);
      },
  
      setAssignedProcessors: function(processors) {
        this.processing.assignedProcessors = processors;
        this.processing.assignedCols = this.getAssignedColsFromProcessors();
        this.completeProcessingValues();
      },
  
      getAssignedColsFromProcessors: function() {
        var assignedCols = [];
        for (var i = 0, len = this.processing.assignedProcessors.length; i < len; i++) {
          var fileAssignedCols = this.processing.assignedProcessors[i].fileAssignedCols;
          var assignedSubcols = [];
          for (var j = 0, len2 = fileAssignedCols.length; j < len2; j++) {
            var paramName = (fileAssignedCols[j]) ? fileAssignedCols[j] : "";
            assignedSubcols.push(paramName);
          }
          assignedCols.push(assignedSubcols.join(","));
        }
        return assignedCols.join(";");
      },

      enableCustomWizard: function(status) {
        this.showCustomWizard = status;
        this.scrollTo('previewData');
      },
  
      hasCards: function() {
        return this.user && this.user.cards && this.user.cards.length;
      },
  
      hasAutoCredits: function() {
        return false;//this.user && this.user.autocredits !== null ? this.user.autocredits : false;
      },
  
      getEmail: function() {
        return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
      },

      areCreditsNeeded: function() {
        //console.log("Processing credits: ", this.processing.processCredits, "User credits: ", this.getCredits());
        //return this.processing.processCredits > this.getCredits()
        return this.getRequiredCredits() > this.user.credits;
      },

      processUpload: function() {
        var finalProcessorName = this.getAssignedProcessors() || this.getCurrentProcessorName();
        var finalAssignedCols = this.getAssignedColsFromProcessors() || this.getAssignedCols();
        var finalAssignedValues = this.getAssignedValuesFromProcessors();
        var finalAssignedNormalized = this.getAssignedNormalized();
  
        this.processUploadedFile(this.processing.upload.fileName, this.processing.totalRows, 0, this.processing.totalCols, finalProcessorName, finalAssignedCols, finalAssignedValues, finalAssignedNormalized);
      },
  
      getAssignedProcessors: function() {
        var assignedProcessors = this.processing.assignedProcessors;
        var assignedCols = [];
        if (assignedProcessors) {
          for (var i = 0; i < assignedProcessors.length; i++) {
            var assignedProcessor = assignedProcessors[i];
            var paramName = assignedProcessor.type + '-' + assignedProcessor.module + '-' + assignedProcessor.name;
            assignedCols.push(paramName);
          }
        }
        return assignedProcessors ? assignedCols.join(";") : null;
      },
  
      getCurrentProcessorName: function(currentProcessor) {
        currentProcessor = currentProcessor || this.getCurrentSelectedProcessor();
        return currentProcessor.type + '-' + currentProcessor.module + '-' + currentProcessor.name;
      },
  
      getAssignedCols: function() {
        return this.processing.assignedCols;
      },
  
      filterAssignedValues: function(arr, paramName) {
        return arr.filter(function(item) {
          return item.name === paramName;
        });
      },
  
      getAssignedValuesFromProcessors: function() {
        var assignedCols = [];
        for (var i = 0, len = this.processing.assignedProcessors.length; i < len; i++) {
          var fileAssignedValues = this.processing.assignedProcessors[i].fileAssignedValues;
          if (fileAssignedValues && fileAssignedValues.length) {
            var processor = this.processing.assignedProcessors[i];
            for (var j = 0, len2 = processor.params.length; j < len2; j++) {
              var paramName = processor.params[j].name;
              var paramFixedValue = this.filterAssignedValues(fileAssignedValues, paramName);
              if (paramFixedValue && paramFixedValue.length) {
                assignedCols.push(paramFixedValue[0].name + "=" + paramFixedValue[0].value);
              }
            }
          }
        }
        return assignedCols.length ? assignedCols.join(";") : "";
      },
  
      getAssignedNormalized: function() {
        var assignedProcessors = this.processing.assignedProcessors;
        var assignedNormalizeds = [];
        if (assignedProcessors) {
          for (var i = 0; i < assignedProcessors.length; i++) {
            assignedNormalizeds.push(assignedProcessors[i].normalized);
          }
        }
        return assignedNormalizeds ? assignedNormalizeds.join(";") : null;
      },

      processUploadedFileOld: function(fileName, totalRows, startRow, totalCols, processorName, assignedCols, fixedValues, assignedNormalized) {
       fixedValues = fixedValues.length ? fixedValues : " ";
        var url = '/api/v2/file/json/' + fileName + "/" + totalRows + '/' +
          totalCols + '/' + processorName + '/' + assignedCols + '/' + assignedNormalized + '/' + fixedValues + '/' + startRow;
  
        this.$http.get(url, this.getAuthConfig()).then(() => {
          this.cbCheckSuccess();
        });
      },

      getDetails: function() {
        var finalProcessors = [];
        for (var i = 0, len = this.processing.assignedProcessors.length; i < len; i++) {
          var processor = this.processing.assignedProcessors[i];
          var fileAssignedCols = processor.fileAssignedCols;
          var assignedCols = [];
          for (var j = 0, len2 = fileAssignedCols.length; j < len2; j++) {
            var paramName = (fileAssignedCols[j]) ? fileAssignedCols[j] : "";
            assignedCols.push(paramName);
          }

          var assignedValues = [];
          if (processor.fileAssignedValues && processor.fileAssignedValues.length) {
            for (var k = 0, len3 = processor.fileAssignedValues.length; k < len3; k++) {
              var paramFixedValue = processor.fileAssignedValues[j];
              assignedValues.push({
                name: paramFixedValue.name,
                value: paramFixedValue.value
              });
            }
          }
          
          var entry = {
            name: processor.type + '-' + processor.module + '-' + processor.name,
            assignedCols: assignedCols,
            assignedValues: assignedValues,
            normalized: processor.normalized,
            extraOutputFields: processor.extraOutputFields
          }
          console.log(entry);
          finalProcessors.push(entry);
        }
        return finalProcessors;
      },

      processUploadedFile: function() {
        var finalProcessorName = this.getAssignedProcessors() || this.getCurrentProcessorName();
        var finalAssignedCols = this.getAssignedColsFromProcessors() || this.getAssignedCols();
        var finalAssignedValues = this.getAssignedValuesFromProcessors();
        var finalAssignedNormalized = this.getAssignedNormalized();
  
        var url = '/api/v2/stream'
        var data = {
          type: 'file',
          fileName: this.processing.upload.fileName,

          totalRows: this.processing.totalRows,
          totalCols: this.processing.totalCols,
          firstRow: 0,

          //old format
          assignedProcessors: finalProcessorName,
          assignedCols: finalAssignedCols,
          fixedValues: finalAssignedValues,
          normalized: finalAssignedNormalized,

          //new format
          processingSetup: this.getDetails(),
        }
        this.$http.post(url, data, this.getAuthConfig()).then(() => {
          this.cbCheckSuccess();
        });
      }, 
  
      clearSource: function() {
        this.errorMessage = '';
        this.stepNumber = 1;
      },
  
      loginApp: function() {
        var that = this;
        var openPopup = function(w, h) {
  
          var top = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
          var left = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
          var win = window.open('/api/v2/auth/mailchimp', "popup", "width=" + w + ",height=" + h + ",top=" + top + ",left=" + left);
          var popupTick = setInterval(function() {
            if (win.closed) {
              clearInterval(popupTick);
              that.getUserDetailsExternalAPI();
            }
          }, 500);
        };
        openPopup(750, 500);
      },

      checkPromoCode() {
        this.promo_code.code_sent = false
        var url = '/api/v2/promo_code/' + this.promo_code.requested;
        this.$http.get(url, this.getAuthConfig()).then((results) => {
          this.$nextTick(() => {
            this.promo_code.code_sent = true
            this.promo_code.available = results.data.available;
            if (this.promo_code.available) {
              this.promo_code.accepted = this.promo_code.requested;
              this.promo_code.requested = "";
              this.promo_code.type = results.data.type;
              this.promo_code.originalAmount = results.data.amount;
              this.recalculatePromo();
            }
            this.$forceUpdate();
          });
        });
      },

      recalculatePromo: function() {
        if (this.promo_code.accepted.length) {
          if (this.promo_code.type === 'amount') {
            this.promo_code.amount = this.promo_code.originalAmount;
          } else {
            this.promo_code.amount = parseInt((((parseInt(this.promo_code.originalAmount, 10) / 100) * this.cost.base) * 1000), 10) / 1000;
          }
        }
      },
    
      initPromoCode() {
        this.promo_code = {
          code_sent: false,
          available: false,
          requested: "",
          accepted: "",
          type: "",
          amount: "",
          originalAmount: ""
        }
      },

      buyCredits: function(amount, method) {
        this.loading = true;
        var url = '/api/v2/buy';
        if (method === 'stripe') {
          url = url = '/api/v2/purchase_session';
        }

        var data = {
          method: method,
          amount: (parseInt(amount * 100, 10) / 100),
          promo: this.promo_code.accepted,
          source: 'wizard'
        }
        this.$http.post(url, data, this.getAuthConfig()).then((results) => {
          this.loading = false;
          var res = results.data;
          this.oldMethod = this.method;
          this.method = "";
          this.checkout = false;
          this.initPromoCode();
          this.savePaymentSessionToStorage(res.session);
          if (method !== 'stripe') {
            this.purchaseDone = true;
            this.purchaseResult = res;
          } else {
            this.enableMethod(method, res);
          }
        });
      },

      checkIfSavedPaymentSession(cb) {
        const query = this.$route.query;
        this.restorePaymentSessionFromStorage((result) => {
          console.log('restored session: ', result);
          if (result) {
            console.log('restored session');
            if (this.checkoutSession && this.checkoutSession.length) {
              //returned url contains ?payment=success if payment was successful
              if (query.payment) {
                //console.log(query.payment);
                if (query.payment === 'success') {
                  this.confirmPurchase();
                } else {
                  this.cancelPurchase();
                }
              }
            }
          } else {
            this.redirectToWizard();
          }
          cb(result);
        });
      },

      confirmPurchase() {
        var url = '/api/v2/confirm_purchase';
        var data = {
          session: this.checkoutSession
        }
        this.$http.post(url, data, this.getAuthConfig()).then((results) => {
          this.purchaseDone = true;
          this.purchaseResult = results.data.success
          if (this.purchaseResult) {
            this.getUserDetails();
            this.$toast.success(this.$t('payment-success'));
            this.redirectToWizard();
          } else {
            this.$toast.error(this.$t('payment-error'));
            this.redirectToWizard();
          }
        });
      },

      cancelPurchase: function() {
        this.loading = true;
        var url = '/api/v2/cancel_purchase';
        const data = {
          session: this.checkoutSession
        }
        //console.log(data);
        this.$http.post(url, data, this.getAuthConfig()).then(() => {
          this.loading = false;
          this.$emit('isLoading', false);
          this.clearPaymentSessionFromStorage();
          this.$toast.error(this.$t('payment-cancel'));
          this.redirectToWizard();
        });
      },

      redirectToWizard: function(section = 'file') {
        if (this.$route.fullPath !== `/wizard/${section}`) {
          this.$router.push(`/wizard/${section}`);
        }
      },

      savePaymentSessionToStorage : function(checkoutSession) {
        localStorage.checkoutSession = JSON.stringify(checkoutSession);
        localStorage.promoCode = JSON.stringify(this.promo_code);
        localStorage.processing = JSON.stringify(this.processing);
        localStorage.selectedProcessors = JSON.stringify(this.selectedProcessors);
      }, 

      restorePaymentSessionFromStorage : function(callback) {
        try {
          this.checkoutSession = JSON.parse(localStorage.checkoutSession);
          if (this.checkoutSession && this.checkoutSession.length) {
            this.stepNumber = 2;
            this.promo_code = JSON.parse(localStorage.promoCode);
            this.processing = JSON.parse(localStorage.processing);
            this.name = this.processing.upload.fileName;
            this.selectedProcessors = JSON.parse(localStorage.selectedProcessors);

            //launch like if a new upload is done
            const that = this;
            this.getUserDetails(function() {
              that.getProcessorDetails(function() {
                that.proposedTools = that.getProposedTools();
                
                that.fileContents = that.getFileContents();
                that.totalRows = that.processing.totalRows;
                that.totalCols = that.processing.totalCols;
                that.isUploaded = true;

                that.processing.assignedProcessors = [];
                that.processing.currentIndex = -1;
                that.proposedTools = that.getProposedTools();
                that.updateProcessingValues();
                that.completeProcessingValues();
                
                return callback(true);
              });
            });
          } else {
            return callback(false);
          }
        } catch (e) {
          return callback(false);
        }
      },

      clearPaymentSessionFromStorage : function() {
        localStorage.removeItem('checkoutSession');
        localStorage.removeItem('promoCode');
        localStorage.removeItem('selectedProcessors');
        localStorage.removeItem('processing');
      },

      enableMethod: function(method, data) {
        if (method === 'stripe') {
          console.log(data)
          //data.session_url = ""
          if (data.session_url) {
            window.location.href = data.session_url;
          } else {
            this.$toast.error(this.$t('payment-error'));
          }
        }
      },

      payWithStripe: function() {
        //const amountWithVat = this.amountPackage * this.processing.vatMultiplier;
        this.buyCredits(this.amountPackage, 'stripe')
      },

      includeScript( URL, callback ){
          let documentTag = document, tag = 'script',
              object = documentTag.createElement(tag),
              scriptTag = documentTag.getElementsByTagName(tag)[0];
          object.src = URL;
          if (callback) {
            object.addEventListener('load', function (e) { callback(null, e); }, false);
          }
          scriptTag.parentNode.insertBefore(object, scriptTag);
      },

      initPaypal() {
        if (window && !window.paypal) {
          var locale = 'en_US';
          if (this.$i18n.locale === 'es') {
            locale = 'es_ES'
          }

          var paypalUrl = 'https://www.paypal.com/sdk/js?currency=EUR&disable-funding=sofort,credit,card&locale=' + locale + '&client-id=';
          paypalUrl += (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development2prod' ? 'ARW8ghcommWpJ67MgXSUGLTLq152aA-BxZqrZ2QD8BntwhAUurlCwhv2bLwIdopWx33O0RhoE8PbBVmY' : 'AbJQ8oz_yiu3y4qf7TgIaKJCOyqBRtKfZY5CsQhMf67hs1DSi1nMiP-0LfMc0v4E21YSGb8MCtvBqzYB');

          this.includeScript(paypalUrl, function(){
            console.log('Paypal loaded');
            //this.configurePaypal();
          }.bind(this) );
        }
      },

      configurePaypal() {
        var that = this;

        var purchaseUrl = this.$apiBase + '/api/v2/purchase_paypal';
        var config = this.getAuthConfig();
        var amount = (parseInt(this.amountPackage * 100, 10) / 100);
        var amountVat = (parseInt(this.amountPackage * this.processing.vatMultiplier * 100, 10) / 100) + '';
        var promo = this.promo_code.accepted;
        var http = this.$http;
        var getUserDetailsFn = this.getUserDetails;

        if (window.paypal && window.paypal.Buttons) {
          that.gateways.paypal = true;
          that.paypalButton = window.paypal.Buttons({
            env: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox',
            style: {
              layout:  'vertical',
              color:   'blue',
              shape:   'rect',
              label:   'paypal',
              size:    'large'
            },
            createOrder: function(data, actions) {
              that.gateways.paypal = true;
              // This function sets up the details of the transaction, including the amount and line item details.
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: (parseInt(amountVat * 100, 10) / 100)
                  }
                }]
              });
            },
            onApprove: function(data, actions) {
              that.gateways.paypal = true;
              that.loading = true;
              // This function captures the funds from the transaction.
              return actions.order.capture().then(function(details) {
                // This function shows a transaction success message to your buyer.
                var data = {
                  purchase: details,
                  promo: promo,
                  amount: (parseInt(amount * 100, 10) / 100),
                  amountVat: (parseInt(amountVat * 100, 10) / 100),
                  env: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox'
                };
                http.post(purchaseUrl, data, config).then((results) => {
                  that.loading = false;
                  that.oldMethod = that.method;
                  //reload user
                  that.purchaseResult = results.data.result;
                  that.purchaseDone = true;
                  getUserDetailsFn();
                  if (results.data.result) {
                    that.$router.push('/purchase/result/success')
                  } else {
                  that.$router.push('/purchase/result/cancel')
                  }
                });
              });
            },
            onError: function (e) {
              console.log(e);
              that.gateways.paypal = false;
              Vue.nextTick(function () {
                that.configurePaypal();
              });
            }
          }).render('#paypal-button-container');
        }
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
@import '../../../assets/css/wizards.css';
@import '../../../assets/css/global.css';
  </style>
  
