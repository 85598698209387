<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>


      <div class="row no_side_margin">
        <div class="total col-lg-12 ">
          <div class="row">
            <div class="col-md-8 col-12">
            </div>

            <div class="col-md-4 col-12 d-flex align-items-center mt-2 mt-md-0 flex-wrap">
              <div class="d-flex align-items-center mr-2" v-if="isAdmin">
                <p class="text mb-0">{{ $t('select-user') }}:</p>
                <select v-model="selectedUser" class="form-control ml-2" v-on:change="saveSelectedUser(); updateDashboardData()">
                <option value="me">{{ $t('select-user-me') }}</option>
                <option value="all">{{ $t('select-user-all') }}</option>
                <!-- <option value="others">{{ $t('select-user-others') }}</option> -->
                <option value="custom" v-if="selectedUser == 'custom'">{{selectedUserEmail}}</option>
                </select>
              </div>

              <div class="d-flex align-items-center">
                <p class="text mb-0">{{ $t('select-period') }}:</p>
                <select v-model="period" class="form-control ml-2" v-on:change="changePeriod(period)">
                <option value="thisweek">{{ $t('select-period-thisweek') }}</option>
                <option value="previousweek">{{ $t('select-period-previousweek') }}</option>
                <option value="thismonth">{{ $t('select-period-thismonth') }}</option>
                <option value="previousmonth">{{ $t('select-period-previousmonth') }}</option>
                <option value="thisquarter">{{ $t('select-period-thisquarter') }}</option>
                <option value="previousquarter">{{ $t('select-period-previousquarter') }}</option>
                <option value="thisyear">{{ $t('select-period-thisyear') }}</option>
                <option value="previousyear">{{ $t('select-period-previousyear') }}</option>
                </select>
              </div>
              </div>
          </div>

          <div class="tab-content">

            <div id="home" class="tab-pane container active">
              <div class="row">
                <div class="col-sm-9 col-7 col-title ">
                  <h4>{{ $t('totals' ) }}</h4>
                </div>
              </div>                
              <div class="row smry-status">
                <div class="col-lg-6 col-md-12 col-sm-6  col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.sales.total}}{{currency}}</div>
                    <div class="item-text">{{ $t('sales' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-6  col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.sales.balance }}{{currency}}</div>
                    <div class="item-text">{{ $t('pending-total' ) }}</div>
                  </div>
                </div>

              </div>
              
              <div class="row" v-if="this.sales.payments">
                <div class="col-sm-9 col-7 col-title pt-5">
                  <h4>{{ $t('payments' ) }}</h4>
                </div>
               </div>

               <!-- <div class="col-lg-12 no_side_pad request" v-if="this.sales.payments">
                <div class="page-nav">
                  <div class="row no_side_margin">
                    <div class="col-lg-2 text-right">
                      <div><a :class="{'disabled': isFirstPageBySection('payments'), '': !isFirstPageBySection('payments')}" v-on:click="movePreviousBySection('payments')"><span class="nav-icon">←</span> {{ $t('previous' ) }} </a></div>
                    </div>
                    
                    <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                        {{ $t('page' ) }} {{ this.currentPageSection.payments + 1 }} / {{ this.totalPagesSection.payments }}
                    </div>
                    <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                      <div><a :class="{'disabled': isLastPageBySection('payments'), '': !isLastPageBySection('payments')}" v-on:click="moveNextBySection('payments')"> {{ $t('next' ) }} <span class="nav-icon">→</span></a></div>
                    </div>
                  </div>
                </div>
              </div> -->
<!-- {{ sales.payments }} -->

               <div class="row smry-status no_side_margin" style="overflow-x: auto;" v-if="this.sales.payments">

                <div class="d-flex align-items-center pt-3 mb-4">
                  <div class="select_period col-text mr-2">
                    <p class="text">{{ $t('filter-by-status') }}:</p>
                  </div>
                  <div class="">
                    <select v-model="filterStatus" class="form-control" style="max-width: 300px;" v-on:change="filterPaymentsByStatus">
                      <option value="">{{ $t('all') }}</option>
                      <option value="paid">{{ $t('paid') }}</option>
                      <option value="pending">{{ $t('pending') }}</option>
                      <option value="canceled">{{ $t('canceled') }}</option>
                    </select>
                  </div>
                </div>
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('date' ) }}</th>
                      <th class="t-head">{{ $t('email' ) }}</th>
                      <th class="t-head">{{ $t('status' ) }}</th>
                      <th class="t-head text-right">{{ $t('amount' ) }}</th>
                      <th class="t-head text-right">{{ $t('amount_with_vat' ) }}</th>
                      <th class="t-head"></th>
                    </tr>
                  </thead>

                  <tbody v-if="!this.sales.filteredPayments || !this.sales.filteredPayments.length">
                    <tr>
                      <td colspan="5" class="table-col text-center">{{ $t('no-results' ) }}</td>
                    </tr>
                  </tbody>

                  <tbody v-if="this.sales.filteredPayments && this.sales.filteredPayments.length">
                    <tr v-for="entry in this.sales.filteredPayments" v-bind:key="entry.key">
                      <td class="table-col">{{entry.creation_date | formatDate}}</td>
                      <td class="table-col">{{entry.email}}</td>
                      <td class="table-col">
                        <span :class="{'text-success': ['paid', 'accepted'].includes(entry.status), 'text-warning': !['paid', 'accepted'].includes(entry.status)}">{{ entry.status }}</span>
                      </td>
                      <td class="table-col text-right">{{entry.amount.toFixed(2) | numberOnly}}{{currency}}</td>
                      <td class="table-col text-right">
                        <span v-if="['paid', 'accepted'].includes(entry.status)">{{entry.amount_vat.toFixed(2) | numberOnly}}{{currency}}</span>
                        <span v-else>-</span>
                      </td>
                      <td class="table-col text-right">
                        <a title="View invoice" :href="entry.invoice_url" target="_blank" v-if="entry.invoice_url && entry.invoice_url.length"><i class="fa fa-eye"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

               <!-- <div class="row" v-if="this.sales.subscriptions">
                <div class="col-sm-9 col-7 col-title pt-5">
                  <h4>{{ $t('subscriptions' ) }}</h4>
                </div>
               </div>

               <div class="col-lg-12 no_side_pad request" v-if="this.sales.subscriptions">
                <div class="page-nav">
                  <div class="row no_side_margin">
                    <div class="col-lg-3 text-right">
                      <div><a :class="{'disabled': isFirstPageBySection('subscriptions'), '': !isFirstPageBySection('subscriptions')}" v-on:click="movePreviousBySection('subscriptions')"><span class="nav-icon">←</span> {{ $t('previous' ) }} </a></div>
                    </div>
                    
                    <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                        {{ $t('page' ) }} {{ this.currentPageSection.subscriptions + 1 }} / {{ this.totalPagesSection.subscriptions }}
                    </div>
                    <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                      <div><a :class="{'disabled': isLastPageBySection('subscriptions'), '': !isLastPageBySection('subscriptions')}" v-on:click="moveNextBySection('subscriptions')"> {{ $t('next' ) }} <span class="nav-icon">→</span></a></div>
                    </div>
                  </div>
                </div>
              </div>

               <div class="row smry-status no_side_margin" style="overflow-x: auto;" v-if="this.sales.subscriptions">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('date' ) }}</th>
                      <th class="t-head">{{ $t('user' ) }}</th>
                      <th class="t-head text-right">{{ $t('amount' ) }}</th>
                      <th class="t-head">{{ $t('ip' ) }}</th>

                    </tr>
                  </thead>

                  <tbody v-if="!this.sales.subscriptions || !this.sales.subscriptions.length">
                    <tr>
                      <td colspan="4" class="table-col text-center">{{ $t('no-results' ) }}</td>
                    </tr>
                  </tbody>

                  <tbody v-if="this.sales.subscriptions && this.sales.subscriptions.length">
                    <tr v-for="entry in this.sales.subscriptions" v-bind:key="entry.key">
                      <td class="table-col">{{entry.date | formatDate}}</td>
                      <td class="table-col">{{entry.email}}</td>
                      <td class="table-col text-right">{{entry.amount.toFixed(2) | numberOnly}}{{currency}}</td>
                      <td class="table-col">{{entry.ip}}</td>

                    </tr>
                  </tbody>
                </table>
              </div> -->

               <div class="row" v-if="this.sales.invoices && this.sales.invoices.length">
                <div class="col-sm-9 col-7 col-title pt-5">
                  <h4>{{ $t('invoices' ) }} <span v-if="this.sales.invoices.length > 0">({{ this.sales.invoices.length }})</span></h4>
                </div>
               </div>

               <!-- <div class="col-lg-12 no_side_pad request" v-if="this.sales.invoices && this.sales.invoices.length">
                <div class="page-nav">
                  <div class="row no_side_margin">
                    <div class="col-lg-3 text-right">
                      <div><a :class="{'disabled': isFirstPageBySection('invoices'), '': !isFirstPageBySection('invoices')}" v-on:click="movePreviousBySection('invoices')"><span class="nav-icon">←</span> {{ $t('previous' ) }} </a></div>
                    </div>
                    
                    <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                      {{ $t('page' ) }} {{ this.currentPageSection.invoices + 1 }} / {{ this.totalPagesSection.invoices }}
                    </div>

                    <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                      <div><a :class="{'disabled': isLastPageBySection('invoices'), '': !isLastPageBySection('invoices')}" v-on:click="moveNextBySectionBySection('invoices')"> {{ $t('next' ) }} <span class="nav-icon">→</span></a></div>
                    </div>
                  </div>
                </div>
              </div> -->

               <div class="row smry-status no_side_margin" style="overflow-x: auto;" v-if="this.sales.invoices && this.sales.invoices.length">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('date' ) }}</th>
                      <th class="t-head">{{ $t('user' ) }}</th>
                      <th class="t-head text-right">{{ $t('amount' ) }}</th>
                      <th class="t-head text-right"></th>
                    </tr>
                  </thead>

                  <tbody v-if="!this.sales.invoices.results || !this.sales.invoices.results.length">
                    <tr>
                      <td colspan="4" class="table-col text-center">{{ $t('no-results' ) }}</td>
                    </tr>
                  </tbody>

                  <tbody v-if="this.sales.invoices && this.sales.invoices.results && this.sales.invoices.results.length">
                    <tr v-for="entry in this.sales.invoices.results" v-bind:key="entry.key">
                      <td class="table-col">{{entry.date | formatDate}}</td>
                      <td class="table-col">{{entry.email}}</td>
                      <td class="table-col text-right">{{entry.amount.toFixed(2) | numberOnly}}{{currency}}</td>
                      <td class="table-col text-right">
                        <!-- <a v-on:click="viewInvoice(entry.id)" class="pointer pull-right mr-2" title="View invoice"><i class="fa fa-eye"></i></a>
                        <a v-on:click="sendInvoice(entry.id)" class="pointer pull-right mr-2" title="Send invoice"><i class="fa fa-envelope"></i></a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>


          </div>

        </div>
      </div>

              
              
      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Dashboard',
  components: {
    Header,
    Sidebar,
    Footer,
  },
  data() {
    return {
      isActive: false,
      isAdmin: false,
      isActiveIcon: '<',
      period: 'thisweek',
      periodAmounts: 7,
      selectedUser: 'all',
      selectedUserEmail: '',
      selectedUserObj: {},
      customSearch: false,
      customSearchTool: false,

      currentLocale: this.$i18n.locale,
      filterStatus: 'paid',

      balance: 1,

      kpisAll: {
        total_day: -1,
        cost: -1,
        forecast: "-"
      },
      kpisList: [],
      kpisTotal: {
        all: {
          count: "-",
        }
      },
      isCostByHour: true,
      startDate: '',
      endDate: '',
      graphOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      datacollection: {
        datasets: []
      },
      toggleClass: "ani1",
      state: [],
      config: {},
      graph: {
        data: [],
        options: {}
      },

      loading: false,

      totalPagesSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      currentPageSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      totalRecordsSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      },

      totalRowsSection: {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0,
      },

      rowsPerPageSection: {
        jobs: 10,
        requests: 10,
        tools: 10,
        sales: 10,
        users: 10
      },

      prevStartDate: null,
      prevEndDate: null,
      requests: [],
      requestShown: -1,
      shownAllRequests: false,
      jobs: [],
      jobsRunning: false,
      countdownToRefresh: 30,
      secondsToRefresh: 30,

      totalTools: [],
      totalUsers: [],
      filterText: '',
      filterTool: '',
      
      tools: {
        results: [],
      },
      edited_tool_key: '',

      sales: {
        invoices: [],
        payments: [],
        filteredPayments: [],
        subscriptions: [],
        total: 0
      },
      users: {
        list: [],
        total: []
      },

      result: null,
      currency: '€',
      
      status: {
        apps: [],
        services: []
      },
    };
  },
  mounted() {
    this.recoverStorageValues();
    this.enableInterceptor();
    this.getProcessingValues();
    this.changePeriod(this.period);
  },
  methods: {
    recoverStorageValues() {
      this.isAdmin = localStorage.role === 'admin';
      this.period = localStorage.period || 'thisweek';
      this.selectedUser = localStorage.role === 'admin' ? localStorage.panelSelectedUser : 'all';
    },
    
    getConfigAuth() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    getProcessingValues: function() {
      const url = '/api/v2/processing';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.processing = results.data;
        this.currency = this.processing.currency === 'usd' ? '$' : '€';
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    toggleFieldValue(toolKey, field) {
      this.$http.get(`/api/v2/panel/tools/${toolKey}/toggle/${field}`, this.getConfigAuth()).then(() => {
        this.getTools(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
      });
    },

    editFieldValue(toolKey, field, value) {
      this.$http.get(`/api/v2/panel/tools/${toolKey}/edit/${field}/${value}`, this.getConfigAuth()).then(() => {
        this.edited_tool_key = '';
        this.getTools(true);
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    setSection(section) {
      $('.nav-tabs a[href="#' + section + '"]').tab('show');
    },

    setSectionAndUrl(section) {
      //update url
      if (section.length) {
        section = '/' + section;
      }
      window.history.pushState({}, '', '#/dashboard' + section);
    },

    sendInvoice: function(id) {
      console.log(`send invoice ${id}`);
      // this.$http.get('/api/v2/invoice/' + id + '/send', this.getConfigAuth()).then((results) => {
      //   this.getSales();
      // });
    },

    viewInvoice: function(id) {
      console.log(`view invoice ${id}`);
      // window.open('/api/v2/invoice/' + id + '/view', '_blank');
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areDatesDifferents: function(setDates) {
      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    isFirstPageBySection: function(section) {
      return this.currentPageSection[section] === 0;
    },
    
    isLastPageBySection: function(section) {
      return this.currentPageSection[section] === this.totalPagesSection[section] - 1;
    },

    showToolDetails: function(requestIndex) {
      this.requestShown = requestIndex;
    },

    moveNextBySection: function(section) {
      this.clearCountdown();
      if (this.isLastPageBySection(section)) {
        this.loading = true;
      }
      if (section == 'jobs') {
        this.moveNextJobs();
      } else if (section == 'requests') {
        this.moveNextRequests();
      } else if (section == 'tools') {
        this.moveNextTools();
      } else if (section == 'sales') {
        this.moveNextSales();
      } else if (section == 'users') {
        this.moveNextUsers();
      }
    },

    moveNextJobs: function() {
      if (this.currentPageSection.jobs + 1 < this.totalPagesSection.jobs) {
        this.currentPageSection.jobs++;
        this.getJobs(true);
      }
    },
    
    adaptOutputByResult: function(sampleResult) {
      try {
        const output = JSON.parse(sampleResult);
        
        // var values = Object.values(output);
        // for (var i = 0; i < values.length; i++) {
        //   if (this.isImage(values[i])) {
        //     //replace "values[i]" with the image tag in output
        //     values[i] = '<a href="' + values[i] + '" target="_blank">' + values[i] + '</a>';
        //     output[Object.keys(output)[i]] = values[i];
        //   } else if (this.isUrl(values[i])) {
        //     //replace "values[i]" with the link tag in output
        //     values[i] = '<a href="' + values[i] + '" target="_blank">' + values[i] + '</a>';
        //     output[Object.keys(output)[i]] = values[i];
        //   }
        // }

        //console.log(output);
        return output;
        
      } catch (e) {
        return sampleResult;
      }
    },

    isImage: function(value) {
      return value && value.indexOf('http') !== -1 && (value.indexOf('.png') !== -1 || value.indexOf('.jpg') !== -1 || value.indexOf('.jpeg') !== -1 || value.indexOf('.gif') !== -1);
    },

    isUrl: function(value) {
      return value && value.indexOf('http') !== -1;
    },

    moveNextRequests: function() {
      if (this.currentPageSection.requests + 1 < this.totalPagesSection.requests) {
        this.currentPageSection.requests++;
        this.getRequests(true);
      }
    },

    moveNextTools: function() {
      if (this.currentPageSection.tools + 1 < this.totalPagesSection.tools) {
        this.currentPageSection.tools++;
        this.getTools(true);
      }
    },

    moveNextSales: function() {
      if (this.currentPageSection.sales + 1 < this.totalPagesSection.sales) {
        this.currentPageSection.sales++;
        this.getSales();
      }
    },

    moveNextUsers: function() {
      if (this.currentPageSection.users + 1 < this.totalPagesSection.users) {
        this.currentPageSection.users++;
        this.getUsers();
      }
    },
    
    movePreviousBySection: function(section) {
      if (this.isFirstPageBySection(section)) {
        this.loading = true;
      }
      this.movePreviousSales();
    },

    movePreviousSales: function() {
      if (this.currentPageSection.sales - 1 >= 0) {
        this.currentPageSection.sales--;
        this.getSales();
      }
    },

    getSales: function() {
      var url = '/api/v2/sales_period/' + this.period + "/" + this.startDate.getTime() + "/" + this.endDate.getTime() + "/" + this.currentPageSection.sales;
      if (this.selectedUser !== '') {
        if ('custom' === this.selectedUser) {
          url += "?user=" + this.selectedUserEmail;
        } else {
          url += "?user=" + this.selectedUser;
        }
      }

      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.sales = results.data;

        //add paymentsFiltered to sales
        this.filterStatus = 'paid';
        this.filterPaymentsByStatus();

        this.totalRecordsSection.sales = results.data.count;
        this.totalPagesSection.sales = results.data.totalPages;
        this.rowsPerPageSection.sales = results.data.rowsPerPage;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    filterPaymentsByStatus: function() {
      var that = this;
      if (this.sales.payments && this.sales.payments.length) {
        this.sales.filteredPayments = this.filterStatus !== '' ? this.sales.payments.filter(function(item) {
          return item.status === that.filterStatus || ['paid', 'accepted'].includes(item.status) && that.filterStatus === 'paid';
        }) : this.sales.payments;
      }
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    fillData() {
      var days = [];
      var costs = [];
      var savings = [];
      this.dayData.map(function(element) {
        var type = element['key'];
        element['values'].map(function(elements) {
          var conv = elements[0];
          var actualDate = parseInt(conv, 10);
          var date = new Date(actualDate);

          var months_arr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

          // Hours part from the timestamp

          var year = date.getFullYear();

          // Month
          var month = months_arr[date.getMonth()];

          // Day
          var day = date.getDate();

          // Hours
          //var hours = date.getHours();

          // Minutes
          //var minutes = "0" + date.getMinutes();

          // Seconds
          //var seconds = "0" + date.getSeconds();

          // Display date time in MM-dd-yyyy h:m:s format
          var convdataTime = day + '/' + month + '/' + year;
          elements.date = convdataTime;
          elements.value = elements[1];
          if (elements.date && days.indexOf(elements.date) === -1) {
            days.push(elements.date)
          }

          if (type === 'cost') {
            costs.push(elements[1])
          } else if (type === 'saving') {
            savings.push(elements[1])
          }
        })
      })

      this.datacollection = {
        labels: days,
        datasets: [
          {
            label: this.$t('expense') + ` (${this.currency})`,
            backgroundColor: "rgb(49, 119, 180)",
            data: costs,
            fill: 'origin'
          },
          // {
          //   label:  this.$t('savings') + ` (${this.currency})`,
          //   backgroundColor: "rgb(174, 199, 232)",
          //   data: savings,
          //   fill: 'origin'
          // }
        ]
      }
    },

    setPeriodAmounts: function() {
      //calculate with startDate and isCostByHour
      const currentDate = this.endDate.getTime() > new Date().getTime() ? new Date() : this.endDate;
      //add 1 day to startDate
      const startDate = new Date(this.startDate);
      var diff = currentDate.getTime() - startDate.getTime();
      //console.log(diff, startDate, currentDate);
      if (diff > 0) {
        var days = diff / 1000 / 60 / 60 / 24;
        this.periodAmounts = days;
        //console.log(days);

        if (this.isCostByHour) {
          this.periodAmounts = days * 24;
        }
      }
    },

    resetCurrentPage: function() {
      this.currentPageSection = {
        jobs: 0,
        requests: 0,
        tools: 0,
        sales: 0,
        users: 0
      };
    },

    changePeriod: function(period) {
      if (localStorage) {
        localStorage.period = period;
      }
      this.filterText = '';

      this.datacollection = {
        datasets: []
      };

      this.period = period;
      this.endDate = new Date();
      this.startDate = new Date();

      var firstDay;
      var lastDay;
      //console.log(this.period, this.startDate, this.endDate);
      if (this.period === 'day') { //current day
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '1day') { //last 24
        this.startDate.setDate(this.startDate.getDate() - 1)
      } else if (this.period === 'month') { //current month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
      } else if (this.period === '7days') { //last 7 days
        this.startDate.setDate(this.startDate.getDate() - 7)
      } else if (this.period === '15days') { //last 15 days
        this.startDate.setDate(this.startDate.getDate() - 15)
      } else if (this.period === '30days') { //last 30 days
        this.startDate.setDate(this.startDate.getDate() - 30)
      } else if (this.period === '90days') { //last 90 days
        this.startDate.setDate(this.startDate.getDate() - 90)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '180days') { //last 180 days
        this.startDate.setDate(this.startDate.getDate() - 180)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '365days') { //last 365 days
        this.startDate.setDate(this.startDate.getDate() - 365)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      //add yesterday, today, this_week, previous_week, this_month, previous_month, this_quarter, previous_quarter, this_year, previous_year
      } else if (this.period === 'yesterday') { //yesterday
        this.startDate.setDate(this.startDate.getDate() - 1)
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setDate(this.endDate.getDate() - 1)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === 'today') { //today
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === 'thisweek') { //this week
        firstDay = this.startDate.getDate() - this.startDate.getDay();
        this.startDate.setDate(firstDay);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = firstDay + 6;
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), lastDay, 23, 59, 59);
      } else if (this.period === 'previousweek') { //previous week
        firstDay = this.startDate.getDate() - this.startDate.getDay() - 7;
        this.startDate.setDate(firstDay);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = firstDay + 6;
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), lastDay, 23, 59, 59);
      } else if (this.period === 'thismonth') { //this month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'previousmonth') { //previous month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        this.startDate.setMonth(this.startDate.getMonth() - 1);
        lastDay = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'thisquarter') { //this quarter
        this.startDate = new Date(this.startDate.getFullYear(), Math.floor(this.startDate.getMonth() / 3) * 3, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), Math.floor(this.startDate.getMonth() / 3) * 3 + 3, 0);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'previousquarter') { //previous quarter
        let previousQuarter = Math.floor(this.startDate.getMonth() / 3) * 3 - 3;
        this.startDate = new Date(this.startDate.getFullYear(), previousQuarter, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        this.endDate = new Date(this.startDate.getFullYear(), previousQuarter + 3, 0);
        this.endDate.setHours(23);
        this.endDate.setMinutes(59);
        this.endDate.setSeconds(59);
      } else if (this.period === 'thisyear') { //this year
        this.startDate = new Date(this.startDate.getFullYear(), 0, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), 11, 31);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      } else if (this.period === 'previousyear') { //previous year
        this.startDate = new Date(this.startDate.getFullYear() - 1, 0, 1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
        lastDay = new Date(this.startDate.getFullYear(), 11, 31);
        this.endDate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
      }



      //console.log(this.startDate, this.endDate);

      this.isCostByHour = (this.period === 'day' || this.period === '1day' || this.period === 'lday' || this.period === 'yesterday' || this.period === 'today');


      this.getSales();
    },

    getStatuses: function() {
      var url = '/api/v2/status/all';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.status = results.data;
        // console.log(results)
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },

    restartJob: function(job) {
      if (!job) {
        return;
      }

      //confirm if status is running
      if (job.status == 2) {
        if (!confirm(this.$t('job-restart-confirm'))) {
          return;
        }
      }
      var url = '/api/v2/restart_job/' + job.id;
      this.$http.get(url, this.getConfigAuth()).then(() => {
        this.getJobs(true);
      });
    },

    stopJob: function(job_id) {
      if (!job_id) {
        return;
      }

      if (!confirm(this.$t('job-stop-confirm'))) {
        return;
      }

      var url = '/api/v2/stop_job/' + job_id;
      this.$http.get(url, this.getConfigAuth()).then(() => {
        this.getJobs(true);
      });
    },

    downloadJobResultsFile: function(request_id) {
      var url = this.$apiBase + "/api/v2/request_download/" + request_id;
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        if (results && results.data && results.data.url && results.data.url.length) {
          window.location.href = results.data.url;
        }
      });
    },

    saveSelectedUser: function() {
      if (localStorage) {
        localStorage.panelSelectedUser = localStorage.role === 'admin' ? this.selectedUser : 'me';
      }
    },

    updateDashboardData: function() {
      this.customSearch = false;
      this.selectedUserEmail = '';
      this.changePeriod(this.period);
    },

    cancelSearch: function() {
      this.customSearch = false;
      this.selectedUser = 'all'
      this.selectedUserEmail = '';
      this.changePeriod(this.period);
    },

    selectUserAndUpdateDashboardData: function(user) {
      this.selectedUser = 'custom';
      this.selectedUserEmail = user.email
      this.selectedUserObj = user;
      this.period = 'thismonth';
      this.changePeriod(this.period);
    },

    addBalance: function() {
      this.result = {};
      var url = '/api/v2/add_balance/' + this.selectedUserEmail + '/' + this.balance;
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.result = results.data;
      });
    },

    saveUser: function() {
      this.result = {};
      var url = '/api/v2/save_user/' + this.selectedUserEmail;
      this.$http.post(url, this.selectedUserObj, this.getConfigAuth()).then((results) => {
        this.result = results.data;
      }).catch(() => {
        this.$root.$emit('isLoading', false);
        
      });
    },
  },
  watch: {

    state: function() {
    },

    // $route() {
    //   //reload data
    //   nextTick(() => {
    //     this.changePeriod(this.period);
    //   });
    // }

  },
  computed: {

    orderedList: function() {
      return _.orderBy(this.kpisList, ['key'], ['asc'])
    },

    dayData() {
      if (this.state != 0) {
        return this.state;
      } else {
        return [{
          "key": "cost",
          "values": [
            [Date.now(), 0]
          ]
        }, {
          "key": "saving",
          "values": [
            [Date.now(), 0]
          ]
        }];
      }
    }
  }
}
</script>

<style scoped>
#secure {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 20px;
  margin-top: 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../../assets/css/global.css';
</style>
